import React from "react";

import {
  Box,
  Typography,
  Grid,
  InputLabel,
  Button,
  TextField,
  FormHelperText,
  Switch,
  Tabs,
  Tab,
  Link,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  TableContainer,
  TableHead,
  Table,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import InputField from "../../../components/src/InputField";
import CommonSelect from "../../../components/src/SelectField";
import PhoneInput from "react-phone-input-2";
import {
  downloadIcon,
  dropDownIcon,
  facebookIcon,
  infoIcon,
  instaGramIcon,
  linkedinIcon,
  locationIcon,
  plusIcon,
  searchIcon,
  tikTokIcon,
  uploadFileIcon,
} from "./assets";
import  NavigationMenu  from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import {
  AddressIcon,
  cityIcon,
  countryIcon,
  emailIcon,
  ZipCodeIcon,
} from "../../../../packages/blocks/email-account-registration/src/assets";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomDialog from "../../../components/src/CustomDialog.web";
const {baseURL} = require( "../../../framework/src/config.js");
import Autocomplete from "@material-ui/lab/Autocomplete";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export interface FormError {
  [key: string]: string;
}
export interface FormErrorTouched {
  [key: string]: boolean;
}

export interface EstablishmentListErrorDetail {
  detail?: string;
  account?: string;
  token?: string;
  error?:string;
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const updatePhoneInitialValue = {
  current_password: "",
  full_phone_number: "",
  country_code: "",
  old_phone_number: "",
  old_country_code: "",
};

const updateEmailInitialValue = {
  current_password: "",
  email: "",
  email_confirmation: "",
};

const resetPasswordInitialValue = {
  current_password: "",
  password:"",
  confirm_password:""
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ width: "100%" }}>{children}</Box>}
    </div>
  );
}

const GridNone = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const EntrePriseContainer = styled(Grid)(({ theme }) => ({
  padding: "5% 12%",
  backgroundColor: "#f7fbff",
  "& .Tab-Container": {
    marginTop: "10px",
  },
  "& .enter-prise-title": {
    fontWeight: 600,
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  "& .MuiTabs-scroller": {
    overflow: "auto !important",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      width: "0px",
    },
  },
}));
const FieldUpdateButton = styled(Button)({
  textTransform: "none",
  fontSize: "11px",
  fontWeight: 500,
  color: "#6F05EA",
  "& .MuiButton-label": {
    borderBottom: "1px solid #6F05EA",
  },
  "& .MuiDialogContent-dividers": {
    borderTop: "none",
    borderBottom: "none",
  },
});
const GeneralInfoFormContainer = styled(Grid)(({ theme }) => ({
  "& .MuiInputBase-input.Mui-disabled":{
    color:"##7D7D7D",
    fontWeight:500
  },
  "& .section-title": {
    fontSize: "27px",
    color: "#6F05EA",
    fontWeight: 600,
    marginTop: "40px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  "& .general-info-btn": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "40px",
  },
  "& .edit-update-btn": {
    display: "flex",
    gap: "5%",
  },
  "& .cancel-btn": {
    color: "#4B4B4B",
    textTransform: "none",
    border: "1px solid #4B4B4B",
    borderRadius: "100px",
    padding: "5px 45px",
    fontSize: "18px",
    fontWeight: 700,
    boxShadow: "none",
    "&:hover": {
      color: "#4B4B4B",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 35px",
      fontSize: "14px",
    },
  },
  "& .general-info-img-container": {
    display: "flex",
    gap: "3%",
    alignItems: "center",
    marginTop: "40px",
    [theme.breakpoints.down("xs")]: {
      margin: "40px 0px 0px 0px",
    },
  },
  "& .user-name-container": {
    display: "flex",
    flexDirection: "column",
  },
  "& .user-img": {
    width: "125px",
    height: "125px",
    borderRadius:"50%",
    [theme.breakpoints.down("xs")]: {
      width: "80px",
      height: "80px",
    },
  },
  "& .user-name-label": {
    fontSize: "13px",
    fontWeight: 400,
    color: "#A6A6A6",
  },
  "& .user-name": {
    fontSize: "30px",
    fontWeight: 600,
    color: "##000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  "& .phone-container": {
    "& .react-tel-input > input": {
      backgroundColor: "#f7fbff",
    },
  },
  "& .city-drop-down > div > div > .MuiSelect-select": {
    padding: "4px",
  },
  "& .upload-img-box": {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  "& .upload-file-input": {
    position: "absolute",
    height:"100px",
    width:"125px",
    opacity: "0",
    [theme.breakpoints.down("xs")]: {
      height:"75px",
      width:"78px",
    },
  },
  "& .new-establish-select": {
    padding: "4px",
  },
  "& .new-establish-address-label": {
    fontSize:"13px",
    fontWeight:"400",
    lineHeight:"22px",
    color:"#6F05EA"
  },
  "& .new-establishment-address > div":{
    padding:"4px",
    "& .MuiInput-root.Mui-focused .MuiInputAdornment-positionStart img": {
      filter: "brightness(0) saturate(100%) invert(13%) sepia(81%) saturate(6967%) hue-rotate(270deg) brightness(86%) contrast(117%)"
    },
    "& .MuiAutocomplete-clearIndicator":{
      display:"none"
    },
    "& .MuiAutocomplete-popupIndicator":{
      display:"none"
    }
  },
  "& .general-info-address":{
    "& .MuiAutocomplete-clearIndicator":{
      display:"none"
    },
    "& .MuiAutocomplete-popupIndicator":{
      display:"none"
    }
  },
  "& .admin-info-container":{
    display:"flex",
    gap:"10px"
  },
  "& .info-icon-text":{
    fontSize:"13px"
  },
  "& .info-icon-img":{
    height:"16px",
    width:"16px"
  }
}));
const ForgetPwdLink = styled(Link)({
  fontSize: "12px",
  fontWeight: 700,
  color: "#6F05EA",
});
const DialogButton = styled(Button)(({ theme }) => ({
  border: "1px solid #6F05EA",
  borderRadius: "100px",
  padding: "5px 165px",
  fontSize: "18px",
  fontWeight: 700,
  textTransform: "none",
  color: "#6200EA",
  backgroundColor: "transparent",
  boxShadow: "none",
  "&:hover": {
    color: "#6200EA",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "14px",
    padding: "5px 85px",
  },
}));
const EditUpdateButton = styled(Button)(({ theme }) => ({
  border: "1px solid #6F05EA",
  borderRadius: "100px",
  padding: "5px 45px",
  fontSize: "18px",
  fontWeight: 700,
  textTransform: "none",
  color: "#6200EA",
  backgroundColor: "transparent",
  boxShadow: "none",
  "&:hover": {
    color: "#6200EA",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 35px",
    fontSize: "14px",
  },
}));
const PasswordSecurityContainer = styled(Box)(({ theme }) => ({
  "& .pwd-sec-title": {
    fontWeight: "600",
    fontSize: "24px",
    color: "#6F05EA",
    margin: "40px 0px 30px 0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  "&. logged-in-title": {
    fontWeight: "500",
    fontSize: "18px",
  },
  "& .logged-in-card-container": {
    marginTop: "10px",
  },
  "& .logged-in-card": {
    display: "flex",
    gap: "2%",
    alignItems: "center",
    padding: "36px",
    marginTop: "10px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 7.5px 0px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    },
  },
  "& .device-name-container": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    gap:"10px",
    [theme.breakpoints.down(420)]: {
      gap:"7px"
    },
  },
  "& .device-img": {
    [theme.breakpoints.down("sm")]: {
      height: "40px",
      width: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "30px",
      width: "30px",
    },
  },
  "& .device-name": {
    fontSize: "16px",
    fontWeight: "400",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(420)]: {
      fontSize: "10px",
    },
  },
  "& .end-session-btn": {
    border: "1px solid #DC2626",
    borderRadius: "100px",
    padding: "5px 45px",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "none",
    color: "#DC2626",
    backgroundColor: "transparent",
    boxShadow: "none",
    minWidth: "unset",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 25px",
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5px 10px",
      fontSize: "10px",
    },
    [theme.breakpoints.down(420)]: {
      padding: "5px 7px",
    },
  },
  "& .finance-search-short-container": {
    display: "flex",
  },
  "& .finance-container": {
    padding: "0px 25% 0px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10% 0px 0px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  "& .finance-search": {
    backgroundColor: "#fff",
  },
  "& .finance-search > div > .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .finance-status": {
    backgroundColor: "#fff",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
    },
  },
  "& .finance-status > div > .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .drop-down-icon": {
    marginRight: "10px",
  },
  "& .finance-status-empty": {
    color: "#94A3B8",
  },
  "& .table-box": {
    maxHeight: "350px",
    marginBottom: "50px",
    [theme.breakpoints.up("md")]: {
      width: "inherit",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  "& .table-container": {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    marginTop: "20px",
    boxShadow: "none",
    borderRadius: "12px",
    [theme.breakpoints.down("md")]: {
      overflowX: "auto",
    },
  },
  "& .table-header": {
    backgroundColor: "#6F05EA33",
  },
  "& .MuiTableCell-body": {
    color: "#6F05EA",
    fontSize: "13px",
  },
  "& .MuiTableCell-head": {
    color: "#6F05EA",
    fontSize: "14px",
  },
  "& .MuiTableCell-root": {
    padding: "8px 24px",
  },
  "& .status-pending": {
    color: "#D97706",
  },
  "& .status-paid": {
    color: "#059669",
  },
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: theme.spacing(1),
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    width: 24,
    height: 21,
    [theme.breakpoints.down("xs")]: {
      height: 20,
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
    height: "89%",
    [theme.breakpoints.down("xs")]: {
      height: "89%",
    },
  },
  [theme.breakpoints.down("xs")]: {
    transform: "scale(0.7)",
  },
}));

const EstablishmentGrid = styled(Grid)(({ theme }) => ({
  marginTop: "40px",
  "& .establishment-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 7.5px 0px",
    backgroundColor: "#fff",
    padding: "35px",
    [theme.breakpoints.down(800)]: {
      padding: "20px",
    },
  },
  "& .info-container": {
    display: "flex",
    gap: "3%",
    width: "80%",
    alignItems: "center",
  },
  "& .establish-img": {
    height: "84px",
    width: "84px",
    borderRadius:"50%",
    [theme.breakpoints.down("xs")]: {
      height: "54px",
      width: "54px",
    },
  },
  "& .establish-data-container": {
    width: "inherit",
  },
  "& .establish-name": {
    fontSize: "20px",
    fontWeight: "600",
    color: "#6F05EA",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "12px",
    },
  },
  "& .establish-location": {
    display: "flex",
    alignItems: "center",
    gap: "3%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "5%",
    },
  },
  "& .location-img": {
    width: "14px",
    height: "14px",
    [theme.breakpoints.down("xs")]: {
      height: "10px",
      width: "10px",
    },
  },
  "& .location-name": {
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  "& .new-establish-btn-container": {
    width: "100%",
    justifyContent: "flex-end",
    display: "flex",
  },
  "& .new-establish-btn": {
    border: "1px solid #6F05EA",
    borderRadius: "100px",
    padding: "12px 48px",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "none",
    color: "#6200EA",
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "400px",
    marginTop: "135px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      padding: "12px 30px",
      marginTop: "35px",
    },
    [theme.breakpoints.down(362)]: {
      fontSize: "12px",
    },
  },
  "& .select-establishment": {
    marginTop: "20px",
  },
  "& .info-add-new": {
    fontSize: "40px",
    fontWeight: 600,
    color: "#787878",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      textAlign: "center",
    },
  },
}));

const VerifyEmailContainer = styled(Box)(({ theme }) => ({
  "& .verify-body": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  "& .verify-info-text": {
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  "& .forgot-link": {
    fontSize: "18px",
    fontWeight: 500,
    color: "#000",
    borderBottom: "1px solid #000",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  "& .verify-sec": {
    color: "#6F05EA",
    fontSize: "16px",
    marginTop: "21px",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  "& .receive-link": {
    fontSize: "16px",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  "& .resend-email": {
    fontWeight: 500,
    color: "#6F05EA",
    fontFamily: "Montserrat",
    fontSize: "16px",
    marginTop: "21px",
    borderBottom: "1px solid #6200EA",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));

const ResetPasswordDialogInfo = styled(Typography)(({theme}) => ({
  fontSize:"24px", 
  fontWeight:700, 
  textAlign:"center",
  [theme.breakpoints.down("sm")]: {
    fontSize:"20px", 
  },
  [theme.breakpoints.down("xs")]: {
    fontSize:"16px", 
  },
}));
const JustLoggedDialogInfo= styled(Typography)(({theme}) => ({
  fontSize:"40px", 
  fontWeight:700, 
  textAlign:"center",
  color:"#6F05EA",
  padding:"30px 50px",
  [theme.breakpoints.down("sm")]: {
    fontSize:"20px", 
  },
  [theme.breakpoints.down("xs")]: {
    fontSize:"16px", 
    padding:"0px",
  },
}));
const EndSessionDialogBtnContainer = styled(Box)(({theme}) => ({
  display:"flex",
  justifyContent:"center",
  gap:"3%",
  marginTop:"40px",
  "& .cancel-btn": {
    color: "#4B4B4B",
    textTransform: "none",
    border: "1px solid #4B4B4B",
    borderRadius: "100px",
    padding: "5px 45px",
    fontSize: "18px",
    fontWeight: 700,
    boxShadow: "none",
    "&:hover": {
      color: "#4B4B4B",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(480)]: {
      padding: "5px 15px",
      fontSize: "12px",
    },
  },
  "& .end-session-btn": {
    border: "1px solid #DC2626",
    borderRadius: "100px",
    padding: "5px 45px",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "none",
    color: "#DC2626",
    backgroundColor: "transparent",
    boxShadow: "none",
    minWidth: "unset",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(480)]: {
      padding: "5px 15px",
      fontSize: "12px",
    },
  },
}));

const ResetPasswordDialog = styled(CustomDialog)(({theme}) => ({
  display:"none"
}));

const emailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("*Please enter email")
    .trim(),
});

const passwordValidationSchema = Yup.object().shape({
  current_password: Yup.string()
    .required("*Enter your password.")
    .test(
      "password-complexity",
      "Password should include at least 8 characters, one number, and one letter.",
      (value) => {
        if (!value) return false;
        return /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/.test(value);
      }
    )
    .trim(),
});

const phoneValidationSchema = Yup.object().shape({
  full_phone_number: Yup.string()
    .required("*Please Enter Your Phone Number")
    .max(10, "Maximum Phone number length should be 10")
    .min(10, "Minimum Phone number length should be 10")
    .trim(),
});

const generalInfoSchema = Yup.object().shape({
  address: Yup.string().required("*Address is Required.").trim(),
  zip_code: Yup.number().test('len', 'Zip Code must be exactly 5 digits', val => val && val.toString().length === 5)
    .typeError("*ZipCode must be a number")
    .required("*Please Enter ZipCode"),
  city: Yup.string().required("*Please Select city").trim(),
  country: Yup.string().required("*Please Select Country").trim(),
  instagramLink: Yup.string().url('Enter Valid link for instagram link').nullable(true),
  linkedinLink: Yup.string().url('Enter Valid link for linkedin link').nullable(true),
  faceBookLink: Yup.string().url('Enter Valid link for facebook link').nullable(true),
  tikTokLink: Yup.string().url('Enter Valid link for tikTok link').nullable(true),
});

const newEstablishmentSchema = Yup.object()
  .shape({
    establishment_name: Yup.string()
      .required("*Please Enter Name")
      .matches(/^[A-Za-z\s]*$/, "User Name should only contain letters")
      .trim(),
    address: Yup.string().required("*Address is Required.").trim(),
    zip_code: Yup.number().test('len', 'Zip Code must be exactly 5 digits', val => val && val.toString().length === 5)
    .typeError("*ZipCode must be a number")
    .required("*Please Enter ZipCode"),
    city: Yup.string().required("*Please Select city").trim(),
    country: Yup.string().required("*Please Select Country").trim(),faceBookLink: Yup.string().url('Enter Valid link for facebook link').nullable(true),
    instagramLink: Yup.string().url('Enter Valid link for instagram link').nullable(true),
    tikTokLink: Yup.string().url('Enter Valid link for tikTok link').nullable(true),
    linkedinLink: Yup.string().url('Enter Valid link for linkedin link').nullable(true),
  })
  .concat(emailValidationSchema)
  .concat(phoneValidationSchema);

const updatePhoneSchema = phoneValidationSchema
  .concat(passwordValidationSchema)
  .concat(
    Yup.object().shape({
      old_phone_number: Yup.string()
        .oneOf(
          [Yup.ref("full_phone_number")],
          "New phone number and confirmation phone number do not match"
        )
        .required("*Please Enter Your Old Phone Number")
        .trim(),
      old_country_code: Yup.string()
      .oneOf(
        [Yup.ref("country_code")],
        "New phone number and confirmation phone number do not match"
      )
    }),
  );
const updateEmailSchema = emailValidationSchema
  .concat(passwordValidationSchema)
  .concat(
    Yup.object().shape({
      email_confirmation: Yup.string()
        .oneOf([Yup.ref("email")], "*Email does not match.")
        .required("*Please Enter Email Confirmation"),
    })
  );
const resetPasswordSchema = passwordValidationSchema
.concat(
  Yup.object().shape({
    password: Yup.string()
    .required("*Enter your new password.")
    .test(
      "password-complexity",
      "Password should include at least 8 characters, one number, and one letter.",
      (value) => {
        if (!value) return false;
        return /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/.test(value);
      }
    )
    .notOneOf([Yup.ref('current_password')], 'New password must be different from current password')
    .trim(),
    confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "Confirm passwords does not match with password.")
    .required("*Enter your Confirm Password."),
  })
);
// Customizable Area End

import CfentrepriseaccountController, {
  Props,
  configJSON,
} from "./CfentrepriseaccountController";

export default class Cfentrepriseaccount extends CfentrepriseaccountController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderGeneralInfoTab = () => {
    return (
      <>
        <Box className="Tab-Container">
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            textColor="inherit"
            aria-label="secondary tabs example"
            style={{
              font: "Montserrat",
              textTransform: "none",
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#6F05EA",
                height: "4px",
              },
            }}
            data-test-id="tabs"
          >
            <Tab
              label="General Information"
              style={
                {
                  ...this.renderTabStyle(0),
                } as React.CSSProperties
              }
              className="Tab"
              {...a11yProps(0)}
            />
            <Tab
              label="Password & Security"
              style={
                {
                  ...this.renderTabStyle(1),
                } as React.CSSProperties
              }
              className="Tab"
              {...a11yProps(1)}
            />
            <Tab
              label="Establishments Control"
              style={
                {
                  ...this.renderTabStyle(2),
                } as React.CSSProperties
              }
              className="Tab"
              {...a11yProps(2)}
            />
            <Tab
              label="Finances"
              style={
                {
                  ...this.renderTabStyle(3),
                } as React.CSSProperties
              }
              className="Tab"
              {...a11yProps(3)}
            />
          </Tabs>
          <TabPanel value={this.state.tabValue} index={0}>
            <Typography>{this.renderGeneralInfoForm()}</Typography>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={1}>
            <Typography>{this.renderPasswordSecurity()}</Typography>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={2}>
            <Typography>{this.renderEstablishment()}</Typography>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={3}>
            <Typography>{this.renderFinances()}</Typography>
          </TabPanel>
        </Box>
      </>
    );
  };

  renderEmailVerifyBox = () => {
    return (
      <CustomDialog
        open={this.state.isEmailVerifyDialogOpen}
        title="Verify Email"
        className="dialog-title"
        onClose={() => this.handleDialogClose("EmailVerify")}
        data-test-id="verify-email-dialog"
      >
        <VerifyEmailContainer>
          <Box className="verify-body">
            <Typography className="verify-info-text">
              We have sent the verification link to
            </Typography>
            <Typography className="forgot-link">
              {this.state.updateEmailValue.email}.
            </Typography>
            <Typography className="verify-sec">
              {this.state.timerSecond} Sec
            </Typography>
            {this.state.timerSecond === 0 && (
              <>
                <Typography className="receive-link">
                  Didn't receive the link?{" "}
                </Typography>
                <Typography
                  className="resend-email"
                  data-test-id="resend-link"
                  onClick={() => {
                    this.handleTimer();
                    this.resendEmailApiCall();
                  }}
                >
                  Resend Email
                </Typography>
                <Box
                    style={{
                      color: "#ef4444",
                      margin: "0px auto 10px auto",
                      fontSize: "14px",
                    }}
                  >
                    {this.state.dialogError}
                  </Box>
              </>
            )}
          </Box>
        </VerifyEmailContainer>
      </CustomDialog>
    );
  };

  renderUpdateEmail = () => {
    return (
      <CustomDialog
        open={this.state.isUpdateEmailDialogOpen}
        title="Update Email"
        className="dialog-title"
        onClose={() => this.handleDialogClose("UpdateEmail")}
        data-test-id="update-email-dialog"
      >
        <Formik
          initialValues={updateEmailInitialValue}
          validationSchema={updateEmailSchema}
          enableReinitialize
          onSubmit={(values) => {
            this.handleUpdateEmailForm(values);
          }}
        >
          {({ errors, touched, handleSubmit, getFieldProps }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid
                    xs={12}
                    className="enter-prise-input-grid"
                    style={{ marginBottom: "35px" }}
                  >
                    <InputField
                      {...getFieldProps("current_password")}
                      fullWidth
                      id="current_password"
                      label="Current Password"
                      placeholder="Current Password"
                      type="text"
                      labelClass="enter-prise-input-label"
                      error={
                        this.getErrorAndHelperText(
                          "current_password",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      helperText={
                        this.getErrorAndHelperText(
                          "current_password",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      data-test-id="userinfo-name"
                    />
                    <ForgetPwdLink
                      onClick={this.goToForgotPasswordPage}
                      underline="always"
                      data-test-id="forget-pwd"
                    >
                      Forget Password
                    </ForgetPwdLink>
                  </Grid>
                  <Grid xs={12} className="enter-prise-input-grid">
                    <InputField
                      {...getFieldProps("email")}
                      fullWidth
                      id="email"
                      label="Add Email"
                      placeholder="Confirm New Email"
                      type="text"
                      labelClass="enter-prise-input-label"
                      style={webStyles.dialogInput}
                      error={
                        this.getErrorAndHelperText(
                          "email",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      helperText={
                        this.getErrorAndHelperText(
                          "email",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      data-test-id="userinfo-name"
                    />
                  </Grid>
                  <Grid xs={12} className="enter-prise-input-grid">
                    <InputField
                      {...getFieldProps("email_confirmation")}
                      fullWidth
                      id="email_confirmation"
                      label="Confirm New Email"
                      placeholder="Confirm New Email"
                      type="text"
                      labelClass="enter-prise-input-label"
                      style={webStyles.dialogInput}
                      error={
                        this.getErrorAndHelperText(
                          "email_confirmation",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      helperText={
                        this.getErrorAndHelperText(
                          "email_confirmation",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      data-test-id="userinfo-name"
                    />
                  </Grid>
                  <Box
                    style={{
                      color: "#ef4444",
                      margin: "0px auto 10px auto",
                      fontSize: "14px",
                    }}
                  >
                    {this.state.dialogError}
                  </Box>
                  <Grid xs={12} className="dialog-btn-container">
                    <DialogButton type="submit">Continue</DialogButton>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </CustomDialog>
    );
  };

  renderUpdatePhone = () => {
    return (
      <CustomDialog
        open={this.state.isUpdatePhoneDialogOpen}
        title="Update Phone"
        className="dialog-title"
        onClose={() => this.handleDialogClose("UpdatePhone")}
        data-test-id="update-phone-dialog"
      >
        <Formik
          initialValues={updatePhoneInitialValue}
          validationSchema={updatePhoneSchema}
          enableReinitialize
          onSubmit={(values) => {
            this.handleUpdatePhoneForm(values);
          }}
          data-test-id="step2-form"
        >
          {({
            errors,
            touched,
            handleSubmit,
            getFieldProps,
            setFieldValue,
            values,
          }) => {
            const oldPhoneError = this.getErrorAndHelperText(
              "old_phone_number",
              errors as FormError,
              touched as FormErrorTouched
            );
            const newPhoneError = this.getErrorAndHelperText(
              "full_phone_number",
              errors as FormError,
              touched as FormErrorTouched
            );
            const old_country_code = this.getErrorAndHelperText(
              "old_country_code",
              errors as FormError,
              touched as FormErrorTouched
            );
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid
                    xs={12}
                    style={{ marginBottom: "35px" }}
                    className="enter-prise-input-grid"
                  >
                    <InputField
                      {...getFieldProps("current_password")}
                      id="current_password"
                      label="Current Password"
                      labelClass="enter-prise-input-label"
                      type="text"
                      error={
                        this.getErrorAndHelperText(
                          "current_password",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      placeholder="Current Password"
                      helperText={
                        this.getErrorAndHelperText(
                          "current_password",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      fullWidth
                      data-test-id="userinfo-name"
                    />
                    <ForgetPwdLink
                      onClick={this.goToForgotPasswordPage}
                      underline="always"
                    >
                      Forget Password
                    </ForgetPwdLink>
                  </Grid>
                  <Grid xs={12}>
                    <Box style={webStyles.dialogInput}>
                      <InputLabel className="enter-prise-input-label">
                        {"New Phone Number"}
                      </InputLabel>
                      <PhoneInput
                        {...getFieldProps("full_phone_number")}
                        country={
                          this.state.isoCountryName.toString().toLowerCase() ||
                          "pt"
                        }
                        buttonStyle={{
                          border: "none",
                          background: "transparent",
                        }}
                        placeholder="Your contact number"
                        inputStyle={{
                          width: "100%",
                          height: "32px",
                          border: "none",
                          borderBottom:
                            Boolean(errors.full_phone_number) &&
                            touched.full_phone_number
                              ? "2px solid #EF4444"
                              : "1px solid #B7B7B7",
                          borderRadius: "0px",
                          paddingLeft: "40px",
                        }}
                        dropdownStyle={{ fontSize: "13px" }}
                        value={values.country_code + values.full_phone_number}
                        onChange={(phone, data: { dialCode: string }) => {
                          const phoneNumber = phone.substring(
                            data.dialCode.length
                          );
                          setFieldValue("full_phone_number", phoneNumber);
                          setFieldValue("country_code", data.dialCode);
                        }}
                        data-test-id="new-phone"
                      />
                      {newPhoneError.isError && (
                        <FormHelperText style={{ color: "#f44336" }}>
                          {newPhoneError.helperText}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box style={webStyles.dialogInput}>
                      <InputLabel className="enter-prise-input-label">
                        {"Confirm Phone Number"}
                      </InputLabel>
                      <PhoneInput
                        {...getFieldProps("old_phone_number")}
                        data-test-id="old-phone"
                        country={
                          this.state.isoCountryName.toString().toLowerCase() ||
                          "pt"
                        }
                        buttonStyle={{
                          border: "none",
                          background: "transparent",
                        }}
                        placeholder="Your old contact number"
                        inputStyle={{
                          width: "100%",
                          height: "32px",
                          border: "none",
                          borderBottom:
                            Boolean(errors.old_phone_number) &&
                            touched.old_phone_number
                              ? "2px solid #EF4444"
                              : "1px solid #B7B7B7",
                          borderRadius: "0px",
                          paddingLeft: "40px",
                        }}
                        dropdownStyle={{ fontSize: "13px" }}
                        value={
                          values.old_country_code + values.old_phone_number
                        }
                        onChange={(phone, data: { dialCode: string }) => {
                          const phoneNumber = phone.substring(
                            data.dialCode.length
                          );
                          setFieldValue("old_phone_number", phoneNumber);
                          setFieldValue("old_country_code", data.dialCode);
                        }}
                      />
                      {oldPhoneError.isError || old_country_code.isError && (
                        <FormHelperText
                          data-test-id="old-phone-error"
                          style={{ color: "#f44336" }}
                        >
                          {oldPhoneError.helperText || old_country_code.helperText}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                  <Box
                    style={{
                      color: "#ef4444",
                      margin: "0px auto 10px auto",
                      fontSize: "14px",
                    }}
                  >
                    {this.state.dialogError}
                  </Box>
                  <Grid xs={12} className="dialog-btn-container">
                    <DialogButton type="submit">Continue</DialogButton>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </CustomDialog>
    );
  };

  renderResetPasswordDialog = () => {
    return (
      <CustomDialog
        open={this.state.isResetPasswordDialogOpen}
        title="Password Reset"
        className="dialog-title"
        onClose={() => this.handleDialogClose("ResetPassword")}
        data-test-id="reset-password-dialog"
      >
        <Formik
          initialValues={resetPasswordInitialValue}
          validationSchema={resetPasswordSchema}
          data-test-id="reset-password-formik"
          enableReinitialize
          onSubmit={(values) => {
            this.handleResetPasswordForm(values);
          }}
        >
          {({ errors, touched, handleSubmit, getFieldProps }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid
                    xs={12}
                    className="enter-prise-input-grid"
                    style={{ marginBottom: "35px" }}
                  >
                    <InputField
                      {...getFieldProps("current_password")}
                      fullWidth
                      placeholder="Current Password"
                      label="Current Password"
                      type="text"
                      id="current_password"
                      labelClass="enter-prise-input-label"
                      error={
                        this.getErrorAndHelperText(
                          "current_password",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      data-test-id="userinfo-name"
                      helperText={
                        this.getErrorAndHelperText(
                          "current_password",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                    />
                    <ForgetPwdLink
                      onClick={this.goToForgotPasswordPage}
                      data-test-id="forget-pwd"
                      underline="always"
                    >
                      Forget Password
                    </ForgetPwdLink>
                  </Grid>
                  <Grid xs={12} className="enter-prise-input-grid">
                    <InputField
                      {...getFieldProps("password")}
                      fullWidth
                      label="New password"
                      placeholder="New Password"
                      id="email"
                      type="text"
                      style={webStyles.dialogInput}
                      labelClass="enter-prise-input-label"
                      data-test-id="userinfo-name"
                      error={
                        this.getErrorAndHelperText(
                          "password",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      helperText={
                        this.getErrorAndHelperText(
                          "password",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                    />
                  </Grid>
                  <Grid xs={12} className="enter-prise-input-grid">
                    <InputField
                      {...getFieldProps("confirm_password")}
                      fullWidth
                      placeholder="Confirm New Password"
                      label="Confirm New Password"
                      type="text"
                      id="confirm_password"
                      style={webStyles.dialogInput}
                      labelClass="enter-prise-input-label"
                      helperText={
                        this.getErrorAndHelperText(
                          "confirm_password",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      error={
                        this.getErrorAndHelperText(
                          "confirm_password",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      data-test-id="reset-confirm-pwd"
                    />
                  </Grid>
                  <Box
                    style={{
                      color: "#ef4444",
                      fontSize: "14px",
                      margin: "0px auto 10px auto",
                    }}
                  >
                    {this.state.dialogError}
                  </Box>
                  <Grid xs={12} className="dialog-btn-container">
                    <DialogButton type="submit">Continue</DialogButton>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </CustomDialog>
    );
  };

  renderJustLoggedDialog = () => {
    return(
      <CustomDialog
        open={false}
        className="dialog-title"
        data-test-id="logged-in-dialog"
      >
        <JustLoggedDialogInfo>
        You just logged in to the new device
        </JustLoggedDialogInfo>
      </CustomDialog>
    )
  }

  renderEndSessionDialog = () => {
    return (
      <ResetPasswordDialog
        open={this.state.isEndSessionDialogOpen}
        className="icon-close-reset"
        onClose={() => this.handleDialogClose("EndSession")}
        data-test-id="end-session-dialog"
      >
       <ResetPasswordDialogInfo>
       Are You Sure you want to End Session with <span style={{color:"#6F05EA"}}>{this.state.endSessionDeviceInfo.name}</span> ?
       </ResetPasswordDialogInfo>
       <Box
                        style={{
                          color: "#ef4444",
                          margin: "0px auto 10px auto",
                          fontSize: "14px",
                        }}
                      >
                        {this.state.dialogError}
                      </Box>
       <EndSessionDialogBtnContainer>
                      <EditUpdateButton
                        variant="outlined"
                        onClick={() => {
                          this.handleEndSessionCancelClick();
                        }}
                        className="cancel-btn"
                        data-test-id="cancel-btn"
                      >
                        Cancel
                      </EditUpdateButton>
                      <Button data-test-id="end-session-dialog-btn" onClick={() => this.handelDialogEndSession(this.state.endSessionDeviceInfo.id)} className="end-session-btn">End Session</Button>
                      
                    </EndSessionDialogBtnContainer>
      </ResetPasswordDialog>
    );
  }

  renderLoggedSessionCard = () => {
    return (
      <Grid container className="logged-in-card-container">
        {this.state.loggedInDeviceList.length > 0 && this.state.loggedInDeviceList.map((device, index) => (
          <Grid item xs={12} className="logged-in-card" key={index}>
            <img src={this.getIconSrc(device.attributes.device_name)} alt="device-img" className="device-img" />
            <Box className="device-name-container">
              <Typography className="device-name">
                {`${device.attributes.device_name} ${device.attributes.location} ${device.attributes.last_active}`}
              </Typography>
              <Button data-test-id="end-session" onClick={() => this.handleEndSessionClick(device.attributes.device_name, device.attributes.device_id)} className="end-session-btn">End Session</Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  renderFileError = () => {
    return(
      this.state.fileError && (
        <Typography style={{color:"#f44336", fontSize:"0.75rem"}}>{this.state.fileError}</Typography>
      )
    )
  }

  renderGeneralInfoForm = () => {
    return (
      <Formik
        initialValues={this.state.generalInfoForm}
        validationSchema={generalInfoSchema}
        enableReinitialize
        onSubmit={(values) => {
          this.handleGeneralInfoForm(values);
        }}
        data-test-id="general-info-form"
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          getFieldProps,
          setFieldValue,
          values,
          resetForm,
        }) => {
          const phoneError = this.getErrorAndHelperText(
            "full_phone_number",
            errors as FormError,
            touched as FormErrorTouched
          );
          const handleAddressChange = (
            event: React.ChangeEvent<HTMLInputElement>,
            addressValue:string
          ) => {
            this.handleGeneralInfoAddressChange(addressValue);
            setFieldValue("address", addressValue);
          };
          return (
            <form
              onSubmit={handleSubmit}
              style={{
                backgroundColor: "#fff",
                padding: "24px",
                marginTop: "35px",
              }}
            >
              <GeneralInfoFormContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid className="general-info-img-container upload-file-container">
                    <Box className="upload-img-box">
                      <input
                        type="file"
                        className="upload-file-input"
                        data-test-id="general-info-file"
                        disabled={!this.state.isGeneralInfoEdit}
                        onChange={(event) => this.handleGeneralInfoFile(event)}
                      />
                      <img
                        className="user-img"
                        src={
                          this.state.generalInfoFileUrl !== ""
                            ? `${this.state.generalInfoFileUrl}`
                            : uploadFileIcon
                        }
                        alt="user-avatar"
                      />
                    </Box>
                    <Box className="user-name-container">
                      <Typography className="user-name-label">Name</Typography>
                      <Typography className="user-name">
                        Love Pet Alliance
                      </Typography>
                    </Box>
                  </Grid>
                  {this.renderFileError()}
                </Grid>
                <Grid item xs={12} className="section-title">
                  General Info
                </Grid>
                {this.state.isGeneralInfoEdit && (
                  <Grid item xs={12} className="admin-info-container">
                    <img className="info-icon-img" src={infoIcon} />
                    <Typography className="info-icon-text">
                      To change your account details, please contact Petzai
                      admins.
                    </Typography>
                  </Grid>
                )}
                <Grid item md={4} xs={12}>
                  <InputField
                    {...getFieldProps("full_name")}
                    fullWidth
                    id="full_name"
                    label="Full Name"
                    placeholder="Your name"
                    type="text"
                    labelStyle={webStyles.inputLabel}
                    error={
                      this.getErrorAndHelperText(
                        "full_name",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    helperText={
                      this.getErrorAndHelperText(
                        "full_name",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    disabled
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <InputField
                    {...getFieldProps("company_name")}
                    fullWidth
                    id="company_name"
                    label="Company Name"
                    placeholder="Your Company name"
                    type="text"
                    disabled
                    labelStyle={webStyles.inputLabel}
                    error={
                      this.getErrorAndHelperText(
                        "company_name",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    helperText={
                      this.getErrorAndHelperText(
                        "company_name",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                  />
                </Grid>
                <GridNone item md={4}></GridNone>
                <Grid item md={4} xs={12}>
                  <InputField
                    {...getFieldProps("iban")}
                    fullWidth
                    id="iban"
                    label="IBAN"
                    placeholder="IBAN number"
                    type="text"
                    disabled
                    labelStyle={webStyles.inputLabel}
                    error={
                      this.getErrorAndHelperText(
                        "iban",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    helperText={
                      this.getErrorAndHelperText(
                        "iban",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <InputField
                    {...getFieldProps("tax_number")}
                    fullWidth
                    id="tax_number"
                    label="Tax Number"
                    placeholder="Your name"
                    type="text"
                    labelStyle={webStyles.inputLabel}
                    error={
                      this.getErrorAndHelperText(
                        "tax_number",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    disabled
                    helperText={
                      this.getErrorAndHelperText(
                        "tax_number",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                  />
                </Grid>
                <GridNone item md={4}></GridNone>
                <Grid item md={4} xs={12}>
                  <InputField
                    {...getFieldProps("client_id")}
                    fullWidth
                    id="client_id"
                    disabled
                    label="Client ID"
                    placeholder="Client ID"
                    type="text"
                    labelStyle={webStyles.inputLabel}
                    error={
                      this.getErrorAndHelperText(
                        "client_id",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    helperText={
                      this.getErrorAndHelperText(
                        "client_id",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                  />
                </Grid>
                <Grid item xs={12} className="section-title">
                  Address
                </Grid>
                <Grid item md={4} xs={12}>
                <Box className="general-info-address">
                    <InputLabel
                          className="new-establish-address-label"
                          >
                            Address
                          </InputLabel>
                    <Autocomplete
                      options={this.getOptionForSuggestionAddress()}
                      freeSolo
                      onBlur={this.handleNewEstablishmentAddressBlur}
                      value={this.state.addGeneralInfoAddress || this.state.generalInfoForm.address}
                      disabled={!this.state.isGeneralInfoEdit}
                      noOptionsText={"No record found"}
                      onInputChange={(event, newInputValue: any) => {
                        handleAddressChange(
                          event as React.ChangeEvent<HTMLInputElement>,
                          newInputValue
                        );
                      }}
                      data-test-id="general-info-address"
                      renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Your Address"
                            disabled={!this.state.isGeneralInfoEdit}
                            {...getFieldProps("address")}
                            variant="standard"
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={AddressIcon} />
                                </InputAdornment>
                              ),
                            }}
                            error={
                              this.getErrorAndHelperText(
                                "address",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).isError
                            }
                            helperText={
                              this.getErrorAndHelperText(
                                "address",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).helperText
                            }
                          />
                      )}
                    />
                     </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <CommonSelect
                    {...getFieldProps("country")}
                    name="country"
                    placeholder="Your country"
                    className="country-city-select"
                    onBlur={handleBlur}
                    labelStyle={webStyles.inputLabel}
                    disabled={!this.state.isGeneralInfoEdit}
                    label="Country"
                    onChange={(event) => {
                      setFieldValue("country", event.target.value);
                      this.handleCountryNameChange(event?.target?.value);
                    }}
                    fullWidth
                    style={{ maxHeight: "240px", overflowY: "scroll" }}
                    id="country"
                    list={this.state.countries}
                    error={
                      this.getErrorAndHelperText(
                        "country",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    data-test-id="general-info-country"
                    helperText={
                      this.getErrorAndHelperText(
                        "country",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                  />
                </Grid>
                <GridNone item md={4}></GridNone>
                <Grid item md={4} xs={12} className="city-drop-down">
                  <CommonSelect
                    {...getFieldProps("city")}
                    disabled={!this.state.isGeneralInfoEdit}
                    value={values.city || this.state.generalInfoForm.city}
                    label="City"
                    style={{ maxHeight: "240px", overflowY: "auto" }}
                    id="city"
                    name="city"
                    className="country-city-select"
                    placeholder="Your city"
                    labelStyle={webStyles.inputLabel}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    list={this.state.cities}
                    error={
                      this.getErrorAndHelperText(
                        "city",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    helperText={
                      this.getErrorAndHelperText(
                        "city",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <InputField
                    {...getFieldProps("zip_code")}
                    fullWidth
                    id="zip_code"
                    label="Zip Code"
                    placeholder="Zip Code"
                    type="text"
                    labelStyle={webStyles.inputLabel}
                    error={
                      this.getErrorAndHelperText(
                        "zip_code",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    helperText={
                      this.getErrorAndHelperText(
                        "zip_code",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    disabled={!this.state.isGeneralInfoEdit}
                  />
                </Grid>
                <Grid item xs={12} className="section-title">
                  Contact us
                </Grid>
                <Grid item md={4} xs={12}>
                  <InputField
                    {...getFieldProps("email")}
                    fullWidth
                    id="email"
                    label="Email"
                    placeholder="Your Email"
                    type="text"
                    labelStyle={webStyles.inputLabel}
                    disabled
                    error={
                      this.getErrorAndHelperText(
                        "email",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    helperText={
                      this.getErrorAndHelperText(
                        "email",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    data-test-id="general-info-email"
                  />
                  <FieldUpdateButton
                    variant="text"
                    onClick={this.handleUpdateEmailClick}
                    data-test-id="general-info-update-email"
                    style={
                      !this.state.isGeneralInfoEdit ? { display: "none" } : {}
                    }
                  >
                    Update Email
                  </FieldUpdateButton>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box className="phone-container">
                    <InputLabel
                      style={{
                        lineHeight: "22px",
                        fontWeight: 600,
                        color: "#334155",
                        fontSize: "18px",
                      }}
                    >
                      {"Phone"}
                    </InputLabel>
                    <PhoneInput
                      {...getFieldProps("full_phone_number")}
                      buttonStyle={{
                        border: "none",
                        background: "transparent",
                      }}
                      disabled
                      inputStyle={{
                        border: "none",
                        width: "100%",
                        borderBottom:
                          Boolean(errors.full_phone_number) &&
                          touched.full_phone_number
                            ? "2px solid #EF4444"
                            : "1px solid #B7B7B7",
                        borderRadius: "0px",
                        height: "32px",
                        paddingLeft: "40px",
                        backgroundColor: "#fff",
                      }}
                      value={values.country_code + values.full_phone_number}
                      onChange={(phone, data: { dialCode: string }) => {
                        const phoneNumber = phone.substring(
                          data.dialCode.length
                        );
                        setFieldValue("country_code", data.dialCode);
                        setFieldValue("full_phone_number", phoneNumber);
                      }}
                      dropdownStyle={{ fontSize: "13px" }}
                      placeholder="Your contact number"
                      data-test-id="general-info-phone"
                    />
                    {phoneError.isError && (
                      <FormHelperText style={{ color: "#f44336" }}>
                        {phoneError.helperText}
                      </FormHelperText>
                    )}
                  </Box>
                  <FieldUpdateButton
                    variant="text"
                    onClick={this.handleUpdatePhoneClick}
                    data-test-id="update-phone"
                    style={
                      !this.state.isGeneralInfoEdit ? { display: "none" } : {}
                    }
                  >
                    Update Phone
                  </FieldUpdateButton>
                </Grid>
                <Grid item xs={12} className="section-title">
                  Social Media Link
                </Grid>
                <Grid item md={4} xs={12}>
                  {this.state.isGeneralInfoEdit ? (
                  <InputField
                    {...getFieldProps("faceBookLink")}
                    fullWidth
                    id="faceBookLink"
                    placeholder="FaceBook"
                    type="text"
                    error={
                      this.getErrorAndHelperText(
                        "faceBookLink",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    helperText={
                      this.getErrorAndHelperText(
                        "faceBookLink",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    disabled={!this.state.isGeneralInfoEdit}
                    label="Facebook"
                    labelStyle={webStyles.inputLabel}
                    startAdornment={<img src={facebookIcon} />}
                  /> ) : (
                  <InputField
                    value={values.faceBookLink}
                    label="Facebook"
                    id="faceBookLink"
                    data-test-id="facebook-link-click"
                    type="text"
                    placeholder="FaceBook"
                    onClick={() => this.handleGeneralInfoLinkClick(values.faceBookLink)}
                    labelStyle={webStyles.inputLabel}
                    startAdornment={<img src={facebookIcon} />}
                    fullWidth
                  />
                  )}
                </Grid>
                <Grid item md={4} xs={12}>
                {this.state.isGeneralInfoEdit ? (
                  <InputField
                    {...getFieldProps("instagramLink")}
                    fullWidth
                    id="instagramLink"
                    error={
                      this.getErrorAndHelperText(
                        "instagramLink",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    placeholder="Instagram"
                    helperText={
                      this.getErrorAndHelperText(
                        "instagramLink",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    type="text"
                    labelStyle={webStyles.inputLabel}
                    label="InstaGram"
                    startAdornment={<img src={instaGramIcon} />}
                    disabled={!this.state.isGeneralInfoEdit}
                  />
                ) : (
                  <InputField
                    value={values.instagramLink}
                    labelStyle={webStyles.inputLabel}
                    fullWidth
                    label="InstaGram"
                    data-test-id="instagram-link-click"
                    id="instagramLink"
                    placeholder="Instagram"
                    startAdornment={<img src={instaGramIcon} />}
                    onClick={() => this.handleGeneralInfoLinkClick(values.instagramLink)}
                    type="text"
                  />
                )}
                </Grid>
                <GridNone item md={4}></GridNone>
                <Grid item md={4} xs={12}>
                {this.state.isGeneralInfoEdit ? (
                <InputField
                    {...getFieldProps("linkedinLink")}
                    fullWidth
                    id="linkedinLink"
                    placeholder="Linkedin"
                    label="Linkedin"
                    type="text"
                    labelStyle={webStyles.inputLabel}
                    disabled={!this.state.isGeneralInfoEdit}
                    error={
                      this.getErrorAndHelperText(
                        "linkedinLink",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    helperText={
                      this.getErrorAndHelperText(
                        "linkedinLink",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    startAdornment={<img src={linkedinIcon} />}
                  />
                ) : (
                  <InputField
                    value={values.linkedinLink}
                    id="linkedinLink"
                    fullWidth
                    type="text"
                    placeholder="Linkedin"
                    label="Linkedin"
                    labelStyle={webStyles.inputLabel}
                    startAdornment={<img src={linkedinIcon} />}
                    data-test-id="linkedin-link-click"
                    onClick={() => this.handleGeneralInfoLinkClick(values.linkedinLink)}
                  />
                )}
                </Grid>
                <Grid item md={4} xs={12}>
                {this.state.isGeneralInfoEdit ? (
                  <InputField
                    {...getFieldProps("tikTokLink")}
                    fullWidth
                    disabled={!this.state.isGeneralInfoEdit}
                    id="tikTokLink"
                    error={
                      this.getErrorAndHelperText(
                        "tikTokLink",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    labelStyle={webStyles.inputLabel}
                    label="TikTok"
                    helperText={
                      this.getErrorAndHelperText(
                        "tikTokLink",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    placeholder="TikTok"
                    type="text"
                    startAdornment={<img src={tikTokIcon} />}
                  />
                ) : (
                  <InputField
                    value={values.tikTokLink}
                    startAdornment={<img src={tikTokIcon} />}
                    onClick={() => this.handleGeneralInfoLinkClick(values.tikTokLink)}
                    fullWidth
                    labelStyle={webStyles.inputLabel}
                    label="TikTok"
                    placeholder="TikTok"
                    data-test-id="tiktok-link-click"
                    type="text"
                    id="tikTokLink"
                  />
                )}
                </Grid>
                {this.state.generalInfoError.length > 0 && (
                  <Typography
                    style={{
                      color: "#EF4444",
                      width: "100%",
                      textAlign: "center",
                    }}
                    data-test-id="new-establish-error"
                  >
                    {this.state.generalInfoError}
                  </Typography>
                )}
                <Grid className="general-info-btn" item xs={12}>
                  {!this.state.isGeneralInfoEdit ? (
                    <EditUpdateButton
                      variant="outlined"
                      onClick={this.handleGeneralEditClick}
                      data-test-id="edit-btn"
                    >
                      Edit
                    </EditUpdateButton>
                  ) : (
                    <Box className="edit-update-btn">
                      <EditUpdateButton
                        variant="outlined"
                        onClick={() => {
                          this.handleGeneralCancelClick();
                          resetForm();
                        }}
                        className="cancel-btn"
                        data-test-id="cancel-btn"
                      >
                        Cancel
                      </EditUpdateButton>
                      <EditUpdateButton variant="outlined" type="submit">
                        Save
                      </EditUpdateButton>
                    </Box>
                  )}
                </Grid>
              </GeneralInfoFormContainer>
            </form>
          );
        }}
      </Formik>
    );
  };

  renderPasswordSecurity = () => {
    return (
      <PasswordSecurityContainer data-test-id="password-security">
        <Typography className="pwd-sec-title">Password reset</Typography>
        <EditUpdateButton data-test-id="reset-password-btn" onClick={() => this.handleResetPasswordClick()}>Reset Password</EditUpdateButton>
        <Typography className="pwd-sec-title">Logged sessions</Typography>
        <Typography className="logged-in-title">
          You're currently logged in on these devices:
        </Typography>
        {this.renderLoggedSessionCard()}
      </PasswordSecurityContainer>
    );
  };

  renderEstablishment = () => {
    return (
      <EstablishmentGrid container data-test-id="establish-container">
        {this.state.listEstablishment.data.length > 0 &&
          this.state.vendorEstablishmentListError.length === 0 &&
          this.state.listEstablishment.data.map((establishmentInfo) => {
            return (
              <Grid
                className="establishment-container select-establishment"
                xs={12}
                md={7}
                key={establishmentInfo.id}
                data-test-id="establishment-grid"
              >
                <Box data-test-id="establish-List" className="info-container" onClick={()=>{this.NavigateToCustomisableVendorProfile(establishmentInfo)}}>
                  <img
                    className="establish-img"
                    src={establishmentInfo.attributes.establishment_photo as string}
                    alt="device-img"
                  />
                  <Box className="establish-data-container">
                    <Typography className="establish-name">
                      {establishmentInfo.attributes.establishment_name}
                    </Typography>
                    <Box className="establish-location">
                      <img
                        className="location-img"
                        src={locationIcon}
                        alt="location-icon"
                      />
                      <Typography className="location-name">
                        {establishmentInfo.attributes.address}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <AntSwitch
                  checked={establishmentInfo.attributes.activated}
                  onChange={() =>
                    this.handleSwitchChange(
                      +establishmentInfo.id,
                      establishmentInfo.attributes.activated
                    )
                  }
                  data-test-id="switch"
                />
              </Grid>
            );
          })}
           {this.state.vendorEstablishmentListError.length > 0 &&
          
              <Typography
                style={{
                  color: "#EF4444",
                  width: "100%",
                  textAlign: "center",
                }}
                data-test-id="vendor-sign-up-error"
              >
                {this.state.vendorEstablishmentListError}
              </Typography>
          }
        <Box className="new-establish-btn-container">
          <Button
            className="new-establish-btn"
            data-test-id="general-info-new-establish"
            endIcon={<img src={plusIcon} alt="add-icon" />}
            onClick={() => {
              this.handleNewEstablishClick();
            }}
          >
            Add New Establishment{" "}
          </Button>
        </Box>
      </EstablishmentGrid>
    );
  };

  renderFinances = () => {
    const appointments = [
      {
        id: "PTZ19RS001",
        date: "12-02-2019",
        amount: "12,99€",
        status: "Pending",
      },
      {
        id: "PTZ19RF001",
        date: "19-06-2024",
        amount: "19,99€",
        status: "Paid",
      },
    ];
    return (
      <PasswordSecurityContainer>
        <Typography className="pwd-sec-title">Payments</Typography>
        <Grid container className="finance-container">
          <Grid item xs={12} md={6} sm={8}>
            <TextField
              value={this.state.searchText}
              name="search"
              placeholder="Search"
              fullWidth
              className="finance-search"
              data-test-id="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} alt="search-icon" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              onChange={(event) => this.handleSearch(event)}
            />
          </Grid>
          <GridNone md={2} item />
          <Grid item xs={12} md={4} sm={4}>
            <FormControl className="finance-status" fullWidth>
              <Select
                value={this.state.statusType}
                variant="outlined"
                onChange={(event) => this.handleStatusChange(event)}
                displayEmpty
                data-test-id="finance-status"
                IconComponent={() => (
                  <img
                    src={dropDownIcon}
                    alt="dropdown"
                    className="drop-down-icon"
                    data-test-id="finance-drop-down-img"
                  />
                )}
              >
                <MenuItem value="">
                  <span className="finance-status-empty">Status</span>
                </MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Box className="table-box">
            <TableContainer component={Paper} className="table-container">
              <Table stickyHeader style={{ width: "100%" }}>
                <TableHead className="table-header">
                  <TableRow>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Appointment Date</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appointments.map((appointment) => (
                    <TableRow key={appointment.id}>
                      <TableCell>{appointment.id}</TableCell>
                      <TableCell>{appointment.date}</TableCell>
                      <TableCell>{appointment.amount}</TableCell>
                      <TableCell
                        className={
                          appointment.status === "Paid"
                            ? "status-paid"
                            : "status-pending"
                        }
                      >
                        {appointment.status}
                      </TableCell>
                      <TableCell>
                        <img src={downloadIcon} alt="download-icon" />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </PasswordSecurityContainer>
    );
  };

  renderSelectEstablishment() {
    return (
      <EstablishmentGrid container data-test-id="establish-container">
        {this.state.listEstablishment.data.length > 0 &&
          this.state.vendorEstablishmentListError.length === 0 && (
            <>
              <Typography className="enter-prise-title">
                Select Your Establishment
              </Typography>
              {this.state.listEstablishment.data.map((establishmentData) => {
                return (
                  <Grid
                    className="establishment-container select-establishment"
                    xs={12}
                    key={establishmentData.id}
                  >
                    <Box className="info-container" onClick={()=>{this.NavigateToCustomisableVendorProfile(establishmentData)}}>
                      <img
                        className="establish-img"
                        src={establishmentData.attributes.establishment_photo as string}
                        alt="device-img"
                      />
                      <Box className="establish-data-container">
                        <Typography className="establish-name">
                          {establishmentData.attributes.establishment_name}
                        </Typography>
                        <Box className="establish-location">
                          <img
                            className="location-img"
                            src={locationIcon}
                            alt="location-icon"
                          />
                          <Typography className="location-name">
                            {establishmentData.attributes.address}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </>
          )}
        {this.state.listEstablishment.data.length === 0 &&
          this.state.vendorEstablishmentListError.length === 0 && (
            <>
              <Typography className="info-add-new">
                Please add an establishment
              </Typography>
            </>
          )}
        {this.state.vendorEstablishmentListError.length > 0 &&
              <Typography
                style={{
                  color: "#EF4444",
                  width: "100%",
                  textAlign: "center",
                }}
                data-test-id="vendor-sign-up-error"
               
              >
                {this.state.vendorEstablishmentListError}
              </Typography>
          }
        <Box className="new-establish-btn-container">
          <Button
            className="new-establish-btn"
            endIcon={<img src={plusIcon} alt="add-icon" />}
            data-test-id="new-establish-btn"
            onClick={() => {
              this.handleNewEstablishClick();
            }}
          >
            Add New Establishment
          </Button>
        </Box>
      </EstablishmentGrid>
    );
  }

  renderAddNewEstablish() {
    return (
      <EstablishmentGrid container data-test-id="establish-container">
        <Typography data-test-id="add-new-title" className="enter-prise-title">
          Add New Establishment
        </Typography>
        <Formik
          initialValues={this.state.newEstablishmentForm}
          validationSchema={newEstablishmentSchema}
          enableReinitialize
          onSubmit={(values) => {
            this.addNewEstablishmentApiCall(values);
          }}
          data-test-id="new-establish-form"
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            getFieldProps,
            setFieldValue,
            values,
            resetForm,
          }) => {
            const phoneError = this.getErrorAndHelperText(
              "full_phone_number",
              errors as FormError,
              touched as FormErrorTouched
            );

            const handleAddressChange = (
              event: React.ChangeEvent<HTMLInputElement>,
              addressValue:string
            ) => {
              this.handleAddNewEstablishmentAddressChange(addressValue);
              setFieldValue("address", addressValue);
            };

            return (
              <form onSubmit={handleSubmit}>
                <GeneralInfoFormContainer container spacing={2}>
                  <Grid item xs={12}>
                    <Grid className="general-info-img-container upload-file-container">
                      <Box className="upload-img-box">
                        <input type="file" className="upload-file-input" data-test-id="add-establish-file" onChange={(event) => this.handleAddNewEstablishmentFile(event)} />
                        <img
                          className="user-img"
                          src={this.state.newEstablishmentUrl !== "" ? `${this.state.newEstablishmentUrl}` : uploadFileIcon}
                          alt="user-avatar"
                        />
                      </Box>
                      <Box className="user-name-container">
                        <Typography className="user-name-label">
                          Name
                        </Typography>
                        <Typography className="user-name">
                          Love Pet Alliance
                        </Typography>
                      </Box>
                      </Grid>
                      {this.renderFileError()}
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputField
                      {...getFieldProps("establishment_name")}
                      fullWidth
                      id="establishment_name"
                      startAdornment={<img src={emailIcon} />}
                      label="Establishment Name"
                      placeholder="Establishment Name"
                      type="text"
                      labelStyle={webStyles.inputLabel}
                      error={
                        this.getErrorAndHelperText(
                          "establishment_name",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      helperText={
                        this.getErrorAndHelperText(
                          "establishment_name",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      data-test-id="add-establishment-name"
                    />
                  </Grid>
                  <Grid item md={4} xs={12} className="new-establishment-address">
                    <Box>
                    <InputLabel
                          className="new-establish-address-label"
                          >
                            Address
                          </InputLabel>
                    <Autocomplete
                      options={this.getOptionForSuggestionAddress()}
                      noOptionsText={"No record found"}
                      onInputChange={(event, newInputValue: any) => {
                        handleAddressChange(
                          event as React.ChangeEvent<HTMLInputElement>,
                          newInputValue
                        );
                      }}
                      onBlur={this.handleNewEstablishmentAddressBlur}
                      data-test-id="add-establish-info-address"
                      renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard" 
                            placeholder="Your Address"
                            {...getFieldProps("address")}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={AddressIcon} />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            error={
                              this.getErrorAndHelperText(
                                "address",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).isError
                            }
                            helperText={
                              this.getErrorAndHelperText(
                                "address",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).helperText
                            }
                          />
                      )}
                    />
                     </Box>
                  </Grid>
                  <GridNone item md={4}></GridNone>
                  <Grid item md={4} xs={12}>
                    <CommonSelect
                      {...getFieldProps("country")}
                      name="country"
                      id="country"
                      className="country-city-select"
                      onBlur={handleBlur}
                      labelStyle={webStyles.inputLabel}
                      placeholder="Your country"
                      style={{ maxHeight: "240px", overflowY: "scroll" }}
                      label="Country"
                      onChange={(event) => {
                        setFieldValue("country", event.target.value);
                        this.handleCountryNameChange(event.target.value);
                      }}
                      fullWidth
                      startAdornment={<img src={countryIcon} />}
                      error={
                        this.getErrorAndHelperText(
                          "country",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      data-test-id="new-establish-country"
                      list={this.state.countries}
                      helperText={
                        this.getErrorAndHelperText(
                          "country",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CommonSelect
                      {...getFieldProps("city")}
                      value={values.city}
                      label="City"
                      style={{ maxHeight: "240px", overflowY: "auto" }}
                      id="city"
                      startAdornment={<img src={cityIcon} />}
                      name="city"
                      className="country-city-select"
                      placeholder="Your city"
                      labelStyle={webStyles.inputLabel}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      list={this.state.cities}
                      error={
                        this.getErrorAndHelperText(
                          "city",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      helperText={
                        this.getErrorAndHelperText(
                          "city",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                    />
                  </Grid>
                  <GridNone item md={4}></GridNone>
                  <Grid item md={4} xs={12}>
                    <InputField
                      {...getFieldProps("zip_code")}
                      fullWidth
                      id="zip_code"
                      label="Zip Code"
                      placeholder="Zip Code"
                      startAdornment={<img src={ZipCodeIcon} />}
                      type="text"
                      labelStyle={webStyles.inputLabel}
                      error={
                        this.getErrorAndHelperText(
                          "zip_code",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      helperText={
                        this.getErrorAndHelperText(
                          "zip_code",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                    />
                  </Grid>
                  <Grid item xs={12} className="section-title">
                    Contact us
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputField
                      {...getFieldProps("email")}
                      fullWidth
                      id="email"
                      label="Email"
                      placeholder="Your Email"
                      type="text"
                      labelStyle={webStyles.inputLabel}
                      error={
                        this.getErrorAndHelperText(
                          "email",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      helperText={
                        this.getErrorAndHelperText(
                          "email",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      data-test-id="general-info-email"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box className="phone-container">
                      <InputLabel
                        style={{
                          lineHeight: "22px",
                          fontWeight: 600,
                          color: "#334155",
                          fontSize: "18px",
                        }}
                      >
                        {"Phone"}
                      </InputLabel>
                      <PhoneInput
                        {...getFieldProps("full_phone_number")}
                        buttonStyle={{
                          border: "none",
                          background: "transparent",
                        }}
                        country={
                          this.state.isoCountryName.toString().toLowerCase() ||
                          "pt"
                        }
                        inputStyle={{
                          border: "none",
                          width: "100%",
                          borderBottom:
                            Boolean(errors.full_phone_number) &&
                            touched.full_phone_number
                              ? "2px solid #EF4444"
                              : "1px solid #B7B7B7",
                          borderRadius: "0px",
                          height: "32px",
                          paddingLeft: "40px",
                        }}
                        value={values.country_code + values.full_phone_number}
                        onChange={(phone, data: { dialCode: string }) => {
                          const phoneNumber = phone.substring(
                            data.dialCode.length
                          );
                          setFieldValue("country_code", data.dialCode);
                          setFieldValue("full_phone_number", phoneNumber);
                        }}
                        dropdownStyle={{ fontSize: "13px" }}
                        placeholder="Your contact number"
                        data-test-id="new-establish-phone"
                      />
                      {phoneError.isError && (
                        <FormHelperText style={{ color: "#f44336" }}>
                          {phoneError.helperText}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} className="section-title">
                    Social Media Link
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputField
                      {...getFieldProps("faceBookLink")}
                      fullWidth
                      id="faceBookLink"
                      placeholder="Enter FaceBook Link"
                      type="text"
                      label="Facebook"
                      labelStyle={webStyles.inputLabel}
                      startAdornment={<img src={facebookIcon} />}
                      error={
                        this.getErrorAndHelperText(
                          "faceBookLink",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      helperText={
                        this.getErrorAndHelperText(
                          "faceBookLink",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputField
                      {...getFieldProps("instagramLink")}
                      fullWidth
                      id="instagramLink"
                      placeholder="Enter Instagram Link"
                      type="text"
                      error={
                        this.getErrorAndHelperText(
                          "instagramLink",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      helperText={
                        this.getErrorAndHelperText(
                          "instagramLink",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      labelStyle={webStyles.inputLabel}
                      label="InstaGram"
                      startAdornment={<img src={instaGramIcon} />}
                    />
                  </Grid>
                  <GridNone item md={4}></GridNone>
                  <Grid item md={4} xs={12}>
                    <InputField
                      {...getFieldProps("linkedinLink")}
                      fullWidth
                      id="linkedinLink"
                      placeholder="Enter Linkedin Link"
                      label="Linkedin"
                      type="text"
                      error={
                        this.getErrorAndHelperText(
                          "linkedinLink",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      labelStyle={webStyles.inputLabel}
                      startAdornment={<img src={linkedinIcon} />}
                      helperText={
                        this.getErrorAndHelperText(
                          "linkedinLink",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputField
                      {...getFieldProps("tikTokLink")}
                      fullWidth
                      id="tikTokLink"
                      placeholder="Enter TikTok Link"
                      type="text"
                      labelStyle={webStyles.inputLabel}
                      label="TikTok"
                      startAdornment={<img src={tikTokIcon} />}
                      helperText={
                        this.getErrorAndHelperText(
                          "tikTokLink",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      error={
                        this.getErrorAndHelperText(
                          "tikTokLink",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                    />
                  </Grid>
                  {this.state.vendorAddNewEstablishmentError.length > 0 && (
                    <Typography
                      style={{
                        color: "#EF4444",
                        width: "100%",
                        textAlign: "center",
                      }}
                      data-test-id="new-establish-error"
                    >
                      {this.state.vendorAddNewEstablishmentError}
                    </Typography>
                  )}
                  <Grid className="general-info-btn" item xs={12}>
                    <Box className="edit-update-btn">
                      <EditUpdateButton
                        variant="outlined"
                        onClick={() => {
                          this.handleNewEstablishCancelClick();
                          resetForm();
                        }}
                        className="cancel-btn"
                        data-test-id="new-establish-cancel-btn"
                      >
                        Cancel
                      </EditUpdateButton>
                      <EditUpdateButton variant="outlined" type="submit">
                        Save
                      </EditUpdateButton>
                    </Box>
                  </Grid>
                </GeneralInfoFormContainer>
              </form>
            );
          }}
        </Formik>
      </EstablishmentGrid>
    );
  }

  renderTabTitleContent() {
    switch (this.state.tabValue) {
      case 0:
        return (
          <Typography className="enter-prise-title">
            General Information
          </Typography>
        );
      case 1:
        return (
          <Typography className="enter-prise-title">
            Password & Security
          </Typography>
        );
      case 2:
        return (
          <Typography className="enter-prise-title">
            Establishments Control
          </Typography>
        );
      case 3:
        return <Typography className="enter-prise-title">Finances</Typography>;
      default:
        return null;
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
       <NavigationMenu id={''} navigation={this.props.navigation} handleCustomerHeaderSelect={this.handleCustomerHeaderSelect} isProfile={false}/>
        <EntrePriseContainer>
          {!this.state.isShowAddNewEstablish &&
            this.state.currentMenuItem === "4" &&
            this.renderSelectEstablishment()}
          {this.state.isShowAddNewEstablish && this.renderAddNewEstablish()}
          {!this.state.isShowAddNewEstablish &&
            this.state.currentMenuItem === "1" && (
              <>
                {this.renderTabTitleContent()}
                {this.renderGeneralInfoTab()}
                {this.renderEmailVerifyBox()}
                {this.renderUpdateEmail()}
                {this.renderUpdatePhone()}
                {this.renderResetPasswordDialog()}
                {this.renderEndSessionDialog()}
              </>
            )}
        </EntrePriseContainer>
        <AppFooter navigation={this.props.navigation} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  inputLabel: {
    fontSize: "13px",
    fontWeight: "400",
    color: "#6F05EA",
  },
  dialogInput: {
    marginBottom: "35px",
  },
  dialogInputLabel: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#334155",
  },
};
// Customizable Area End
