// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("../../config.json");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEdit:boolean,
  enabledSwitches: Record<string, boolean>;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PriceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
        isEdit:false,
        enabledSwitches: {},
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleSwitchChange = (checked:boolean, itemId: number) => {
  
    this.setState((prevState) => {
      const newEnabledSwitches = { ...prevState.enabledSwitches };
  
      if (checked) {
        newEnabledSwitches[itemId] = checked;
      } else {
        delete newEnabledSwitches[itemId];
      }
  
      return {
        enabledSwitches: newEnabledSwitches,
      };
    });
  };

  handleEditClick = () => {
    this.setState({isEdit:true});
  };

  handleCancelClick = () => {
    this.setState({isEdit:false});
  };
  // Customizable Area End
}