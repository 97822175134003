import React from 'react';
import { EventItem } from '../types';
import { Avatar, Box, Paper, Typography, styled } from '@material-ui/core';
import {CardDogImage, DayCareIcon, HotelsIcon, DogWalkingIcon, PetSittingIcon, TrainingIcon, GroomingIcon  } from '../assets';
import moment from 'moment';


type Props = {
    event: EventItem;
    onEventSelect: (event: EventItem | null) => void;
}

type IconType = 'groomer' | 'walking' | 'hotel' | 'training' | 'daycare' | 'sitting';


const Wrapper = styled(Paper)({
    width: '100%',
    display: 'flex',
    padding: '21px 28px',
    gap: '30px',

    "& .card-avatar": {
        width: '80px',
        height: '80px'
    },

    "& .appointment-content": {
        display: 'flex',
        flexDirection: 'column',
        gap: '13px',
        textAlign: 'left'
    },
    
    "& .app-pet-name": {
        color: '#000',
        fontSize: '20px',
        fontWeight: 600
    },
    "& .app-pet-service": {
        color: '#B7B7B7',
        fontSize: '16px',
        fontWeight: 600
    },
    "& .app-pet-time": {
        color: '#B7B7B7',
        fontSize: '16px',
        fontWeight: 400
    },
    "& .service-icon": {
        maxWidth: '30px',
        marginTop: '4px'
    },
    "& .requests-section": {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
    },
    "& .request-item": {
        fontSize: '16px',
        fontWeight: 400
    }
})

const iconMap: Record<IconType, string> = {
    'groomer': GroomingIcon,
    'walking': DogWalkingIcon,
    'hotel': HotelsIcon,
    'training': TrainingIcon,
    'daycare': DayCareIcon,
    'sitting': PetSittingIcon
}

const Appointment = ({ event, onEventSelect }: Props) => {


    const renderRequestText = () => {
        const mapRequestType = {
            'deletion': "Deletion",
            'transportation': "Transportation"
        }

        const mapRequestStatus = {
            "approved": "approved",
            "pending": "is on approval",
            "rejected": "rejected"
        }

        const mapColor = {
            "approved": "#059669",
            "pending": "#D4AD2D",
            "rejected": "#C10000"
        }
        
        if(event.data.appointment.requests.length) {
            return (
                <Box className='requests-section' >
                    {event.data.appointment.requests.map(request => (
                        <Typography className='request-item' style={{ color: mapColor[request.status as 'approved']}}>
                            {`${mapRequestType[request.type as 'deletion']} request ${mapRequestStatus[request.status]}`}
                        </Typography>
                    ))}
                </Box>
            )
        }

        return null
    }

    const formatTimeRange = (): string => {
        const startTime = moment(event.start).format('HH:mm');
        const endTime = moment(event.end).format('HH:mm');
        return `${startTime} - ${endTime}`;
    };

    return (
        <Wrapper square={false} elevation={3} variant="outlined" onClick={() => onEventSelect(event)}>
            <Avatar src={CardDogImage} className='card-avatar'/>
            <Box>
                <img className='service-icon' alt={event.data.appointment.serviceType} src={iconMap[event.data.appointment.serviceType as IconType]}/>
            </Box>
            <Box className='appointment-content'>
                <Typography className='app-pet-name'>{event.data.appointment.animal.name}</Typography>
                <Typography className='app-pet-service'>{event.data.appointment.service}</Typography>
                <Typography className='app-pet-time'>{formatTimeRange()}</Typography>
                {renderRequestText()}
            </Box>
        </Wrapper>
    )

}

export default Appointment