import React from "react";
// Customizable Area Start
import {
  Box, styled, Button, Typography, TextField, FormControlLabel, Checkbox
} from "@material-ui/core";
import { infoIcon, offSwitchIcon, onSwitchIcon } from "../assets";
import PricesVerticalController, { Props } from "./PricesVerticalController.web";

const LeftMenus: string[] = [
  "Once a week",
  "Twice a week",
  "3 times a week",
  "4 times a week",
  "5 times a week",
  "6 times a week",
  "7 times a week"
]
interface IService {
  "service_name": string,
  "once_a_week": string,
  "twice_a_week": string,
  "3_times_a_week": string,
  "4_times_a_week": string,
  "5_times_a_week": string,
  "6_times_a_week": string,
  "7_times_a_week": string,
}
const ServicesList: IService[] = [
  {
    "service_name": "Title 1",
    "once_a_week": "",
    "twice_a_week": "",
    "3_times_a_week": "",
    "4_times_a_week": "",
    "5_times_a_week": "",
    "6_times_a_week": "",
    "7_times_a_week": "",
  },
  {
    "service_name": "Title 2",
    "once_a_week": "",
    "twice_a_week": "",
    "3_times_a_week": "",
    "4_times_a_week": "",
    "5_times_a_week": "",
    "6_times_a_week": "",
    "7_times_a_week": "",
  },
  {
    "service_name": "Title 3",
    "once_a_week": "",
    "twice_a_week": "",
    "3_times_a_week": "",
    "4_times_a_week": "",
    "5_times_a_week": "",
    "6_times_a_week": "",
    "7_times_a_week": "",
  }
]
const inputList: string[] = [
  "once_a_week",
  "twice_a_week",
  "3_times_a_week",
  "4_times_a_week",
  "5_times_a_week",
  "6_times_a_week",
  "7_times_a_week",
]
// Customizable Area End

export default class PricesVertical extends PricesVerticalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderSwitches = () => {
    return (
      <>
        <Box style={style.switchWrapper}>
          {this.transportationSwitch()}
          {this.medicationSwitch()}
        </Box>

      </>
    )
  }
  transportationSwitch = () => {
    const { transportation } = this.state.form
    return (
      <RowCheckBox>
        <LeftCheckBox>
          <CustomCheckbox
            disabled={!this.state.isEditOn}
            value="Yes"
            control={
              <Checkbox
                name="transportation"
                onChange={(this.handleChangeSwitch)}
                value="Yes"
                className="transportation-switch"
                disableRipple
                checkedIcon={<img src={onSwitchIcon} />}
                icon={<img src={offSwitchIcon} />}
              />
            }
            label="Transportation"
            labelPlacement="start"
            data-testid="transportation-switch"
          />
        </LeftCheckBox>
        {transportation == "Yes" ?
          <RightCheckBox>
            <CustomInput
              id={"transportation-input"}
              disabled={!this.state.isEditOn}
            />
          </RightCheckBox>
          : <></>}
      </RowCheckBox>
    )
  }
  medicationSwitch = () => {
    const { medication } = this.state.form

    return (
      <RowCheckBox>
        <LeftCheckBox>
          <CustomCheckbox
            disabled={!this.state.isEditOn}
            value="Yes"
            control={
              <Checkbox
                name="medication"
                onChange={(this.handleChangeSwitch)}
                value="Yes"
                className="medication-switch"
                disableRipple
                checkedIcon={<img src={onSwitchIcon} />}
                icon={<img src={offSwitchIcon} />}
              />
            }
            label="Medication"
            labelPlacement="start"
            data-testid="medication-switch"
          />
        </LeftCheckBox>
        {medication == "Yes" ?
          <RightCheckBox>
            <CustomInput
              id={"medication-input"}
              disabled={!this.state.isEditOn}
            />
          </RightCheckBox>
          : <></>}

      </RowCheckBox>
    )
  }
  renderButtons = () => {
    return (
      <Box style={style.buttonWrapper}>
        <Box style={style.allPriceBox}>
          <img src={infoIcon} />
          <Typography style={style.allPrice}>All prices are per day.</Typography>
        </Box>
        <ButtonWrapper>
          {this.state.isEditOn ?
            <>
              <CustomButton
                style={style.blackBtn}
                onClick={this.handleCancelClick}
                data-testid="cancel-btn"
              >
                Cancel
              </CustomButton>
              <CustomButton
                style={style.blueBtn}
                onClick={this.handleCancelClick}
                data-testid="save-btn"
              >
                Save
              </CustomButton>
            </>
            :
            <CustomButton
              style={style.blueBtn}
              onClick={this.handleEditClick}
              data-testid="edit-price-btn"
            >
              Edit
            </CustomButton>
          }
        </ButtonWrapper>
      </Box>

    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainWrapper>
        <Form>
          <RowBox>
            <LeftBox>
              <ColumTitle>Select Recurrence</ColumTitle>
              <ChipWrapper>
                {LeftMenus.map((chip, index) => {
                  return (
                    <ChipBox key={index}>
                      <ChipTitle>{chip}</ChipTitle>
                    </ChipBox>
                  )
                })}
              </ChipWrapper>
            </LeftBox>
            <RightBox>
              {ServicesList.map((item, itemIndex) => {
                return (
                  <ColumWrapper key={itemIndex}>
                    <ColumTitle>{item.service_name}</ColumTitle>
                    <ColumSubTitle>Price</ColumSubTitle>
                    <InputWrapper>
                      {inputList.map((input_name, index) => {
                        return (
                          <CustomInput
                            key={index}
                            disabled={!this.state.isEditOn}
                            id={input_name}
                          />
                        )
                      })}
                    </InputWrapper>
                  </ColumWrapper>
                )
              })}

            </RightBox>
          </RowBox>
          {this.renderSwitches()}
          {this.renderButtons()}
        </Form>
      </MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  padding: "24px 8px",
})
const Form = styled('form')({
  marginBottom: 0
})
const ColumTitle = styled(Typography)({
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "22px",
  color: "#6F05EA"
})
const ColumSubTitle = styled(Typography)({
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "14px",
  color: "#6F05EA"
})
const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "32px",
})
const LeftBox = styled(Box)({
  width: "100%",
  maxWidth: "200px"
})
const RightBox = styled(Box)({
  width: "100%",
  overflowX: "auto",
  display: "flex",
  flexDirection: "row",
  columnGap: "26px",
  paddingBottom: "48px"
})
const ChipWrapper = styled(Box)({
  marginTop: 32,
  display: "flex",
  flexDirection: "column",
  rowGap: "16px"
})
const ChipBox = styled(Box)({
  padding: "14px 12px",
  borderRadius: "8px",
  boxShadow: "0px 2px 8px 0px #0000000F",
  backgroundColor: "#F8F8F8",
})
const ChipTitle = styled(Box)({
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#0F172A"
})

const ColumWrapper = styled(Box)({
  minWidth: "176px"
})
const InputWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "26px",
  marginTop: 18
})
const ButtonWrapper = styled(Box)({
  padding: "0px",
  display: "flex",
  columnGap: "12px",
})

const CustomButton = styled(Button)({
  height: "40px",
  width: "140px",
  padding: "8px 0",
  borderRadius: "100px",
  textAlign: "center",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "24px",
  textTransform: "none"
})
const CustomInput = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInputBase-input": {
    height: "34px",
    padding: "8px !important",
    "&::placeholder": {
      color: "rgba(166, 166, 166, 1)",
      opacity: 1,
      fontStyle: "italic",
      fontFamily: 'Montserrat',
      fontSize: "12px",
      fontWeight: 400,
    },
  },
})
const CustomCheckbox = styled(FormControlLabel)({
  margin: "0px",
  "& .MuiFormControlLabel-label": {
    color: "#6F05EA",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    userSelect: "none",
  },
  "& .MuiButtonBase-root": {
    padding: 0,
  },
  "& .Mui-checked.medication-switch": {
    marginLeft: "52px !important"
  },
  "& .medication-switch": {
    marginLeft: "46px !important"
  },
  "& .Mui-checked.transportation-switch": {
    marginLeft: "26px !important"
  },
  "& .transportation-switch": {
    marginLeft: "20px !important"
  },

})
const RowCheckBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "48px",
})
const LeftCheckBox = styled(Box)({
  width: "100%",
  maxWidth: "183px"
})
const RightCheckBox = styled(Box)({
  width: "100%",
  maxWidth: "176px"
})
const style = {
  blueBtn: {
    color: "#6F05EA",
    border: "1px solid #6F05EA",
  } as React.CSSProperties,
  blackBtn: {
    color: "#78716C",
    border: "1px solid #78716C",
  } as React.CSSProperties,
  blueColor: {
    color: "#6F05EA",
  } as React.CSSProperties,
  switchWrapper: {
    marginTop: 44,
    display: "flex",
    rowGap: "16px",
    flexDirection: "column",
  } as React.CSSProperties,
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end"
  } as React.CSSProperties,
  allPriceBox: {
    display: "flex",
    columnGap: "8px",
    alignItems: "center"
  } as React.CSSProperties,
  allPrice: {
    fontFamily: 'Montserrat',
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
    color: "#F87171"
  } as React.CSSProperties,
}
// Customizable Area End