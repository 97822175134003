import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Typography, styled } from '@material-ui/core';
import moment from 'moment';

import { EventItem } from '../types';
import { leftArrow, Portrait, CardDogImage } from '../assets';
import { defaultButton } from './FormWrapper';

type Props = {
    selectedEvent: EventItem
    onBackClick: (val: EventItem | null) => void; 
}

const Wrapper = styled(Box)(({ theme }) => ({
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    "& .header": {
        display: 'flex',
        gap: '24px',
        color: '#000',
        marginBottom: '40px',
        alignItems: 'center'
    },
    "& .header-text": {
        fontSize: '40px',
        fontWeight: 600,
    },
    "& .sections": {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px'
    },
    "& .section-wrapper": {
        background: '#F6F6F6',
        padding: '32px 38px',
    },
    "& .fields-wrapper": {
        display: 'flex',
        gap: '32px',
        flexDirection: 'column',
        [theme.breakpoints.up("md")]: {
            flexDirection: 'row',
          },
    },
    "& .field-set": {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        width: '100%'
    },
    "& .arrow-style": {
        userSelect: 'none', cursor: 'pointer'
    },
    "& .section-header": {
        display: 'flex', gap: '7px', alignItems: 'center', marginBottom: '32px'
    },
    "& .section-text": {
        color: '#6F05EA',
        fontSize: '18px',
        fontWeight: 600
    },
    "& .field-wrapper": {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        width: '100%'
    },
    "& .field-label": {
        color: '#6F05EA',
        fontSize: '12px',
        fontWeight: 400
    },
    "& .field-value": {
        color: '#000000',
        fontSize: '18px',
        fontWeight: 500
    },
    "& .buttons-wrapper": {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        gap: '20px'
    },
    "& .secondary-btn": {
        ...defaultButton,
        border: "1px solid #4B4B4B",
        color: '#4B4B4B',
        padding: '10px 26px',
        fontWeight: 700,
        fontSize: '18px'
    },
    "& .main-btn": {
        ...defaultButton,
        border: "1px solid #6F05EA",
        color: '#6F05EA',
        fontWeight: 700,
        fontSize: '18px',
        padding: '10px 26px',
    },
}))

const Field = ({title, value}: { title: string, value: string}) => {
    return (
        <Box className='field-wrapper'>
            <Typography className='field-label'>{title}</Typography>
            <Typography className='field-value'>{value}</Typography>
        </Box>
    )
}

const ApointmentDetails = ({ selectedEvent, onBackClick }: Props) => {
    const [actions, setActions] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const toggleActions = () => {
        setActions(!actions);
    }

    return (
        <Wrapper data-test-id="apointments-details">
            <Box className='header'>
                <Box data-test-id="back-btn" onClick={() => onBackClick(null)}><img src={leftArrow} className='arrow-style'/></Box>
                <Typography className='header-text'>{selectedEvent.data.appointment.animal.name}</Typography>
                <Typography className='header-text'>{selectedEvent.data.appointment.service}</Typography>
            </Box>
            <Box className='sections'>
                <Box className='section-wrapper'>
                    <Box className='section-header'>
                        <img src={Portrait} />
                        <Typography className='section-text'>Appointment Details</Typography>
                    </Box>
                    <Box className='fields-wrapper'>
                        <Box className='field-set'>
                            <Field title='Date' value={moment(selectedEvent.start).format('DD/MM/YYYY')} />
                            <Field title='Price' value={`${selectedEvent.data.appointment.price}€`} />
                        </Box>
                        <Box className='field-set'>
                            <Field title='Time' value={moment(selectedEvent.start).format('hh:mm')} />
                            <Field title='Service Type' value={selectedEvent.data.appointment.service} />
                        </Box>
                    </Box>
                </Box>
                <Box className='section-wrapper'>
                    <Box className='section-header'>
                        <Avatar src={CardDogImage} style={{ width: '24px', height: '24px'}} />
                        <Typography className='section-text'>{selectedEvent.data.appointment.animal.name}`s Details</Typography>
                    </Box>
                    <Box className='fields-wrapper'>
                        <Box className='field-set'>
                            <Field title='Animal' value={selectedEvent.data.appointment.animal.type} />
                            <Field title='Breed' value={selectedEvent.data.appointment.animal.breed} />
                            <Field title='Weight' value={selectedEvent.data.appointment.animal.weight} />
                            <Field title='Fur' value={selectedEvent.data.appointment.animal.fur} />
                        </Box>
                        <Box className='field-set'>
                            <Field title='Gender' value={selectedEvent.data.appointment.animal.gender} />
                            <Field title='Birthdate' value={selectedEvent.data.appointment.animal.birthdate} />
                            <Field title='Size' value={selectedEvent.data.appointment.animal.size} />
                        </Box>
                    </Box>
                </Box>
                <Box className='buttons-wrapper'>
                    {actions ? 
                    <>
                        <Button className='secondary-btn'>Request change</Button>
                        <Button className='secondary-btn'>Request cancellation</Button>
                        <Button className='main-btn' onClick={toggleActions}>Cancel</Button>
                    </> 
                    : <Button data-test-id='action-btn' className='main-btn' onClick={toggleActions}>Request change</Button>
                    }
                </Box>
            </Box>
        </Wrapper>
    )
}


export default ApointmentDetails;