import React, {CSSProperties } from 'react';
 // Customizable Area Start
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Box
} from '@material-ui/core';
import GeneralInformationController, { configJSON } from './GeneralInformationController.web';
import General from './generalInfo/General.web';
import Rooms from './generalInfo/Rooms.web';
import Services from './generalInfo/Services.web';
import CommoditiesWeb from './generalInfo/Commodities.web';
import SpacesWeb from './generalInfo/Spaces.web';
import PricesVertical from './generalInfo/PricesVertical.web';
import PriceWeb from './generalInfo/Price.web';
import { AccordionIcon } from './assets';

class GeneralInformation extends GeneralInformationController {

  accordianIcon = <img src={AccordionIcon} />

  renderGeneralAccordian = () => {
    return (
      <StyledAccordion
        id="main-accordian-1"
        expanded={this.state.isOpen.general}
        onChange={this.setOpenAccordian("general")}
        data-testid="general-accordion"
      >
        <AccordionSummary
          expandIcon={this.accordianIcon}
          aria-controls="accordian-1-content"
          id="accordian-1">
          {configJSON.GENERAL_INFO_SECTIONS.GENERAL}
        </AccordionSummary>
        <AccordionDetails id="accordian-1-content">
          <General navigation={null} />
        </AccordionDetails>
      </StyledAccordion>
    )
  }
  renderCommoditiesAccordian = () => {
    return (
      <StyledAccordion
        id="main-accordian-2"
        expanded={this.state.isOpen.commodities}
        onChange={this.setOpenAccordian("commodities")}
        data-testid="commodities-accordion"
      >
        <AccordionSummary
          expandIcon={this.accordianIcon}
          aria-controls="accordian-2-content"
          id="accordian-2">
          {configJSON.GENERAL_INFO_SECTIONS.COMMODITIES}
        </AccordionSummary>
        <AccordionDetails id="accordian-2-content">
          {this.state.isOpen.commodities && <CommoditiesWeb navigation={undefined} id={''} selectedService={this.props.selectedService} getServicesList={this.props.getServicesList} />}
        </AccordionDetails>
      </StyledAccordion>
    )
  }
  renderSpacesAccordian = () => {
    return (
      <StyledAccordion
        id="main-accordian-3"
        expanded={this.state.isOpen.spaces}
        onChange={this.setOpenAccordian("spaces")}
        data-testid="spaces-accordion"
      >
        <AccordionSummary
          expandIcon={this.accordianIcon}
          aria-controls="accordian-3-content"
          id="accordian-3">
          {configJSON.GENERAL_INFO_SECTIONS.SPACES}
        </AccordionSummary>
        <AccordionDetails id="accordian-3-content">
        {this.state.isOpen.spaces &&<SpacesWeb navigation={undefined} id={''} selectedService={this.props.selectedService} getServicesList={this.props.getServicesList}/>}
        </AccordionDetails>
      </StyledAccordion>
    )
  }
  renderRoomsAccordian = () => {
    return (
      <>
        <StyledAccordion
          id="main-accordian-4"
          expanded={this.state.isOpen.rooms}
          onChange={this.setOpenAccordian("rooms")}
          data-testid="rooms-accordion"
        >
          <AccordionSummary
            expandIcon={this.accordianIcon}
            aria-controls="accordian-4-content"
            id="accordian-4">
            {configJSON.GENERAL_INFO_SECTIONS.ROOMS}
          </AccordionSummary>
          <AccordionDetails id="accordian-4-content">
            {this.state.isOpen.rooms && <Rooms navigation={null} selectedService={this.props.selectedService} getServicesList={this.props.getServicesList} />}
          </AccordionDetails>
        </StyledAccordion>
      </>
    )
  }
  renderPriceHorizontal = () => {
    return (
      <StyledAccordion
        id="main-accordian-7"
        expanded={this.state.isOpen.priceH}
        onChange={this.setOpenAccordian("priceH")}
        data-testid="priceH-accordion"
      >
        <AccordionSummary
          expandIcon={this.accordianIcon}
          aria-controls="accordian-7-content"
          id="accordian-7">
          {configJSON.GENERAL_INFO_SECTIONS.PRICES}
        </AccordionSummary>
        <AccordionDetails id="accordian-7-content">
          <PriceWeb navigation={undefined} id={''} />
        </AccordionDetails>
      </StyledAccordion>
    )
  }
  renderServicesAccordian = () => {
    return (
      <>
        <StyledAccordion
          id="main-accordian-5"
          expanded={this.state.isOpen.services}
          onChange={this.setOpenAccordian("services")}
          data-testid="services-accordion"
        >
          <AccordionSummary
            expandIcon={this.accordianIcon}
            aria-controls="accordian-5-content"
            id="accordian-5">
            {configJSON.GENERAL_INFO_SECTIONS.SERVICES}
          </AccordionSummary>
          <AccordionDetails id="accordian-5-content">
            {this.state.isOpen.services && <Services navigation={null} selectedService={this.props.selectedService} getServicesList={this.props.getServicesList} />}
          </AccordionDetails>
        </StyledAccordion>
      </>
    )
  }
  renderPriceVertical = () => {
    return (
      <>
        <StyledAccordion
          id="main-accordian-6"
          expanded={this.state.isOpen.priceV}
          onChange={this.setOpenAccordian("priceV")}
          data-testid="priceV-accordion"
        >
          <AccordionSummary
            expandIcon={this.accordianIcon}
            aria-controls="accordian-6-content"
            id="accordian-6">
            {configJSON.GENERAL_INFO_SECTIONS.PRICES}
          </AccordionSummary>
          <AccordionDetails id="accordian-6-content">
            <PricesVertical navigation={null} />
          </AccordionDetails>
        </StyledAccordion>
      </>
    )
  }
  renderAccordians = () => {
    switch (this.state.selectedService.name) {
      case configJSON.SERVICES_ENUM.DAY_CARE:
        return (
          <>
            {this.renderGeneralAccordian()}
            {this.renderCommoditiesAccordian()}
            {this.renderSpacesAccordian()}
            {this.renderPriceVertical()}
          </>
        )

      case configJSON.SERVICES_ENUM.DOG_WALKING:
        return (
          <>
            {this.renderGeneralAccordian()}
            {this.renderServicesAccordian()}
            {this.renderPriceVertical()}
          </>
        )

      case configJSON.SERVICES_ENUM.HOTELS:
        return (
          <>
            {this.renderGeneralAccordian()}
            {this.renderCommoditiesAccordian()}
            {this.renderRoomsAccordian()}
            {this.renderPriceHorizontal()}
          </>
        )

      case configJSON.SERVICES_ENUM.GROOMING:
      case configJSON.SERVICES_ENUM.PET_SITTING:
      case configJSON.SERVICES_ENUM.TRAINING:
        return (
          <>
            {this.renderGeneralAccordian()}
            {this.renderServicesAccordian()}
            {this.renderPriceHorizontal()}
          </>
        )
    }
  }


  render() {

    return (
      <MainWrapper>
        {this.renderAccordians()}
      </MainWrapper>
    );
  }
}

const MainWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: 32
})

const StyledAccordion = styled(Accordion)({
  boxShadow: "0px 2px 8px 0px #0000000F",
  borderRadius: "16px !important",
  "&::before": {
    backgroundColor: "transparent"
  },
  "& .MuiAccordionSummary-content": {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "20px",
    color: "#6F05EA",
  },
  "&.Mui-expanded .MuiAccordionSummary-root ": {
    borderBottomLeftRadius: "0 !important",
    borderBottomRightRadius: "0 !important",
    height: "62px"
  },
  "& .MuiAccordionSummary-root": {
    backgroundColor: "#F0E5FF",
    borderRadius: "16px !important",
    paddingLeft: "42px",
    paddingRight: "24px",
    height: "62px"
  },
  "& .MuiAccordionDetails-root": {
    padding: "32px 24px"
  }
})
 // Customizable Area End
export default GeneralInformation;