import { IAnimal, IServiceList } from "./interfaces.web";

interface ITimeRange {
    name: string;
    value: string;
}
export const generateTimeRange = (start: string, end: string, interval: number): ITimeRange[] => {
    const times: ITimeRange[] = [];
    const startHour = parseInt(start.split(":")[0], 10);
    const startMinute = parseInt(start.split(":")[1], 10);
    const endHour = parseInt(end.split(":")[0], 10);
    const endMinute = parseInt(end.split(":")[1], 10);

    let hour = startHour;
    let minute = startMinute;

    while (hour < endHour || (hour === endHour && minute <= endMinute)) {
        const timeString = `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
        times.push({ name: timeString, value: timeString });

        minute += interval;
        if (minute >= 60) {
            minute = minute % 60;
            hour += 1;
        }
    }

    return times;
};

export const checkCondition = (condition: boolean, truePart: unknown, falsePart: unknown) => {
    return condition ? truePart : falsePart
}

export const getImageIndices = (length:number, cols=3) => {
    const indices = [];
    for (let i = 0; i < length; i++) {
      const row = Math.floor(i / cols);
      const col = i % cols;
  
      if (col === 0 && row % 2 === 0) { 
        indices.push(i);
      } else if (col === 1 && (row % 2 === 1)) { 
        indices.push(i+1);
      }
    }
    
    return indices;
  };
  export const getImageColumn = (length:number, cols:number, index:number) => {
    if(length === 1){
      return 2;
    }else{
      const indices = [];
      for (let i = 0; i < length; i++) {
        const row = Math.floor(i / cols);
        const col = i % cols;
        
        if (col === 0 && row % 2 === 0) { 
          indices.push(i);
        } else if (col === 1 && (row % 2 === 1)) { 
          indices.push(i+1);
        }
      }
      if(indices.includes(index)){
        return 2
      }
      else{ 
        return 1
      }
    }
    };
export const returnTruthyString = (value: string | number | null | undefined) => {
  return value ? String(value) : ""
}

export const getAnimalList = (serviceName: string) => {
  const allAnimals: IAnimal[] = [
    {
      key: "dog",
      label: "Dogs",
      value: false
    },
    {
      key: "cats",
      label: "Cats",
      value: false
    },
    {
      key: "fish",
      label: "Fish",
      value: false
    },
    {
      key: "bird",
      label: "Bird",
      value: false
    },
    {
      key: "rabbit",
      label: "Rabbit",
      value: false
    },
    {
      key: "reptile",
      label: "Reptile",
      value: false
    },
    {
      key: "rodents",
      label: "Rodents",
      value: false,
    }
  ]
  if (serviceName == "Training") {
    const trainingAnimalList = [
      {
        key: "dog",
        label: "Dogs",
        value: false
      },
      {
        key: "cats",
        label: "Cats",
        value: false
      }]
    return trainingAnimalList
  }else if (serviceName == "Dog Walking") {
    const dogWalkingAnimalList = [
      {
        key: "dog",
        label: "Dogs",
        value: false
      }]
    return dogWalkingAnimalList
  }
  return allAnimals
}

export const capitalizeFirstLetter = (value:string) => {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export const getServiceList = (serviceName: string) => {
  if (serviceName == "Training") {
    const trainingServiceList = [
      {
        key: "individual training",
        label: "Individual Training",
        value: false
      },
      {
        key: "aggressive behaviour change",
        label: "Aggressive behaviour change",
        value: false
      },
      {
        key: "individual puppy training (2-6months)",
        label: "Individual Puppy Training (2-6months)",
        value: false
      },
      {
        key: "individual junior training (7-18 months)",
        label: "Individual junior training (7-18 months)",
        value: false
      },
      {
        key: "individual kitten training (2-6 months)",
        label: "Individual kitten training (2-6 months)",
        value: false
      },
      {
        key: "special train",
        label: "Special train",
        value: false
      },
      {
        key: "general group training",
        label: "General group training",
        value: false
      },
      {
        key: "individual home sessions adult",
        label: "Individual home sessions adult",
        value: false
      },
      {
        key: "individual home sessions puppy (2-6 months)",
        label: "Individual home sessions puppy (2-6 months)",
        value: false
      },
      {
        key: "individual home sessions junior (7-18 months)",
        label: "Individual home sessions junior (7-18 months)",
        value: false
      },
    ]
    return trainingServiceList
  }else if (serviceName == "Dog Walking") {
    const dogWalkingServiceList = [
      {
        key: "individual",
        label: "Individual",
        value: false
      },
      {
        key: "group",
        label: "Group",
        value: false
      },
    ]
    return dogWalkingServiceList
  }
  return []
}

export const getAllowPetOptionList = (serviceType: string) => {
  switch (serviceType) {
    case "individual training":
    case "individual home sessions adult":
    case "individual home sessions puppy (2-6 months)":
    case "individual home sessions junior (7-18 months)":
      return [
        {
          key: "dog",
          label: "Dogs",
          value: false,
        },
        {
          key: "cats",
          label: "Cats",
          value: false,
        },
      ];
    case "aggressive behaviour change":
    case "individual puppy training (2-6months)":
    case "individual junior training (7-18 months)":
    case "special train":
    case "general group training":
      return [
        {
          key: "dog",
          label: "Dogs",
          value: false,
        },
      ];
    case "individual kitten training (2-6 months)":
      return [
        {
          key: "cats",
          label: "Cats",
          value: false,
        },
      ];
    case "group":
    case "individual":
      return [
        {
          key: "dog",
          label: "Dogs",
          value: false,
        },
      ];
    default:
      return getServiceList("Pet Sitting");
  }
}