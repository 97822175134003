import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Button,
  styled,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
// Customizable Area End

import PriceController, { Props } from "./PriceController.web";
import { infoIcon, offSwitchIcon, onSwitchIcon } from "../assets";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const PriceData = [
  {
    id: 1,
    title: "Individual room",
    price: "10€/day",
  },
  {
    id: 2,
    title: "Collective room",
    price: "158€/day",
  },
];

const OtherServiceData = [
  { title: "Transportation", id: 1 },
  { title: "Medication", id: 2 },
];

const PriceContainer = styled(Box)({
  "& .otherServiceSection": {
    marginTop: "100px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  "& .otherServiceContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "200px",
  },
  "& .otherServiceBox": {
    display: "flex",
    gap: 32,
    height: "32px",
    alignItems: "center",
  },
  "& .otherServiceName": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#6F05EA",
    marginLeft: "8px",
  },
  "& .buttonContainer": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    alignItems: "center",
    "@media (max-width: 960px)": {
      flexDirection: "column",
      gap: "12px",
    },
  },
  "& .perDayPriceContainer": {
    display: "flex",
    gap: 8,
    marginLeft: "8px",
    alignItems: "center",
  },
  "& .perDayPriceTypo": {
    color: "#F87171",
    fontSize: "12px",
    fontWeight: 400,
  },
  "& .infoIcon": {
    height: "20px",
    width: "20x",
  },
  "& .cancelButton": {
    borderColor: "#78716C",
    color: "#78716C",
    fontSize: 18,
    fontWeight: "700",
    padding: "0px 40px",
  },
  "& .actionButton": {
    height: "40px",
    border: "1px solid",
    textTransform: "none",
    borderRadius: "100px",
  },
  "& .saveButton": {
    borderColor: "#6F05EA",
    color: "#6F05EA",
    fontSize: 18,
    fontWeight: "700",
    padding: "0px 50px",
  },
  "& .editButtonContainer": {
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width: 960px)": {
      justifyContent: "center",
      width: "70%",
    },
  },
  "& .editButton": {
    "@media (max-width: 960px)": {
      width: "100%",
    },
  },
  "& .saveCancelButtonContainer": {
    display: "flex",
    gap: 12,
    "@media (max-width: 600px)": {
      justifyContent: "center",
      flexDirection: "column",
      width: "70%",
    },
  },
  "& .otherServiceInput": {
    width: "180px",
  },
});

const PriceCardContainer = styled(Box)({
  "& .cardTitleContainer": {
    display: "flex",
    gap: 32,
    alignItems: "center",
  },
  "& .serviceNameTitleContainer": {
    width: "200px",
    paddingLeft: "8px",
  },
  "& .priceTitleContainer": {
    display: "flex",
    flexDirection: "column",
    width: "180px",
  },
  "& .priceTitleTypo": {
    fontSize: "18px",
    color: "#6F05EA",
    fontWeight: 600,
  },
  "& .priceTitleTypo2": {
    fontSize: "12px",
    fontWeight: 400,
    color: "#6F05EA",
    marginLeft: 5,
  },
  "& .MuiInputBase-input": {
    marginLeft: "8px",
    marginTop: "4px",
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: "12px",
    fontFamily: "Montserrat",
  },
  "& .MuiInput-underline::before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInput-underline::after": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .priceInput": {
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .serviceNameTypo": {
    fontSize: "14px",
    fontWeight: 400,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  "& .serviceNameContainer": {
    display: "flex",
    backgroundColor: "#f5f5f5",
    alignItems: "center",
    padding: "14px 12px",
    width: "200px",
    borderRadius: "8px",
  },
  "& .cardTitleBox": {
    marginBottom: "21px",
  },
  "& .servicePriceContainer": {
    width: "180px",
  },
  "& .cardInfoContainer": {
    display: "flex",
    alignItems: "center",
    gap: 32,
    marginBottom: "16px",
  },
});
// Customizable Area GridEnd

export default class PriceWeb extends PriceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderPriceCard = () => {
    return (
      <PriceCardContainer>
        <Box className="cardTitleContainer cardTitleBox">
          <Box className="serviceNameTitleContainer">
            <Typography className="priceTitleTypo">Room Name</Typography>
          </Box>
          <Box className="priceTitleContainer">
            <Typography className="priceTitleTypo">Price</Typography>
            <Typography className="priceTitleTypo2">Price</Typography>
          </Box>
        </Box>
        {PriceData.map((item) => (
          <Box className="cardInfoContainer" key={item.id}>
            <Box className="serviceNameContainer">
              <Typography className="serviceNameTypo">{item.title}</Typography>
            </Box>
            <Box className="servicePriceContainer">
              <TextField
                id="spaceTitle"
                fullWidth
                value={item.price}
                disabled={!this.state.isEdit}
                margin="normal"
                className="priceInput"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Box>
        ))}
      </PriceCardContainer>
    );
  };

  renderOtherService = () => {
    return (
      <Box className="otherServiceSection">
        {OtherServiceData.map((item) => (
          <Box className="otherServiceBox" key={item.id}>
            <Box className="otherServiceContainer">
              <Typography className="otherServiceName">{item.title}</Typography>
              <Box style={{width:"48px",height:"28px"}}><img src={this.state.enabledSwitches[item.id] ? onSwitchIcon : offSwitchIcon}  onClick={!this.state.isEdit ? undefined : () => this.handleSwitchChange(!this.state.enabledSwitches[item.id], item.id)} />
              </Box>
            </Box>
            <Box className="otherServiceInput">
              {this.state.isEdit && this.state.enabledSwitches[item.id] && (
                <TextField
                  id="spaceTitle"
                  fullWidth
                  className="priceInput"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  renderButtonContainer = () => {
    return (
      <Box className="buttonContainer">
        <Box className="perDayPriceContainer">
          <img src={infoIcon} className="infoIcon" />
          <Box className="perDayPriceTypo">All prices are per day.</Box>
        </Box>
        {!this.state.isEdit ? (
          <Box className="editButtonContainer">
            <Button
              className="saveButton actionButton editButton"
              onClick={this.handleEditClick}
              data-test-id="edit-btn"
            >
              Edit
            </Button>
          </Box>
        ) : (
          <Box className="saveCancelButtonContainer">
            <Button
              className="cancelButton actionButton"
              onClick={this.handleCancelClick}
              data-test-id="cancel-btn"
            >
              Cancel
            </Button>
            <Button className="saveButton actionButton" data-test-id="save-btn">
              Save
            </Button>
          </Box>
        )}
      </Box>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <PriceContainer>
          {this.renderPriceCard()}
          {this.renderOtherService()}
          {this.renderButtonContainer()}
        </PriceContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
