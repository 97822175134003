import React from "react";

// Customizable Area Start
import CustomisableUserProfileVenderController, {
  Props,
} from "./CustomisableUserProfilesVenderController.web";
import SidebarServices from "./SidebarServices.web"
import  NavigationMenu  from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import {
  Box, styled, createTheme, Tabs, Tab, IconButton, Drawer
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import GeneralInformation from "./GeneralInformation.web";
import RequestDetails from "./CustomisableUserGallary.web";
import CustomisableUserCalendarVendorWeb from "./CustomisableUserCalendarVendor.web";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import { Tab1, Tab2, Tab3, Tab4 } from "./assets";
import Settings2 from '../../settings2/src/Settings2.web';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
const PageWrapper = styled(Box)({
  "& *": {
    boxSizing: 'border-box',
  },

  "& .MuiTypography-root.MuiTypography-body1": {
    fontFamily: 'Montserrat',
  },

  '& .MuiInput-underline.Mui-disabled': {
    '&::before': {
      borderBottomStyle: 'solid'
    }
  },

  '& .MuiInputBase-root.Mui-disabled': {
    color: 'inherit'
  },

  '& .MuiInputBase-input.MuiInput-input': {
    "&::placeholder": {
      color: '#A6A6A6',
      fontSize: '13px',
      opacity: 1,
      fontWeight: 400,
      fontFamily: 'Montserrat',
    }
  },

  "& .custom-tab": {
    color: '#B7B7B7',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'none',
    maxWidth:322,padding:12,gap:'6px'
  },
  "& .tab-label":{
    display: 'flex',
    alignItem:'center'
  },
  "& .tab-icon":{
    width: 26, 
    height: 26, 
    marginRight: 12 
  },
  "& .custom-tab.Mui-selected": {
    fontWeight: 700,
    color: '#6F05EA',
    borderBottom:'3px solid #6F05EA'
  },
  "& .vertical-tab.Mui-selected": {
    color: '#6F05EA',
    fontWeight: 600,
  },
  "& .vertical-tab": {
    color: '#808080',
    fontSize: '14px',
    fontWeight: 600,
    flex: 1,
    textTransform: 'none',
    position: 'relative',
    fontFamily: 'Montserrat',

    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        top: 0,
        left: 0,
        width: '1px', 
        backgroundColor: '#000',
    },
  },
 
  "& .MuiSwitch-thumb": {
    boxSizing: 'border-box',
    height: 22,
    width: 22,

  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    transitionDuration: '300ms',
    margin: 2,
    '&.Mui-checked': {
      color: 'rgba(111, 5, 234, 1)',
      transform: 'translateX(27px)',
      '& + .MuiSwitch-track': {
        backgroundColor: 'rgba(111, 5, 234, 0.2)',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
  "& .MuiListItem-button": {
    '&:hover': {
      textTransform: 'none',
      backgroundColor: 'rgba(111, 5, 234, 0.1)', 
    },
    fontFamily: 'Montserrat',
  },

  "& .MuiSwitch-root": {
    height: 26,
    width: 54,
    padding: 0,
  },

  "& .MuiSwitch-track": {
    backgroundColor: '#E9E9EA',
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },

  "& .main-menu-wrapper": {
    [theme.breakpoints.up("sm")]: {
      display: 'flex',
    },
    display: 'none',
  },
  "& .custom-svg.active": {
    "& path": {
      fill: '#fff'
    }
  },
  "& .content-box": {
    flex: 1,
    paddingTop: '96px',
    paddingBottom: '96px',
    paddingLeft: '50px',
    paddingRight: '50px',
    background: 'white',
    textAlign: 'center',

    "& .content": {
      maxWidth: '100%',
      [theme.breakpoints.up("sm")]: {
        maxWidth: '965px',
      },
    }
  },


});
const AboutSection = styled(Box)({
  width:'100%',
  height:'auto',
  borderRadius:10,
  padding:'32px 20px 38px',
  gap:'34px',
  border:'0px 0px 0px 0px',
  background:'#f6f4f9',
  display:'flex',
  flexDirection:'row',
  "& .column-one":{
    display:'flex',
    flexDirection:'column'
   },
   "& .column-two":{
    flexDirection:'column',
    display:'flex',

   }

});
const RequestSectionBox = styled(Box)({
  display: "flex",
  height: "100%",
  flexDirection:'row',
  background:'#F6F4F9',
 boxSizing: 'border-box',
 "& .Cancel-Appointment-box": { 
 },
 "& .profile-date": {fontSize:16,fontWeight:500},
 "& .profile-title": {fontSize:30,fontWeight:600,fontFamily:'Montserrat',color:'#000',lineHeight:'36px'}
  ,"& .mainSection": {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    overflow: "scroll",
    backgroundColor: "#F6F4F9",
    width:'100%'
  },
  "& .grid-main-content": {
    display: 'flex',width:'100%',flexDirection:'column',
    margin:10,padding:40,
    "@media screen and (max-width:1023px)": {
      marginLeft: '-89px',
      padding: 20,

               },
               "@media screen and (max-width:500px)": {
                margin: 38,
                marginLeft:-69
                           },
               "@media screen and (min-width:1025px)": {
                margin: 10,
  padding: 40,
  marginLeft:-133
               },
               "@media screen and (min-width:1440px)": {
                margin: 10,
  padding: 40,
  marginLeft:-170
               },
               "@media screen and (min-width:1650px)": {
                margin: 10,
  padding: 40,
  marginLeft:-241
               }
    
              },
  "& .tabLogo": {
    display: "none",
    width: "100%",
    paddingTop: "20px"
  },
  "& .tabLogoBox": {
    width: "160px",
    height: "50px"
  },
  "@media screen and (max-width:1024px)": {
    "& .sideBar": {
      display: "none"
    },
    "& .sideBarOpen": {
      display: "block"
    },
    "& .tabLogo": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  },


});
const DashboardBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "20px",
  justifyContent:"space-between",
  "& .menuIconBtn":{
    display: "none"
  },
  "& .dashboardHeading": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& .MuiTypography-body1": {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      fontFamily: "Inter",
      color: "#011342",
    },
  },
  "& .menuIcon": {
    display: "none",
  },
  "& .profileBox": {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    gap: "15px",
    "& .MuiButton-root": {
      fontSize: "16px",
      fontWeight: 700,
      fontFamily: "Inter",
      textTransform: "none",
      color: "white",
      background: "#012275",
      borderRadius: "4px",
      paddingRight: "15px",
      paddingLeft: "15px",
      height: "49px",
    },
  },
  "& .addIcon": {
    paddingRight: "8px",
  },
  "& .bellIconBox":{
    boxShadow: "0px 0px 3px rgba(0,0,0,0.1)",
  },
  "& .profile": {
    boxShadow: "0px 0px 3px rgba(0,0,0,0.1)",
    padding:"8px 25px 8px 8px",
    "& .MuiTypography-body2": {
      fontSize: "14px",
      fontWeight: 600,
      fontFamily: "Inter",
      color: "#011342",
    },
    display: "flex",
    alignItems: "center",
    gap: "5px",
    order: 3,
  },
  "& .userImage": {
    width: "32px",
    height: "32px",
    "& img": {
      borderRadius: "50%",
    },
  },
  "@media screen and (max-width:1024px)": {
    "& .menuIconBtn":{
      display: "block"
    },
    "& .tabHeading":{
      display: "none"
    },
    "& .MuiButton-root": {
      fontSize: "14px",
      order: 2,
    },
    "& .profileBox": {
      "& img": {
        order: 1,
      },
    },
    "& .menuIcon": {
      display: "block",
    },
  },
  "@media screen and (max-width:599px)": {
    "& .dashboardHeading": {
      gap: "5px",
      "& .MuiTypography-body1": {
        fontSize: "20px !important",
        lineHeight: "20px !important",
      },
    },
    "& .profileBox": {
      padding: "5px",
      gap: "10px",
      "& .MuiButton-root": {
        fontSize: "10px !important",
        paddingRight: "5px !important",
        paddingLeft: "5px !important",
        height: "30px !important",
      },
    },
    "& .addIcon": { display: "none" },
    padding: "10px",
    "& .profile": {
      gap: "2px",
      "& .MuiTypography-body2": { display: "none" },
    },
  },
});
// Customizable Area End

export default class CustomisableUserProfilesVender extends CustomisableUserProfileVenderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSettingsBlock = () => {
    return (
      <Box style={{ width: '100%'}}>
        <TabWrapper
          variant="scrollable"
          value={this.state.currentSettingsTab}
          data-test-id="settings-tabs"
          onChange={(_, idx) => this.setTabId(idx)}
          TabIndicatorProps={{
          style: {
              backgroundColor: '#6F05EA',
          },
          }}
        >
            <CustomTab label={<TabBox><img src={Tab1} />Calendar</TabBox>} data-testid="Calendar-tab"/>
          <CustomTab label={<TabBox><img src={Tab2} />Gallery</TabBox>} data-testid="Gallery-tab"/>
          <CustomTab label={<TabBox><img src={Tab3} />Dashboard</TabBox>} data-testid="Dashboard-tab"/>
          <CustomTab label={<TabBox><img src={Tab4} />General Information</TabBox>} data-testid="General-Information-tab"/>  
        </TabWrapper>
        {this.renderSettingsTabContent()}
     
      </Box>
    )
  }
  renderSettingsTabContent = () => {
    switch (this.state.currentSettingsTab) {
      case 0:
        return this.renderCalenderTab();
      case 1:
        return this.renderGallaryTabTab();
      case 2:
        return this.renderDashboardTab();
        case 3:
          return this.renderGeneralTab();
      default:
        break;
    }
  }
  renderCalenderTab = () => {
    return (
      <>
        <CustomisableUserCalendarVendorWeb navigation={''} id="" />
      </>
    )
  }
  renderGallaryTabTab = () => {
    return (
      <>
        <RequestDetails navigation={''} id="" selectedService={this.state.selectedService} />
      </>
    )
  }
  renderDashboardTab = () => {
    return (
      <>
        <UpcomingBox>Coming Soon!</UpcomingBox>
      </>
    )
  }
  renderGeneralTab=()=>{
    return(
      <>
        <GeneralInformation
          selectedService={this.state.selectedService}
          getServicesList={this.getServicesList}
        />
      </>
    )
   
  }
  renderFooter = () => {
    return <AppFooter navigation={this.props.navigation} />
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <NavigationMenu id={''} navigation={this.props.navigation} handleCustomerHeaderSelect={this.handleCustomerHeaderSelect} isProfile={false} />
        <MobileMenuWrapper>
          <IconButton onClick={this.toggleDrawer(true)}><MenuIcon /></IconButton>
          <Drawer anchor="left" open={this.state.isSideBarOpen} onClose={this.toggleDrawer(false)}>
            <SidebarServices
              filtredServicesList={this.state.filtredServicesList}
              selectedService={this.state.selectedService}
              handleSetService={this.handleSetService}
              addServiceModalOpen={this.state.addServiceModalOpen}
              openServiceModalFn={this.openServiceModal}
              closeServiceModalFn={this.closeServiceModal}
              showsetting = {this.state.openSetting}
              showsettingFn ={this.handleSetting}
              nextButtonModalFn={this.nextButtonModal} navigation={undefined} id={""}            />
          </Drawer>
        </MobileMenuWrapper>
        <MainWrapper>
          <LeftWrapper data-testid="sidebar-wrapper">
            <SidebarServices
              filtredServicesList={this.state.filtredServicesList}
              selectedService={this.state.selectedService}
              handleSetService={this.handleSetService}
              addServiceModalOpen={this.state.addServiceModalOpen}
              openServiceModalFn={this.openServiceModal}
              closeServiceModalFn={this.closeServiceModal}
              nextButtonModalFn={this.nextButtonModal}
              showsetting = {this.state.openSetting}
              showsettingFn ={this.handleSetting}
              navigation={this.props.navigation} id={""}            />
          </LeftWrapper>
          <RightWrapper>
          {!this.state.openSetting ? this.renderSettingsBlock() 
          : <Settings2 navigation={undefined} id={""} />}      
          </RightWrapper>
        </MainWrapper>
        {this.renderFooter()}
      </>
    );
  
  }
}

const MainWrapper = styled(Box)({
  display:"flex",
  flexDirection:"row"
})
const LeftWrapper = styled(Box)({
  display: "block",
  maxWidth: 313,
  width: "100%",
  "@media (max-width: 800px)": {
      display: "none"
  },
})
const MobileMenuWrapper = styled(Box)({
  display: "none",
  backgroundColor: "#F6F4F9",
  "@media (max-width: 800px)": {
    display: "block"
  },
})
const RightWrapper = styled(Box)({
  width:"100%",
  display: "flex",
  backgroundColor:"#F6F4F9",
  padding:"75px 100px"
})

const TabWrapper = styled(Tabs)({
  marginBottom:50,
  "& .MuiTabs-root":{
    overflow:"auto"
  },
  "& .MuiTabs-flexContainer": {
    columnGap: 8
  },
  "& .MuiTabs-indicator": {
    height: 3
  }
})
const CustomTab = styled(Tab)({
  minWidth: 0,
  padding: "0 12px",
  opacity: 1,
  textTransform: "none",
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#000000",
})
const TabBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: 12,
  alignItems: "center",
})

const UpcomingBox = styled(Box)({
  padding: "48px",
  maxHeight: 400,
  backgroundColor: "#fff",
  boxShadow: "0px 2px 8px 0px #0000000F",
  borderRadius: "16px",
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "24px",
  textAlign: "center",
  color: "#6F05EA"
})
// Customizable Area End
