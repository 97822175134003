import moment from "moment";
import { EventItem } from "../../../blocks/customisableuserprofiles/src/types";
import { getWorkingWeekDates } from "../helpers/getCurrentWeek";

export const apointmentsMock: EventItem[] = [{
    id: 1,
    start: moment("Mon Aug 13 2024 12:30:08 GMT+0300").toDate(),
    end: moment("Mon Aug 13 2024 13:30:08 GMT+0300").toDate(),
    data: { appointment: {
      id: 1,
      price: 95,
      requests: [
        {
          id: 1,
          status: 'rejected',
          type: 'transportation'
        }
      ],
      animal: {
        id: 1,
        type: "Cat",
        gender: "male",
        breed: "Orange Tabby Cat",
        birthdate: '09/04/2020',
        weight: '25kg',
        size: 'Medium (12-28 kg)',
        fur: 'Medium coat',
        name: 'Farrusco'
      },
      serviceType: 'training',
      service: 'Training' 
    }}
  }, {
    id: 2,
    start: moment("Mon Aug 13 2024 16:30:08 GMT+0300").toDate(),
    end: moment("Mon Aug 15 2024 17:30:08 GMT+0300").toDate(),
    data: { appointment: {
              id: 2,
              price: 25,
              requests: [
                {
                  id: 2,
                  status: 'pending',
                  type: 'transportation'
                }
              ],
              animal: {
                id: 2,
                type: "Cat",
                gender: "male",
                breed: "Orange Tabby Cat",
                birthdate: '09/04/2020',
                weight: '25kg',
                size: 'Medium (12-28 kg)',
                fur: 'Medium coat',
                name: 'Annie'
              },
              service: 'Grooming',
              serviceType: 'groomer', 
    }, hotel: true}
  }, {
    id: 3,
    start: moment("Mon Aug 17 2024 03:00:00 GMT+0300").toDate(),
    end: moment("Mon Aug 18 2024 03:00:00 GMT+0300").toDate(),
    data: { hotel: true, appointment: {
      id: 3,
      price: 122,
      requests: [
        {
          id: 2,
          status: 'rejected',
          type: 'transportation'
        }
      ],
      animal: {
        id: 2,
        type: "Cat",
        gender: "male",
        breed: "Orange Tabby Cat",
        birthdate: '09/04/2020',
        weight: '25kg',
        size: 'Medium (12-28 kg)',
        fur: 'Medium coat',
        name: 'Annie'
      },
      service: 'Hotel',
      serviceType: 'hotel' 
    }}
  }, {
    id: 4,
    start: moment("Mon Aug 12 2024 08:00:08 GMT+0300").toDate(),
    end: moment("Mon Aug 12 2024 09:00:08 GMT+0300").toDate(),
    data: { appointment: {
      id: 4,
      price: 45,
      requests: [
        {
          id: 4,
          status: 'approved',
          type: 'deletion'
        }
      ],
      animal: {
        id: 3,
        type: "Cat",
        gender: "male",
        breed: "Orange Tabby Cat",
        birthdate: '09/04/2020',
        weight: '25kg',
        size: 'Medium (12-28 kg)',
        fur: 'Medium coat',
        name: 'Zane'
      },
      service: 'Hotel',
      serviceType: 'hotel' 
    }}
  }, {
    id: 5,
    start: moment("Mon Aug 07 2024 10:00:08 GMT+0300").toDate(),
    end: moment("Mon Aug 07 2024 12:00:08 GMT+0300").toDate(),
    data: { appointment: {
      id: 5,
      requests: [],
      price: 40,
      animal: {
        id: 3,
        type: "Cat",
        gender: "male",
        breed: "Orange Tabby Cat",
        birthdate: '09/04/2020',
        weight: '25kg',
        size: 'Medium (12-28 kg)',
        fur: 'Medium coat',
        name: 'Zane'
      },
      service: 'Hotel',
      serviceType: 'hotel' 
    }}
  }, {
    id: 6,
    start: moment("Mon Aug 07 2024 10:00:08 GMT+0300").toDate(),
    end: moment("Mon Aug 10 2024 12:00:08 GMT+0300").toDate(),
    data: { appointment: {
              id: 6,
              requests: [],
              price: 40,
              animal: {
                id: 3,
                type: "Cat",
                gender: "male",
                breed: "Orange Tabby Cat",
                birthdate: '09/04/2020',
                weight: '25kg',
                size: 'Medium (12-28 kg)',
                fur: 'Medium coat',
                name: 'Zane'
              },
              service: 'Hotel',
              serviceType: 'hotel' 
            },
            hotel: true
    }
  }
] 
