import React, { useState } from 'react';
import { EventItem } from '../types';
import CustomerCalendar from './CustomerCalendar';
import { Box, Typography, styled } from '@material-ui/core';
import ApointmentDetails from './ApointmentDetails';
import ApointmentsList from './ApointmentsList';

type Props = {
    events: EventItem[];
    appointments: EventItem[];
}

const CalendarTabWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '35px',

    '& .calendar-section': {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px'
    },

    "& .calendar-header": {
        fontSize: '40px',
        color: '#000',
        fontWeight: 600,
        textAlign: 'left'
    }
})

const CalendarTab = ({ events, appointments }: Props) => {
    const [selectedEvent, setSelectedEvent] = useState<EventItem | null>(null);

    const handleSelectEvent = (event: EventItem | null) => {
        setSelectedEvent(event);
    }


    return (
        <CalendarTabWrapper>
            {selectedEvent ? <ApointmentDetails selectedEvent={selectedEvent} onBackClick={handleSelectEvent}/> : 
            <>
                <Box className='calendar-section'>
                    <Typography className='calendar-header'>Calendar</Typography>
                    <CustomerCalendar events={events} onEventSelect={handleSelectEvent}/>
                </Box>
                <ApointmentsList appointments={appointments} onEventSelect={handleSelectEvent} />
            </>
            }
            
        </CalendarTabWrapper>
    )
}

export default CalendarTab;