import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
interface IApiModels {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string | null;
  isJsonStringify?: boolean;
}
interface IAddBookingModel{
    email:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabValue:number,
  isAddBookingOpen:boolean,
  isShowSuccessfullyBookingEmail:boolean,
  AppointmentArrayList:any[];
  RequestArrayList:any[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableUserCalendarVendorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Starts
  getAppointmentListApi: string = "";
  getRequestListApi: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        tabValue:0,
        isAddBookingOpen:false,
        isShowSuccessfullyBookingEmail:false,
        AppointmentArrayList:[],
        RequestArrayList:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    if (apiRequestCallId === this.getAppointmentListApi) {
      this.handleAppointmentList(responseJson)
    }if (apiRequestCallId === this.getRequestListApi) {
      this.handleRequestList(responseJson)
    }
  }
    // Customizable Area End
  }

  // Customizable Area Start
  handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({ tabValue: Number(newValue) });
  };
  handleAppointmentList(responseJson:any){
    if(responseJson.data){
      this.setState({AppointmentArrayList:responseJson.data})
    }
  }
  handleRequestList(responseJson:any){
    if(responseJson.data){
      this.setState({RequestArrayList:responseJson.data})
    }
  }
  handleAddBookingClick = () => {
    this.setState({isAddBookingOpen:true})
  }
  async componentDidMount() {
    this.getAppointmentListArray();
    this.getRequestListArray();
  }
  getAppointmentListArray = async () => {

    const TokenForAddress = sessionStorage.getItem("login_token");
    this.getAppointmentListApi = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.GetapponitmentList}=month&start_date=25-07-2024&end_date=25-07-2024`,
      token: TokenForAddress,
    });
  };
  getRequestListArray = async () => {

    const TokenForAddress = sessionStorage.getItem("login_token");
    this.getRequestListApi = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getRequestListEndpoint}`,
      token: TokenForAddress,
    });
  };
  apiCall = async (data: IApiModels) => {
    const {endPoint, contentType, method, body, token, isJsonStringify } =
      data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }
    const requestMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    body &&
      requestMessaged.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );

    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessaged.id, requestMessaged);
    return requestMessaged.messageId;
  };
  handleAddBookingClose = () => {
    this.setState({isAddBookingOpen:false, isShowSuccessfullyBookingEmail:false})
  }

  handleAddBookingDialogSave = (value:IAddBookingModel) =>{
    this.setState({isShowSuccessfullyBookingEmail:true})
  }
  // Customizable Area End
}
