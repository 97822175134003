// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IAnimal, IFilteredService } from "../../../../components/src/interfaces.web";
import { getStorageData } from "../../../../framework/src/Utilities";
import { getAnimalList } from "../../../../components/src/HelperUtils";

export const configJSON = require("../config.js");

interface IApiModel {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string | null;
    isJsonStringify?: boolean;
}

interface IRoomAttribute {
    id: number,
    title: string,
    capacity: number,
    allows_pet: string,
    description: string,
    duration: null,
    shift: null,
    service_id: number,
    created_at: string,
    updated_at: string,
    images_urls: IImageResponse[],
    
  }
  interface IRoom {
    id:number;
    type: string;
    attributes: IRoomAttribute;
  }

  interface IImageResponse {
    id: number,
    file_name: string,
    file_url: string,
}
interface IGetRoomResponse {
    data:IRoom[]
  }

  interface IFormValue {
    title: string;
    capacity: string;
    allowedPet: string;
    description: string;
    images: File[]
  }

  interface IAddUpdateRoomResponse {
    data:{
      id:number,
      type:string,
      attributes:IRoomAttribute
    }
  }
// Customizable Area End

export const webConfigJSON = require("../config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    selectedService:IFilteredService;
    getServicesList:(id:string) => void;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    openAddRoom: boolean,
    isViewDialogOpen: boolean;
    isEdit: boolean;
    getRoomList:IRoom[],
    getRoomListLoading:boolean,
    roomFormInitialValue:IFormValue,
    selectedViewData:IRoom,
    allowedPetOptionValue:IAnimal[]
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class RoomsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getRoomApiCallId : string = "";
    addRoomApiCallId : string = "";
    updateRoomApiCallId : string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            openAddRoom: false,
            isViewDialogOpen:false,
            isEdit:false,
            getRoomList:[],
            getRoomListLoading:false,
            roomFormInitialValue:{  title: "",
            capacity: "",
            allowedPet: "",
            description: "",
            images:[]
          },
          selectedViewData:{} as IRoom,
          allowedPetOptionValue:[]
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            
            if (apiRequestCallId && responseJson) {
              switch (apiRequestCallId) {
                case this.getRoomApiCallId:
                  this.handleGetRoomResponse(responseJson);
                  break;
                case this.addRoomApiCallId:
                  this.handleAddRoomResponse(responseJson);
                  break;
                case this.updateRoomApiCallId:
                  this.handleUpdateRoomResponse(responseJson);
                  break;
                default:
                  break;
              }
            }
          }
        // Customizable Area End
    }
    // Customizable Area Start
  openAddRoomModal = () => {
      this.setState({
          openAddRoom: true
      })
  }
  closeAddRoomModal = () => {
      this.setState({
          openAddRoom: false,
          isEdit:false,
          roomFormInitialValue: {
            title: "",
            capacity: "",
            allowedPet: "",
            description: "",
            images: []
          }
      })
  }

  handleViewDialogClose = () => {
      this.setState({
          isViewDialogOpen: false,
          openAddRoom: false,
          isEdit: false,
          selectedViewData:{} as IRoom,
      });
  };

  handleViewEditClick = async () => {
    const data = this.state.selectedViewData;
    const imageList = await this.handleSelectedFile(data.attributes.images_urls);
    this.setState({
      openAddRoom: true,
      isViewDialogOpen: false,
      isEdit: true,
      roomFormInitialValue:{
        title: data.attributes.title ,
        capacity: data.attributes.capacity.toString(),
        allowedPet: data.attributes.allows_pet,
        description: data.attributes.description,
        images:imageList
      }
    });
  };

  handleViewClick = (selectId:number) => {
    const selectedData = this.state.getRoomList.find((item) => item.id === selectId) as IRoom;
      this.setState({ isViewDialogOpen: true, selectedViewData:selectedData });
  };

  apiCall = async (data: IApiModel) => {
      const {
        contentType,
        method,
        endPoint,
        body,
        token,
        isJsonStringify,
      } = data;
      let header;
      if (token) {
        header = {
          "Content-Type": contentType,
          token: token,
        };
      } else {
        header = {
          "Content-Type": contentType,
        };
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      body &&
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          isJsonStringify ? JSON.stringify(body) : body
        );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    };
  
    async componentDidMount() {
      super.componentDidMount();
      this.setState({allowedPetOptionValue:getAnimalList(this.props.selectedService.name)})
      if(this.props.selectedService.service_id){
        this.getRoomApiCall(); 
      }
    };

  getRoomApiCall = async () => {
    const token = await getStorageData("login_token");
    if (token) {
      this.setState({getRoomListLoading:true,getRoomList:[]})
      this.getRoomApiCallId = await this.apiCall({
        method: configJSON.httpGetMethod,
        token: token,
        endPoint: `${configJSON.getRoomEndPoint}${this.props.selectedService.service_id}`,
      });
    }
  };

  handleGetRoomResponse = (responseJson:IGetRoomResponse) => {
    this.setState({getRoomListLoading:false})
    if(responseJson && responseJson.data && responseJson.data.length){
      this.setState({getRoomList:responseJson.data})
    }
    else{
      this.setState({getRoomList:[]})
    }
  };

  addUpdateRoomApiCall = async(values: IFormValue) => {
    let newRoomData = new FormData();
    newRoomData.append("sub_service[title]",values.title);
    newRoomData.append("sub_service[capacity]",values.capacity);
    newRoomData.append("sub_service[allows_pet]",values.allowedPet);
    newRoomData.append("sub_service[description]",values.description);
    newRoomData.append("sub_service[service_id]",this.props.selectedService.service_id);
    if (values.images.length > 0) {
      values.images.forEach((file) => {
      newRoomData.append("sub_service[images][]", file);
      });
      }
    
    const token = await getStorageData("login_token");
    
    if (token) {
      if (!this.state.isEdit) {
        this.addRoomApiCallId = await this.apiCall({
          method: configJSON.httpPostMethod,
          token: token,
          endPoint: configJSON.addRoomEndPoint,
          body: newRoomData,
        });
      }
      else {
      this.updateRoomApiCallId = await this.apiCall({
        method: configJSON.httpPutMethod,
        token: token,
        endPoint: `${configJSON.updateSpaceEndPoint}${this.state.selectedViewData.id}?service_id=${this.props.selectedService.service_id}`,
        body: newRoomData,
      });
    }
    }
  };

  handleAddRoomResponse = (responseJson:IAddUpdateRoomResponse) => {
    if(responseJson && responseJson.data && responseJson.data.attributes){
      this.closeAddRoomModal();
      this.getRoomApiCall();
    }
  };

  handleSelectedFile = async (
    images: IImageResponse[],
  ) => {
    return await Promise.all(
      images.map(async (image) => {
        const blob = await fetch(image.file_url).then((res) => res.blob());
        const urlParts = image.file_name.split(".");
        const fileExtension = urlParts.pop() || "jpg";
        let mimeType = "image/jpg";
        if (fileExtension === "png") {
          mimeType = "image/png";
        }

        const fileName = `backend-image-${image.file_name}`;

        return new File([blob], fileName, {
          type: mimeType,
        });
      })
    );
  };

  handleUpdateRoomResponse = (responseJson: IAddUpdateRoomResponse) => {
    if (responseJson && responseJson.data && responseJson.data.attributes) {
      this.closeAddRoomModal();
      this.getRoomApiCall();
      this.setState({
        roomFormInitialValue: {
          title: "",
          capacity: "",
          allowedPet: "",
          description: "",
          images: []
        }
      })
    }
  };

  handleHiddenClick = () => {
    this.setState({isEdit:true})
  }
    // Customizable Area End
}

