import moment from "moment";
import { EventItem } from "../../../blocks/customisableuserprofiles/src/types";

interface AppointmentsByDate {
    date: string; 
    appointments: EventItem[];
  }

export const groupAppointmentsByDate = (appointments: EventItem[]): AppointmentsByDate[] => {
    const dateMap: Map<string, EventItem[]> = new Map();
  
    appointments.forEach(appointment => {
      const dateKey = moment(appointment.start).format('YYYY-MM-DD');
      if (!dateMap.has(dateKey)) {
        dateMap.set(dateKey, []);
      }
      dateMap.get(dateKey)?.push(appointment);
    });
  
    const groupedAppointments: AppointmentsByDate[] = Array.from(dateMap, ([date, appts]) => ({
      date,
      appointments: appts
    }));
  
    // Sort by date
    groupedAppointments.sort((a, b) => moment(a.date).isBefore(b.date) ? -1 : 1);
  
    return groupedAppointments;
  };

