Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TextDetector";
exports.labelBodyText = "TextDetector Body";

exports.btnExampleTitle = "CLICK ME";

exports.requestApiContentType = "application/json";
exports.getReceivedRequestApiEndpoint = "request_management/requests/received";
exports.getReceivedRequestApiMethod = "GET";

exports.getSendRequestApiEndpoint = "request_management/requests/sent";
exports.getSendRequestApiMethod = "GET";

exports.getAllReceivedRequestApiEndpoint = "request_management/requests";
exports.getAllReceivedRequestApiMethod = "GET";

exports.createSendRequestApiEndpoint = "request_management/requests";
exports.createSendRequestApiMethod = "POST";

exports.emptyGroupIdAlert = "Please select group id";
exports.emptyRejectReasonAlert = "Please enter reason";
exports.acceptedMsgText = "Request has been accepted.";
exports.rejectedMsgText = "Request has been rejected.";
exports.deletedMsgText = "Request has been deleted.";
exports.requestSentSuccessMsg = "Request has been sent."
exports.requestUpdateSuccessMsg = "Request has been updated."

exports.updateRequestApiEndpointStart = "request_management/requests/";
exports.updateRequestApiEndpointEnd = "/review";
exports.updateRequestApiMethod= "PUT";

exports.deleteRequestApiEndpoint = "request_management/requests/";
exports.deleteRequestApiMethod= "DELETE";

exports.getGroupsApiEndpoint = "account_groups/groups";
exports.getGroupsApiMethod= "GET";

exports.getSentRequestApiEndpoint = "request_management/requests/sent";
exports.getSentRequestApiMethod= "GET";

exports.updateRequestTextApiEndpoint = "request_management/requests/";
exports.updateRequestTextApiMethod= "PUT";


exports.titleText = "Request Management";
exports.sendRequestBtnLabel = "Send Request";
exports.receiveRequestText = "Received Requests";
exports.nameLabelText = "Name";
exports.requestLabelText = "Request Text";
exports.rejectReasonLabelText = "Reject Reason";
exports.statusLabelText = "Status";
exports.actionLabelText = "Action";
exports.acceptBtnLabel = "Accept";
exports.rejectBtnLabel = "Reject";
exports.deleteBtnLabel = "Delete";
exports.sendRequestsText = "Sent Requests";
exports.updateBtnLabel = "Update";
exports.viewBtnLabel = "View";
exports.requestIdLabel = "Request ID";
exports.sendRequestDialogTitle = "Send Request";
exports.updateRequestDialogTitle = "Update Request";
exports.rejectRequestDialogTitle = "Request reject";
exports.cancelBtnLabel = "Cancel";
exports.sendBtnLabel = "Send";
exports.rejectReasonInputLabel = "Please enter reason";
exports.requestTextInputLabel = "Please enter request text";
exports.selectGroupPlaceholder = "Please select group";
exports.rejectTextFieldIsRequired = "Please enter reject reason";
exports.loginAlertMessage = "Please login first.";
exports.filterInputLabel = "Enter request id to filter"
exports.editBtn = "Edit";
exports.cancelBtn = "Cancel";
exports.saveBtn = "Save";
exports.deleteBtn = "Delete pet";
exports.continueBtn = "Continue";
exports.general = "General";
exports.medical = "Medical";
exports.traits = "Traits";
exports.microcheap = "Microchip number";
exports.personality = "Personality";
exports.personalityDesc = "How would you describe your pet in unknown environments?";
exports.habits = "Habits & Routines";
exports.calm = "Calm";
exports.energetic = "Energetic";
exports.relaxed2 = "Relaxed";
exports.anxious = "Anxious";
exports.easyGoing2 = "Easy-going";
exports.shy = "Shy";
exports.friendly2 = "Friendly";
exports.aggressive = "Aggressive";
exports.gentle2 = "Gentle";
exports.strong = "Strong";
exports.obedient2 = "Obedient";
exports.rebbelious = "Rebbelious";
exports.cancelMessage="You’re cancelling the appointment, are you sure?";
exports.requestdetailsEndpoint='bx_block_request_management/requests';
exports.patchcall = "PATCH";
// Customizable Area End