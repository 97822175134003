import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { Box,Grid, Button, IconButton, Typography, styled, Tabs, Tab ,Switch} from "@material-ui/core";
import { Backarrow,Catprofile,request,image_paw_24px,image_crop_24px,personpic} from "../../../blocks/customisableuserprofiles/src/assets";
import MenuIcon from '@material-ui/icons/Menu';
import  {AppVendorHeader}  from "../../../components/src/AppHeaderVendor.web";
import Sidebar  from "../../../components/src/NewSideBar/Sidebar.web";
export const configJSON = require("./config");
import FieldsRow from "../../../components/src/FieldsRow.web";
import FieldsRowVendor from "../../../components/src/FieldRowVendor.web";
import GenericModal from "../../../components/src/GenericModal";
import PhoneInput from "react-phone-input-2";
type EditFields = 'isEditMedical' | 'isEditTreats' | 'isEditGeneral' | 'isEditPersonal';
import {defaultButton} from '../../../blocks/customisableuserprofiles/src/components/FormWrapper';
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import CommonDatePicker from "../../../components/src/Calendar.web";
import moment from "moment";
import { FieldInputProps, Formik, FormikErrors, FormikTouched } from "formik";
import InputField from "../../../components/src/InputFieldwithArrow";
import { IMedicalForm } from "./GeneralRequestTabController";
import * as Yup from "yup";


// Customizable Area End

import GeneralRequestTabController, {
  Props,
} from "./GeneralRequestTabController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#ffffff",
    },
  },
});
const Wrapper = styled(Box)(({ theme }) => ({
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    "& .header": {
        display: 'flex',
        gap: '24px',
        color: '#000',
        marginBottom: '40px',
        alignItems: 'center'
    },
    "& .header-text": {
        fontSize: '40px',
        fontWeight: 600,
    },
    "& .sections": {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px'
    },
    "& .section-wrapper": {
        background: '#F6F6F6',
        padding: '32px 38px',
    },
    "& .fields-wrapper": {
        display: 'flex',
        gap: '32px',
        flexDirection: 'column',
        [theme.breakpoints.up("md")]: {
            flexDirection: 'row',
          },
    },
    "& .field-set": {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        width: '100%'
    },
    "& .arrow-style": {
        userSelect: 'none', cursor: 'pointer'
    },
    "& .section-header": {
        display: 'flex', gap: '7px', alignItems: 'center', marginBottom: '32px'
    },
    "& .section-text": {
        color: '#6F05EA',
        fontSize: '18px',
        fontWeight: 600
    },
    "& .field-wrapper": {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        width: '100%'
    },
    "& .field-label": {
        color: '#6F05EA',
        fontSize: '12px',
        fontWeight: 400
    },
    "& .field-value": {
        color: '#000000',
        fontSize: '18px',
        fontWeight: 500
    },
    "& .buttons-wrapper": {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        gap: '20px'
    },
    "& .secondary-btn": {
        ...defaultButton,
        border: "1px solid #6F05EA",
        color: '#6F05EA',
        padding: '10px 14px',
        fontWeight: 700,
        fontSize: '18px'
    },
    "& .main-btn": {
        ...defaultButton,
        border: "1px solid #6F05EA",
        color: '#6F05EA',
        fontWeight: 700,
        fontSize: '18px',
        padding: '10px 14px',
    },
}))
const generalValidationSchema = Yup.object().shape({
    updateddate: Yup.string()
      .required('UpdatedDate is required')
      .test('is-not-future-date', '*Please enter a valid updateDdate', value =>
        moment(value, 'DD/MM/YYYY').isSameOrBefore(moment())
      )
      .trim(),
  });
// Customizable Area End

export default class GeneralRequestTab extends GeneralRequestTabController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderHeaderPart = () => {
    return (
      <DashboardBox>
        <Box className="dashboardHeading">
          <IconButton className="menuIconBtn" data-test-id="sidebar" onClick={() => {this.openSideBar() } }><MenuIcon /></IconButton>
          <Typography className={this.state.isSideBarOpen ? "tabHeading" : "dashHeading"} variant="body1"></Typography></Box>
        
      </DashboardBox>
    )
  };
  renderRequestSection=()=>{
return (
  <>
  <RequestSection>
  <Box className="Request-content-logo">
    <Box><img src={request}/></Box>
    <Box><Typography className="request-title">Request</Typography></Box>
    </Box>
  <FieldsRow 
                      left={
                        <Box className="about-details-container">
                        <Box>
                          <Typography className="about-lable-Title">
                          Type
                          </Typography>
                          <Typography
                            variant="body1"
                            component="div"
                            className="lable-value" 
                          >
                           {this.state.RequestDetails.attributes?.request_type}
                          </Typography>
                        </Box></Box>
                      }
                      right={
                       <Box></Box>
                      }
                     
                    />
  <FieldsRow 
                      left={
                        <Box className="about-details-container">
                        <Box>
                          <Typography className="about-lable-Title">
                          Updated Date
                          </Typography>
                          <Typography
                            variant="body1"
                            component="div"
                            className="lable-value" 
                          >
                           {this.state.RequestDetails.attributes?.schedule_detail.start_date}
                          </Typography>
                        </Box></Box>
                      }
                      right={
                        <Box className="about-details-container">
                        <Box>
                          <Typography className="about-lable-Title">
                          Updated Time
                          </Typography>
                          <Typography
                            variant="body1"
                            component="div"
                            className="lable-value" 
                          >
                           12:30
                          </Typography>
                        </Box></Box>
                      }
                     
                    />
                    {this.state.RequestDetails.attributes?.status=='pending' && 
                        <Box className="button-container">
                        <Box><Button
                                                    data-test-id="Btn-EmailLogIn"
                                                      variant="contained"
                                                      disabled={this.state.isaccepted}
                                                      fullWidth
                                                      type="submit"
                                                      className="reject-Btn"
                                                      onClick={()=>this.rejectRequestCall('rejected2')}
                                                    >
                                                      Reject
                                                    </Button>
                                                    </Box>
                       <Box>
                       <Button
                                                      data-test-id="Btn-EmailLogIn"
                                                      variant="contained"
                                                      fullWidth
                                                      type="submit"
                                                      className="accept-Btn"
                                                      onClick={()=>this.rejectRequestCall('accepted')}
                                                    >
                                                      Accept
                                                    </Button>
                       </Box>
                        </Box>
                    }
{this.state.RequestDetails.attributes?.status=='accepted' && <Box className="button-container"><Typography style={{color:'red'}}>Accepted</Typography></Box>}
  <Box className="button-container"><Typography style={{color:'#C10000'}}>{this.state.rejectedMsg}</Typography></Box>

  </RequestSection>
  </>
)
  }
  renderBithdaySelect = (
    values: IMedicalForm,
    getFieldProps: (val: string) => FieldInputProps<string>, 
    errors: FormikErrors<IMedicalForm>,
    touched: FormikTouched<IMedicalForm>,
    setFieldValue: (field: string, val: string) => void,
  ) => {
    return (
      <Box style={{ position: "relative" }}>
        <CommonDatePicker
        data-test-id={'datepicker'}
          disabled={!this.state.isEditGeneral}
          selectedDate={
            this.state.medical.updateddate
              ? moment(this.state.medical.updateddate, "DD-MM-YYYY").toDate()
              : null
          }
          handleDateChange={(date: Date | null) => {
            setFieldValue(
              "updateddate",
              moment(date).format("DD/MM/YYYY")
            );
            this.setnewDate(moment(date).format("DD/MM/YYYY"))
          }}
        />
        <InputField
          {...getFieldProps("updateddate")}
          {...this.getInputProps("updateddate", errors, touched, values)}
          label="Updated Date"
          placeholder="Select Date"
          inputProps={{
           
          }}
          labelStyle={{color:'#6F05EA',fontWeight:400,fontFamily:'Montserrat',fontSize:12}}
        />
      </Box>
    )
  }
  renderAboutSectionContainer = () => {
    const defaultInputProps = {
        style: styles.inputStyleGeneral, 
      }
    const { open } = this.state;
      const {  ...rest } = this.state.medical

    return (
      <RequestContainer>
        <AboutTitle className="Request-content-logo">
    <Box><img src={image_paw_24px}/></Box>
    <Box><Typography className="request-title">About</Typography></Box>
    </AboutTitle>
        <Box>
                  <GenericModal id='request-model' isOpen={this.state.isOpenLanguageModal} title="" onClose={() => this.closeModal()} withoutCloseBtn={true}>
                    <Box style={{display:'flex',height:50}}></Box>
                    <Box style={styles.settings.modalBody}>
                      <Typography style={styles.settings.modalText}>{configJSON.cancelMessage}</Typography>
                     <Box><Typography style={{fontWeight:500,color:'#C10000',fontSize:18}}>You will be penalized as described in Terms and Conditions.</Typography></Box>
                      <Box style={{ display: 'flex', gap: '24px',marginTop:30,width:'100%',justifyContent:'center'}}>
                        <Button
                          data-test-id='cancel-btn-modal'  
                          style={{
                            ...styles.actionButtonDefault, 
                            border: '1px solid #4B4B4B', 
                            color: '#4B4B4B',
                            fontSize:24,fontWeight:600,
                            width:'100%',maxWidth:253,height:56
                            } as React.CSSProperties} 
                          onClick={() => {
                            this.GetCancelAppointment()
                          }}>Yes, I’m sure
                        </Button>
                        <Button
                          data-test-id='continue-btn'  
                          style={{
                            ...styles.actionButtonDefault, 
                            border: '1px solid #6F05EA', 
                            width:'100%',maxWidth:253, fontSize:24,fontWeight:600,height:56,
                            color: '#6F05EA',
                            } as React.CSSProperties} 
                          onClick={() => {
                            this.GetRejectCancelAppointment()
                          }}
                        >
                            No, don’t cancel
                        </Button>
                      </Box>
                      
                    </Box>
                   
                    <Box style={{display:'flex',height:50}}></Box>
                  </GenericModal>
                 
                  <GenericModal id='request-model2' isOpen={open} title="" onClose={() => this.handleClose()} withoutCloseBtn={true}>
                    <Box style={{padding:20}}>
          <Formik
            initialValues={{
                ...rest,
              }}
            enableReinitialize
            validationSchema={generalValidationSchema}
            onSubmit={(values) => {
           
            }}
            data-test-id="date-form"
          >
            {({
              errors,
              values,
              touched,
              isValid,
              dirty,
              handleChange,
              handleBlur,
              handleSubmit,
              getFieldProps,
              setFieldValue,
              resetForm,
            }) => {
                return (
                  <form onSubmit={handleSubmit}>
            <Typography variant="h6" component="h2" style={{color:'#6F05EA',fontSize:23,fontWeight:700,fontFamily:'Montserrat',marginTop:-20,marginBottom:40}}>
              Request Change
            </Typography>
                     <FieldsRow 
                      left={
                        <Box style={styles.labelSection}>
      <Box>
        <Typography style={styles.labeltitle}>
        Old Date
        </Typography>
        <Typography
          variant="body1"
          component="div"
          style={styles.labelValue}
        >
          {this.state.RequestDetails.attributes.schedule_detail.start_date}
        </Typography>
      </Box></Box>
                      }
                      right={
                        <Box style={styles.labelSection}>
      <Box>
        <Typography style={styles.labeltitle}>
          Old Time
        </Typography>
        <Typography
          variant="body1"
          component="div"
          style={styles.labelValue}
        >
         02:00 pm
        </Typography>
      </Box></Box>
                      }
                    />
                     
             
                     <FieldsRow 
                     left=
                        { 
                            this.renderBithdaySelect(values, getFieldProps, errors, touched, setFieldValue)}
                        right= { 
                            <InputField
          {...getFieldProps("time")}
          {...this.getInputProps("time", errors, touched, values)}
          label="Updated Time"
          placeholder="Select Time"
          inputProps={{
           defaultInputProps
          }}
          type={"time"}
          fullWidth
          labelStyle={{color:'#6F05EA',fontWeight:400,fontFamily:'Montserrat',fontSize:12}}
        />
                      }
                      
                     />
                          <Box style={{ display: 'flex', gap: '24px',marginTop:30,width:'100%',justifyContent:'center'}}>
                        <Button
                          data-test-id='cancel-btn-modal'  
                          style={{
                            ...styles.actionButtonDefault, 
                            border: '1px solid #4B4B4B', 
                            color: '#4B4B4B',
                            fontSize:24,fontWeight:600,
                            width:'100%',maxWidth:253,height:56
                            } as React.CSSProperties} 
                          onClick={() => {
                            this.handleOpen()
                          }}>Cancel
                        </Button>
                        <Button
                          data-test-id='continue-btn'  
                          style={{
                            ...styles.actionButtonDefault, 
                            border: '1px solid #6F05EA', 
                            width:'100%',maxWidth:253, fontSize:24,fontWeight:600,height:56,
                            color: '#6F05EA',
                            } as React.CSSProperties} 
                          onClick={() => {
                            this.createResheduleCalll()
                          }}
                        >
                            Request
                        </Button>
                      </Box>
                      <Box><Typography style={{color:'red',textAlign:'center',marginTop:10}}>{this.state.reshedulerrorMsg}</Typography></Box>

                    </form>
                ) 
                }}
            </Formik>
          </Box>
        </GenericModal>
                  <form >
                    <FieldsRow 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
          Animal
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         {this.state.RequestDetails.attributes?.pet.pet_detail.pet_category}
        </Typography>
      </Box></Box>
                      }
                      right={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
          Gender
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         {this.state.RequestDetails.attributes?.pet.pet_detail.gender}
        </Typography>
      </Box></Box>
                      }
                    />
                   
                    <FieldsRow 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
          Breed
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value"
        >
         {this.state.RequestDetails.attributes?.pet.pet_detail.breed}
        </Typography>
      </Box></Box>
                      }
                      right={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
          Birthdate
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         {this.state.RequestDetails.attributes?.pet.pet_detail.birthdate}
        </Typography>
      </Box></Box>
                      }
                    />
                    <FieldsRow 
                      left={
                        <Box className="about-details-container">
                        <Box>
                          <Typography className="about-lable-Title">
                            Weight
                          </Typography>
                          <Typography
                            variant="body1"
                            component="div"
                            className="lable-value" 
                          >
                          {this.state.RequestDetails.attributes?.pet.pet_detail.weight}
                          </Typography>
                        </Box></Box>
                      }
                      right={
                        <Box className="about-details-container">
                        <Box>
                          <Typography className="about-lable-Title">
                            Size
                          </Typography>
                          <Typography
                            variant="body1"
                            component="div"
                            className="lable-value" 
                          >
                           {this.state.RequestDetails.attributes?.pet.pet_detail.size}
                          </Typography>
                        </Box></Box>
                      }
                    />
                   
                    <FieldsRow 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
          Fur
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         {this.state.RequestDetails.attributes?.pet.pet_detail.fur}
        </Typography>
      </Box></Box>
                      }
                      right={
                        <></>
                      }
                     
                    />
                
                  </form>
                  </Box>
      </RequestContainer>
    )
  }


  renderOwnerTab=()=>{
    return(
      <>
     <RequestContainer>
        <AboutTitle className="Request-content-logo">
    <Box><img src={image_crop_24px}/></Box>
    <Box><Typography className="request-title">Owner Details</Typography></Box>
    </AboutTitle>
    <AboutTitle className="Request-profile-logo" >
    <Box><img style={{borderRadius:100,width:50,height:50}} src={this.state.RequestDetails.attributes?.customer.image_url}/></Box>
    <Box><Typography className="request-title">{this.state.RequestDetails.attributes?.customer.customer_detail.first_name}</Typography></Box>
    </AboutTitle>
    <form style={{marginTop:20}}>
                    <FieldsRow 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
          Address
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         {this.state.RequestDetails.attributes?.customer.customer_detail.address}
        </Typography>
      </Box></Box>
                      }
                      right={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
          Country
        </Typography>
        <Typography
          component="div"
          variant="body1"
          className="lable-value" 
        >
         {this.state.RequestDetails.attributes?.customer.customer_detail.country}
        </Typography>
      </Box></Box>
                      }
                    />
                   
                    <FieldsRow 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
          City
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         {this.state.RequestDetails.attributes?.customer.customer_detail.city}
        </Typography>
      </Box></Box>
                      }
                      right={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
          Zipcode
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
        {this.state.RequestDetails.attributes?.customer.customer_detail.zipcode}
        </Typography>
      </Box></Box>
                      }
                    />
                    <FieldsRow 
                      left={
                        <Box className="about-details-container">
                        <Box>
                          <Typography className="about-lable-Title">
                            Phone
                          </Typography>
                          <Typography
                            variant="body1"
                            component="div"
                            className="lable-value" 
                          >
                           {this.state.RequestDetails.attributes?.customer.customer_detail.phone_number}
                          </Typography>
                        </Box></Box>
                      }
                      right={
                        <></>
                      }
                     
                    />
                    
                  </form>
    </RequestContainer>
        </>
    )
  }
  renderGeneralTab=()=>{
    return(
      <>
      {this.renderRequestSection()}
      <AboutSection>
      {this.renderAboutSectionContainer()}
      </AboutSection>
      <AboutSection>
      {this.renderOwnerTab()}
      </AboutSection>
   <Box style={{marginTop:20}}>
    {!this.state.isEditRequest ? 
    <>
   {this.renderActionButtons()}</>:<Box></Box>
}
   </Box>
     
      </>
    )
  }

  renderRangeSelect = (renderRangeSelect2: {
    labelStart: string, 
    labelEnd: string, 
    value: string | undefined, 
    field: string
  }, setFieldValue: (field: string, value: number) => void) => {
    const isActive = renderRangeSelect2.value;

    return (
        <Box style={{ display: 'flex'}} key={renderRangeSelect2.field}>
          <Typography style={{
              fontSize: '18px',
            color: isActive ? '#000000': '#777777',
            fontWeight: 500,
            minWidth: '110px'
          }}>{renderRangeSelect2.labelStart}</Typography>
          <Box style={{ alignItems: 'center',display: 'flex'}}>
            <Box style={styles.renderRangeSelect2}>
              <Box data-test-id='range-select1-1' style={this.getDotStyle(renderRangeSelect2.value, 0)} onClick={() => this.state.isEditTreats && setFieldValue(renderRangeSelect2.field, 0)}/>
            </Box>
            <Box style={styles.renderRangeSelect2}>
              <Box data-test-id='range-select1-2' style={this.getDotStyle(renderRangeSelect2.value, 1)} onClick={() => this.state.isEditTreats && setFieldValue(renderRangeSelect2.field, 1)}/>
            </Box>
            <Box style={styles.renderRangeSelect2}>
              <Box data-test-id='range-select1-3' style={this.getDotStyle(renderRangeSelect2.value, 2)} onClick={() => this.state.isEditTreats && setFieldValue(renderRangeSelect2.field, 2)}/>
            </Box>
          </Box>
          <Typography style={{
            color: isActive ? '#000000': '#777777',
            fontWeight: 500,
            fontSize: '18px',
          }}>{renderRangeSelect2.labelEnd}</Typography>
        </Box>
    )
  }
  renderTraitsTab = () => {
    const rangeSelects2 = [
      {
        labelStart: configJSON.calm,
        field: 'calm',
        labelEnd: configJSON.energetic,
        
      },{
        field: 'relaxed',
        labelStart: configJSON.relaxed2,
        labelEnd: configJSON.anxious,
        
      },{
        labelStart: configJSON.easyGoing2,
        labelEnd: configJSON.shy,
        field: 'easy_going'
      },{
        labelStart: configJSON.friendly2,
        field: 'friendly',
        labelEnd: configJSON.aggressive,

      },{
        labelStart: configJSON.gentle2,
        labelEnd: configJSON.strong,
        field: 'gentle'
      },{
        labelEnd: configJSON.rebbelious,
        labelStart: configJSON.obedient2,
        field: 'obedient'
      }
    ]

    return (
        <AboutSection>
            <RequestContainer>
      <Box style={{ width: '100%'}}>
        <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px', marginBottom: '35px'}}>{configJSON.personality}</Typography>
        <Typography style={{ fontWeight: 500, fontSize: '18px', marginBottom: '35px'}}>{configJSON.personalityDesc}</Typography>
        <Formik
          initialValues={this.state.treats}
          enableReinitialize
          onSubmit={(values) => {
          }}
          data-test-id="treats-form"
        >
          {({
            errors,
            values,
            touched,
            setFieldValue,
            resetForm,
          }) => {
            return (
              <form >
              <Box style={{ display: 'flex', flexDirection: 'column', gap: '35px', marginBottom: '60px'}}>
                {rangeSelects2.map(item => this.renderRangeSelect({...item, value: values[item.field as 'relaxed']}, setFieldValue))}
              </Box>
        <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px', marginBottom: '35px'}}>{configJSON.habits}</Typography>
              <FieldsRow 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
        How many walks
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
        3
        </Typography>
      </Box></Box>
                      }
                      right={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
        Walking schedules
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         7:00, 15:00, 23:00
        </Typography>
      </Box></Box>
                      }
                    />
                      <FieldsRow 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
        How many potty
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         3
        </Typography>
      </Box></Box>
                      }
                      right={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
        Potty schedules
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         7:00, 15:00, 23:00
        </Typography>
      </Box></Box>
                      }
                    />
                      <FieldsRow 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
        How many meals
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         3
        </Typography>
      </Box></Box>
                      }
                      right={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
        Meal schedules
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
        7:00, 15:00, 23:00
        </Typography>
      </Box></Box>
                      }
                    />
            </form>
            )
        }}
        </Formik>
        
      </Box>
      </RequestContainer>
      </AboutSection>
    )
  }

  renderPetMedicalForm = () => {
    return (
        <AboutSection>
        <RequestContainer>
        <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px', marginBottom: '35px'}}>Health bulletin</Typography>
        <Box>
                  <form >
                    <FieldsRowVendor 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title2">
        Medical conditions
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="input-field" 
        >
         Please insert any illness that conditions your pet’s daily life
        </Typography>
      </Box></Box>
                      }
                    
                    />
                   
                    <FieldsRowVendor 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title2">
        Allergies
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="input-field"
        >
         Please insert any allergies your pet may have
        </Typography>
      </Box></Box>
                      }
                    
                    />
                    <FieldsRowVendor 
                      left={
                        <Box className="about-details-container">
                        <Box>
                          <Typography className="about-lable-Title2">
                          Usual medication
                          </Typography>
                          <Typography
                            variant="body1"
                            component="div"
                            className="input-field" 
                          >
                          Please insert medicine your pet is taking
                          </Typography>
                        </Box></Box>
                      }
                     
                    />
                   
                    <FieldsRowVendor 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
        Last health check up
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
        03/02/2024
        </Typography>
      </Box></Box>
                      }
                    />
                    
                  </form>
                  </Box>
                  <Box>
                  <Box style={{ display: 'flex', gap: '22px', alignItems: 'center', marginBottom: '22px' }}>
                  <Switch
                    id="cheap"
                    name="cheap"
                    disabled={!this.state.isEditMedical}
                    checked={this.state.cheap}
                  />
                  <Typography style={{ fontWeight: 500, fontSize: '18px',color:'#000'}}>Microchip</Typography>
                </Box>
                { !this.state.cheapNumber && 
                  <Box className="about-details-container">
                  <Box>
                    <Typography className="about-lable-Title">
                    Microchip number
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className="lable-value" 
                    >
                    FECAVA
                    </Typography>
                  </Box></Box>
                }
                
                <Box style={{ display: 'flex', gap: '22px', alignItems: 'center', marginBottom: '22px',marginTop:30 }}>
                  <Switch
                    id="vaccines_up_to_date"
                    name="vaccines_up_to_date"
                    disabled={!this.state.isEditMedical}
                    checked={this.state.cheap}
                  />
                  <Typography style={{ fontWeight: 500, fontSize: '18px',color:'#000'}}>Vaccines up to date</Typography>
                </Box>
                <Box style={{ display: 'flex', gap: '22px', alignItems: 'center', marginBottom: '57px' }}>
                  <Switch
                    id="sterilized"
                    name="sterilized"
                    checked={this.state.cheap}
                    disabled={!this.state.isEditMedical}
                 
                  />
                  <Typography style={{ fontWeight: 500, fontSize: '18px',color:'#000'}}>Sterilized</Typography>
                </Box>
                <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px', marginBottom: '35px'}}>Usual veterinary</Typography>
                <FieldsRow 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
        Clinic/Hospital
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         Centro Veterinário do Porto
        </Typography>
      </Box></Box>
                      }
                      right={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
          Phone
        </Typography>
        <Box style={{ width: '100%' }}>
        <PhoneInput
          buttonStyle={{ border: "none", background: "transparent" }}
          placeholder="Code"
          inputStyle={{
            ...styles.medicalFormPhone,
          }}
          dropdownStyle={{ fontSize: "13px" }}
          value={'8709886309'}
          inputProps={{
            maxLength: 16,
            readOnly: true
          }}
          data-test-id="PhoneNumber"
        />
      </Box>
      </Box></Box>
                      }
                    />
                    <FieldsRow 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
        Clinic Address
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         Centro Veterinário do Porto
        </Typography>
      </Box></Box>
                      }
                      right={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
          Doctor
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         Dra. Maria Santos
        </Typography>
      </Box></Box>
                      }
                    />
               <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px', marginBottom: '35px'}}>Insurance</Typography>
               <FieldsRow 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
        Company
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         Fidelidade
        </Typography>
      </Box></Box>
                      }
                      right={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
          Policy Number
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         3737910474719
        </Typography>
      </Box></Box>
                      }
                    />
                    <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px', marginBottom: '35px'}}>Emergency contacts</Typography>
               <FieldsRow 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
        Primary Contact
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         Joana Esteves
        </Typography>
      </Box></Box>
                      }
                      right={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
          Phone
        </Typography>
        <PhoneInput
          placeholder="Code"
          buttonStyle={{  background: "transparent",border: "none" }}
          inputStyle={{
            ...styles.medicalFormPhone,
          }}
          dropdownStyle={{ fontSize: "13px" }}
          inputProps={{
            maxLength: 16,
            readOnly: true
          }}
          value={'8709886309'}
          data-test-id="PhoneNumber"
        />
      </Box></Box>
                      }
                    />
                     <FieldsRow 
                      left={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
        Secondary Contact
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className="lable-value" 
        >
         Fidelidade
        </Typography>
      </Box></Box>
                      }
                      right={
                        <Box className="about-details-container">
      <Box>
        <Typography className="about-lable-Title">
          Phone
        </Typography>
        <PhoneInput
          placeholder="Code"
          inputStyle={{
            ...styles.medicalFormPhone,
          }}
          buttonStyle={{ border: "none", background: "transparent" }}
          dropdownStyle={{ fontSize: "13px" }}
          value={'8709886309'}
          inputProps={{
            readOnly: true,
            maxLength: 16,
          }}
          data-test-id="PhoneNumber"
        />
      </Box></Box>
                      }
                    />
                  </Box>
      </RequestContainer>
      </AboutSection>
    )
  }
  renderSettingsTabContent = () => {
    switch (this.state.currentSettingsTab) {
      case 0:
        return this.renderGeneralTab();
      case 1:
        return this.renderPetMedicalForm();
      case 2:
        return this.renderTraitsTab();
      default:
        break;
    }
  }
  renderActionButtons = () => {
    return (
      <>
      <Wrapper>
    <Box className='buttons-wrapper'>
                    {this.state.isEditGeneral ? 
                    <>
                        <Button className='secondary-btn' data-test-id='cancel-btn' style={{minWidth:153}} onClick={() => this.toggleActions()}>Cancel</Button>
                        <Button className='secondary-btn' data-test-id='cancel-appointment' onClick={() => this.closeModal()} >Cancel Appointment</Button>
                        <Button className='main-btn' data-test-id='request-change' onClick={this.handleOpen}>Request change</Button>
                    </> 
                    : <Button data-test-id='edit-btn' className='main-btn' style={{minWidth:153}} onClick={this.toggleActions}> Edit </Button>
                    }
                </Box>
                </Wrapper>
    </>
    )
  }

  renderSettingsBlock = () => {
    return (
      <Box style={{ width: '100%', textAlign: 'left',marginTop:25}}>
        <Tabs
        
          variant="scrollable"
          scrollButtons="auto"
          value={this.state.currentSettingsTab}
          data-test-id="settings-tabs"
          onChange={(_, idx) => this.setTabId(idx)}
          TabIndicatorProps={{
          style: {
              backgroundColor: '#6F05EA',
          },
          }}
        >
          <Tab label={configJSON.general} className="custom-tab" />
          <Tab label={configJSON.medical} className="custom-tab" />
          <Tab  label={configJSON.traits} className="custom-tab" />
        </Tabs>
        {this.renderSettingsTabContent()}
     
      </Box>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      
        <><AppVendorHeader onMenuSelect={function (selected: string): void {} }/>
        <RequestSectionBox>
          <Box className={this.state.isSideBarOpen ? "sideBarOpen" : "sideBar"}>
          <Box style={{display:'flex', width: "100%"}}>
        <Sidebar navigation={this.props.navigation}/>
        </Box>
          </Box>;
    
          <Box className="mainSection" data-test-id={"sidebarMenu"}>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} >
                {this.renderHeaderPart()}
              </Grid>
            <Grid item xs={12} sm={8} md={9} lg={9} xl={9} style={{width:'100%',display:'flex'}} >
            <Box className="grid-main-content">
            
      <Box style={{display:'flex',flexDirection:'column',width:'100%'}}>
      <Box style={{width:'100%',display:'flex',flexDirection:'row'}}>
      <Typography style={{display: 'flex', width: 16,height: 27,alignSelf:'center'}}><img src={Backarrow}/></Typography>
      <Typography style={{color:'#0F172A',fontSize:40,fontWeight:600,fontFamily:'Montserrat',marginLeft:20}}>{this.state.RequestDetails.attributes?.pet.pet_detail.name}</Typography>
      </Box>
      <Box style={{width:437,height:148,display:'flex',flexDirection:'row',marginTop:25}}>
      <Box><img src={Catprofile}/></Box>
      {!this.state.iscancelAppointment ? 
    <Box style={{display:'flex',flexDirection:'column',marginLeft:20,marginTop:8}}>
    <Typography className="profile-title">{this.state.RequestDetails.attributes?.pet.pet_detail.pet_category}</Typography>
    <Typography className="profile-date">Date: {this.state.RequestDetails.attributes?.pet.pet_detail.birthdate}</Typography>
    <Typography className="profile-date">Time: 10 am to 11 am</Typography>
    <Typography className="profile-date">Price: 37€</Typography>
    </Box>:<Box className="Cancel-Appointment-box">
    <Typography className="profile-title">{this.state.RequestDetails.attributes?.pet.pet_detail.pet_category}</Typography>
      <Typography style={{fontFamily:'Montserrat',fontWeight:500,fontSize:16,color:'#C10000'}}>Appointment is cancelled</Typography></Box>  
    }
      
      </Box>
      </Box>
        <PageWrapper >
        <Box>
          {this.renderSettingsBlock()}
        </Box>
        <Box style={{ marginTop: '40px'}}>
        
                      </Box>
        </PageWrapper>
        
      </Box>
              </Grid>
          </Box>
          </RequestSectionBox>
          <AppFooter />
             </>
        
      // Customizable Area End
    );
  }
}
// Customizable Area Start
  
  const PageWrapper = styled(Box)({
    "& *": {
      boxSizing: 'border-box',
    },
    '& .MuiInput-underline.Mui-disabled': {
        '&::before': {
          borderBottomStyle: 'solid'
        }
      },
    "& .MuiTypography-root.MuiTypography-body1": {
      fontFamily: 'Montserrat',
    },
  
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'inherit'
    },
  
    '& .MuiInputBase-input.MuiInput-input': {
      "&::placeholder": {
        color: '#A6A6A6',
        fontSize: '13px',
        opacity: 1,
        fontWeight: 400,
        fontFamily: 'Montserrat',
      }
    },
  
    "& .custom-tab.Mui-selected": {
      fontWeight: 700,
      color: '#6F05EA',
      borderBottom:'3px solid #6F05EA'
    },

    "& .custom-tab": {
        color: '#B7B7B7',
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontWeight: 500,
        flex: 1,
        textTransform: 'none',
        maxWidth:322,width:'100%',padding:12,gap:'6px'
      },
    "& .vertical-tab.Mui-selected": {
      color: '#6F05EA',
      fontWeight: 600,
    },
    "& .vertical-tab": {
      color: '#808080',
      fontSize: '14px',
      fontWeight: 600,
      flex: 1,
      textTransform: 'none',
      position: 'relative',
      fontFamily: 'Montserrat',
  
      '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          top: 0,
          left: 0,
          width: '1px', 
          backgroundColor: '#000',
      },
    },
   
    "& .MuiSwitch-thumb": {
      boxSizing: 'border-box',
      height: 22,
      width: 22,
  
    },
   
    "& .MuiListItem-button": {
      '&:hover': {
        textTransform: 'none',
        backgroundColor: 'rgba(111, 5, 234, 0.1)', 
      },
      fontFamily: 'Montserrat',
    },
    "& .MuiSwitch-switchBase": {
        padding: 0,
        transitionDuration: '300ms',
        margin: 2,
        '&.Mui-checked': {
          color: 'rgba(111, 5, 234, 1)',
          transform: 'translateX(27px)',
          '& + .MuiSwitch-track': {
            backgroundColor: 'rgba(111, 5, 234, 0.2)',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
      },
    "& .MuiSwitch-root": {
      height: 26,
      width: 54,
      padding: 0,
    },
    "& .main-menu-wrapper": {
        [theme.breakpoints.up("sm")]: {
          display: 'flex',
        },
        display: 'none',
      },
    "& .MuiSwitch-track": {
      backgroundColor: '#E9E9EA',
      borderRadius: 26 / 2,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  
   
    "& .custom-svg.active": {
      "& path": {
        fill: '#fff'
      }
    },
    "& .content-box": {
      flex: 1,
      paddingTop: '96px',
      paddingBottom: '96px',
      paddingLeft: '50px',
      paddingRight: '50px',
      background: 'white',
      textAlign: 'center',
  
      "& .content": {
        maxWidth: '100%',
        [theme.breakpoints.up("sm")]: {
          maxWidth: '965px',
        },
      }
    },
  
  
  });
  type NestedCSSProperties = { [key: string]: CSSProperties | NestedCSSProperties };
  type CSSProperties = React.CSSProperties;
  const styles: {
    general: {
      flexCol: CSSProperties;
    };
    selectedPet: CSSProperties;
    notSelectedPet: CSSProperties;
    renderRangeSelect2: CSSProperties;
    actionButtonDefault: CSSProperties;
    medicalFormPhone: CSSProperties;
    filledLabel: CSSProperties;
    secondaryLabel: CSSProperties;
    inputStyleGeneral: CSSProperties;
    labelSection:CSSProperties;
    labeltitle:CSSProperties;
    labelValue:CSSProperties;
    settings: NestedCSSProperties & { header: CSSProperties; modalText: CSSProperties; modalBody: CSSProperties
     };
  }  = {
    general: {
      flexCol: {
        flexDirection: 'column',  display: 'flex', 
      },
    },
    settings: {
      header: {
        marginBottom: '10px',
        fontWeight: 600,
        fontSize: '40px',
        color: '#000000',
      },
      modalText: {
        fontWeight: 700,fontSize: '24px', 
      },
      modalBody: {
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        textAlign: 'center', 
        flexDirection: 'column', 
        alignItems: 'center', 
        gap: '30px', 
        paddingTop: '20px',
        paddingBottom: '20px'
      }
    },
    selectedPet: {
      width: '75px',
      border: '2px solid rgba(111, 5, 234, 1)',
      transition: 'filter 0.3s ease, opacity 0.3s ease',
      backgroundColor: '#F0E5FF',
      color: '#6F05EA',
      height: '75px'
    },
    notSelectedPet: {
      width: '75px',
      height: '75px',
      opacity: 0.5,
      transition: 'filter 0.3s ease, opacity 0.3s ease',
      filter: 'grayscale(100%)',

    },
    labelSection:{
        display: 'flex', flexDirection: 'column',borderBottom:'1px solid #d9d9d9', pointerEvents: 'none'
    },
    labeltitle:{color:'#6F05EA',
    fontSize:12,
    fontFamily:'Montserrat',
    fontWeight:400, pointerEvents: 'none'},
    labelValue:{
        pointerEvents: 'none',fontSize:18,fontWeight:500,fontFamily:'Montserrat',marginTop:10,color:'#808080' 
    },
    renderRangeSelect2: {
      display: 'flex', 
      width: '104px', 
      justifyContent: 'center',
      alignItems: 'center', 

    },
    medicalFormPhone: {
        width: "100%",
        height: "32px",
        border: "none",
        borderRadius: "0px",
        paddingLeft: "40px",
      },
      actionButtonDefault: {
        height: '40px', 
        textTransform: 'none', 
        borderRadius: '100px', 
        width: '146px', 
        fontSize: '18px',
        fontFamily: 'Montserrat',
        fontWeight: 700
      },
   
    filledLabel: {
      fontSize: '13px',
      fontWeight: 400,
      color: '#6F05EA',
      fontFamily: 'Montserrat',
    },
  
    inputStyleGeneral: {
      height: '34px', 
      fontSize: '18px',
      fontWeight: 500,
      fontFamily: 'Montserrat',
      marginTop: '4px',

    },
    secondaryLabel: {
        fontSize: '18px',
        fontWeight: 400,
        fontFamily: 'Montserrat',
        color: '#777777',

      },
    }

  
  
  const RequestSectionBox = styled(Box)({
    display: "flex",
    height: "100%",
    flexDirection:'row',
    background:'#F6F4F9',
   boxSizing: 'border-box',
   "& .Cancel-Appointment-box": { 
  marginLeft:20,
  marginTop:30
   },
   "& .profile-date": {fontSize:16,fontWeight:500},
   "& .profile-title": {fontSize:30,fontWeight:600,fontFamily:'Montserrat',color:'#000',lineHeight:'36px'}
    ,"& .mainSection": {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      overflow: "scroll",
      backgroundColor: "#F6F4F9",
      width:'100%'
    },
    "& .grid-main-content": {
      display: 'flex',width:'100%',flexDirection:'column',
      margin:10,padding:40,
      "@media screen and (max-width:1023px)": {
        marginLeft: '-89px',
        padding: 20,
  
                 },
                 "@media screen and (max-width:500px)": {
                  margin: 38,
                  marginLeft:-69
                             },
                 "@media screen and (min-width:1025px)": {
                  margin: 10,
    padding: 40,
    marginLeft:-133
                 },
                 "@media screen and (min-width:1440px)": {
                  margin: 10,
    padding: 40,
    marginLeft:-170
                 },
                 "@media screen and (min-width:1650px)": {
                  margin: 10,
    padding: 40,
    marginLeft:-241
                 }
      
                },
    "& .tabLogo": {
      display: "none",
      width: "100%",
      paddingTop: "20px"
    },
    "& .tabLogoBox": {
      width: "160px",
      height: "50px"
    },
    "@media screen and (max-width:1024px)": {
      "& .sideBar": {
        display: "none"
      },
      "& .sideBarOpen": {
        display: "block"
      },
      "& .tabLogo": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    },
  
  
  });
  const RequestSection = styled(Box)({
    display:'flex',
    width:'100%',
    height:'auto',flexDirection:'column',
    boxShadow: '0px 4px 8px 0px #00000008',
    marginTop:20,
  border:'0px 0px 0px 4px',
  padding:'32px 38px 32px 38px',
  gap:'34px',
  borderRadius:10,
  background:'#fff',
    "& .Request-content-logo":{
      display:'flex',
      flexDirection:'row',
      
     },
     "& .Request-profile-logo":{
      display:'flex',
      flexDirection:'row',
      
     },
     "& .about-lable-Title": {
      color:'#6F05EA',
      fontFamily:'Montserrat',
      fontSize:12,
      fontWeight:400
     },
   
     "& .request-title":{
      fontSize:18,
      fontFamily:'Montserrat',
      color:'6F05EA',
      fontWeight:600,marginLeft:8,marginTop:-3
     },
     "& .type-title":{ 
      color:'#6F05EA',
      fontSize:12,
      fontWeight:400,
      fontFamily:'Montserrat'
     },
     "& .lable-value":{ marginTop:10,fontSize:18,pointerEvents: 'none',fontWeight:500,fontFamily:'Montserrat' },
  
     "& .about-details-container":{ flexDirection: 'column',display: 'flex', borderBottom:'1px solid #d9d9d9'}
  ,
     "& .cancel-title":{ 
      color:'#000',
      fontSize:18,
      fontWeight:500,
      fontFamily:'Montserrat'
     },
     "& .button-container":{ 
      display:'flex',
      flexDirection:'row',
      justifyContent:'flex-end',
      gap:'10px'
     },
     "& .cancel-box":{
      marginTop: -30
     },
     "& .reject-Btn": {
      border: "1px solid var(--Red, #C10000)",
      borderRadius: "100px",
      padding: "10px 0px 10px 0px",
      height: "40px",
      fontSize: "18px",
      width:146,
      gap: "10px",
      fontWeight: 700,
      textTransform: "none",
      color: "#C10000",
      backgroundColor: "transparent",
      fontFamily:'Montserrat'
    },
    "& .accept-Btn": {
      border: '1px solid var(--Primary-color, rgba(111, 5, 234, 1))',
      borderRadius: "100px",
      padding: "10px 0px 10px 0px",
      height: "40px",
      width:146,
      fontSize: "18px",
      gap: "10px",
      fontWeight: 700,
      textTransform: "none",
      color: "#6F05EA",
      backgroundColor: "transparent",
      fontFamily:'Montserrat'
    },
     
     
  })
  const AboutSection = styled(Box)({
    width:'100%',
    height:'auto',
    borderRadius:10,
    padding:'32px 38px 32px 38px',
    border:'0px 0px 0px 4px',
    gap:'34px',
    background:'#fff',
    boxShadow: '0px 4px 8px 0px #00000008',
    display:'flex',
    flexDirection:'row',
    marginTop:20,
    "& .column-one":{
      display:'flex',
      flexDirection:'column'
     },
     "& .column-two":{
      flexDirection:'column',
      display:'flex',
  
     },
     "& .about-lable-Title2": {
        color:'#334155',
        fontFamily:'Montserrat',
        fontSize:18,
        fontWeight:600
       },
       "& .input-field": {
        color:'#A6A6A6',
        fontFamily:'Montserrat',
        fontSize:13,
        fontWeight:400
       },
  
  })
  const MedicalWrapper = styled(Box)({
    width: '100%', 
    display: 'flex', 
    gap: '27px', 
    flexDirection: 'column',
  })
  const AboutTitle= styled(Box)({
    display:'flex',
    flexDirection:'row',
    gap:'10px',
    "& .Request-content-logo":{
      display:'flex',
      flexDirection:'row',
      
     },
     "& .request-title":{
      fontSize:18,
      fontFamily:'Montserrat',
      color:'6F05EA',
      fontWeight:600,
      marginBottom:30
     },
  });
  const RequestContainer=styled(Box)({
    textAlign: 'left', marginTop: '10px',width:'100%',
    "& .about-lable-Title": {
      color:'#6F05EA',
      fontSize:12,
      fontFamily:'Montserrat',
      fontWeight:400
     },
     "& .lable-value":{ pointerEvents: 'none',fontSize:18,fontWeight:500,fontFamily:'Montserrat',marginTop:10 },
     "& .about-details-container":{ display: 'flex', flexDirection: 'column',borderBottom:'1px solid #d9d9d9'}
  
  })
  
  const DashboardBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    padding: "20px",
    justifyContent:"space-between",
    "& .menuIconBtn":{
      display: "none"
    },
    "& .dashboardHeading": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      "& .MuiTypography-body1": {
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        fontFamily: "Inter",
        color: "#011342",
      },
    },
    "& .menuIcon": {
      display: "none",
    },
    "& .profileBox": {
      display: "flex",
      alignItems: "center",
      padding: "10px",
      gap: "15px",
      "& .MuiButton-root": {
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Inter",
        textTransform: "none",
        color: "white",
        background: "#012275",
        borderRadius: "4px",
        paddingRight: "15px",
        paddingLeft: "15px",
        height: "49px",
      },
    },
    "& .addIcon": {
      paddingRight: "8px",
    },
    "& .bellIconBox":{
      boxShadow: "0px 0px 3px rgba(0,0,0,0.1)",
    },
    "& .profile": {
      boxShadow: "0px 0px 3px rgba(0,0,0,0.1)",
      padding:"8px 25px 8px 8px",
      "& .MuiTypography-body2": {
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: "Inter",
        color: "#011342",
      },
      display: "flex",
      alignItems: "center",
      gap: "5px",
      order: 3,
    },
    "& .userImage": {
      width: "32px",
      height: "32px",
      "& img": {
        borderRadius: "50%",
      },
    },
    "@media screen and (max-width:1024px)": {
      "& .menuIconBtn":{
        display: "block"
      },
      "& .tabHeading":{
        display: "none"
      },
      "& .MuiButton-root": {
        fontSize: "14px",
        order: 2,
      },
      "& .profileBox": {
        "& img": {
          order: 1,
        },
      },
      "& .menuIcon": {
        display: "block",
      },
    },
    "@media screen and (max-width:599px)": {
      "& .dashboardHeading": {
        gap: "5px",
        "& .MuiTypography-body1": {
          fontSize: "20px !important",
          lineHeight: "20px !important",
        },
      },
      "& .profileBox": {
        padding: "5px",
        gap: "10px",
        "& .MuiButton-root": {
          fontSize: "10px !important",
          paddingRight: "5px !important",
          paddingLeft: "5px !important",
          height: "30px !important",
        },
      },
      "& .addIcon": { display: "none" },
      padding: "10px",
      "& .profile": {
        gap: "2px",
        "& .MuiTypography-body2": { display: "none" },
      },
    },
  });
// Customizable Area End
