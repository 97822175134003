import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
    getName,
  } from "../../../framework/src/Messages/MessageEnum";
  import { FormikErrors, FormikTouched } from "formik";
  const styles = {
    filledLabel: {
      fontSize: "13px",
      fontWeight: 400,
      color: "#6F05EA",
      fontFamily: "Montserrat",
    },
    secondaryLabel: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: '18px',
      color: '#777777'
    },
  };
  interface IApiModel {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string | null;
    isJsonStringify?: boolean;
  }
  const defaultRequestDetails={
 
    id: "16",
    type: "request",
    attributes: {
        id: 16,
        status: "pending",
      request_type: "Transportation Request",
        start_date: null,
        end_date: null,
        shift: null,
        duration: null,
        created_by: "customer",
        schedule_detail: {
            id: 19,
          start_date: "2024-09-05",
            end_date: "2024-09-14",
            shift: "12.30"
        },
        customer: {
            customer_detail: {
                id: 366,
                first_name: "Deepika Sept",
                last_name: "",
                full_phone_number: "8754219632",
                country_code: null,
                phone_number: 8754219632,
                email: "test6@yopmail.com",
                activated: true,
                device_id: null,
                unique_auth_id: "WRIU92WFa6cPC3sPd651gQtt",
                password_digest: "",
                created_at: "2024-09-05T06:37:06.668Z",
                updated_at: "2024-09-05T06:40:24.544Z",
                user_name: null,
                platform: null,
                user_type: null,
                app_language_id: null,
                last_visit_at: null,
                is_blacklisted: false,
                suspend_until: null,
                status: "regular",
                role_id: 2,
                gender: null,
                date_of_birth: null,
                age: null,
                gpa: null,
                country: "AF",
                city: "indore",
                language: "portuguese",
                nationality: "portuguese",
                address: "indore",
                zipcode: "5555",
                confirm_email: null,
                stripe_id: "cus_Qn9cnLZZJmiuuR",
                vendor_type: "company",
                tax_number: "778",
                company_name: "RK Tech",
                services_type: [
                    '',
                    ''
                ],
                facebook_url: null,
                instagram_url: null,
                linkedin_url: null,
                tiktok_url: null,
                client_id: null,
                iban: null
            },
            image_url: "https://minio.b436904.dev.eastus.az.svc/sbucket/nwhxbvfzf7hy6089f0esb753qavn?response-content-disposition=inline%3B%20filename%3D%22image_product-image.png%22%3B%20filename%2A%3DUTF-8%27%27image_product-image.png&response-content-type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=hello%2F20240906%2Fbuilder-1%2Fs3%2Faws4_request&X-Amz-Date=20240906T122348Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=ae80338e7b3e90a5cb2ba626f1d73f8c2b13ddfc1a0c47c05005c16c4118e967"
        },
        pet: {
            pet_detail: {
                id: 156,
                pet_category: "Cat",
                name: "Cat",
                breed: "Breed 2",
                birthdate: "2023-07-13",
                gender: "Male",
                size: "Medium (45-64 cm)",
                weight: "Less then 5 kg",
                fur: '',
                species: "Genui Pig",
                account_id: 366,
                created_at: "2024-09-05T11:20:58.399Z",
                updated_at: "2024-09-05T12:47:36.840Z"
            },
            image_url: "https://minio.b436904.dev.eastus.az.svc/sbucket/wwd9tuot7d2bwjuz1cl45x57kox0?response-content-disposition=inline%3B%20filename%3D%22image_product-image%20%25283%2529.png%22%3B%20filename%2A%3DUTF-8%27%27image_product-image%2520%25283%2529.png&response-content-type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=hello%2F20240906%2Fbuilder-1%2Fs3%2Faws4_request&X-Amz-Date=20240906T122348Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=a705f13ba0b358ee089ac5ac8b142c81081d4a566d8f3ff9d95126b8d9f2d234"
        },
        service_provider: {
            service_provider_detail: {
                id: 277,
                first_name: "TestThree",
                last_name: "",
                full_phone_number: "97517234567",
                country_code: 975,
                phone_number: 17234567,
                email: "Test3@yopmail.com",
                activated: true,
                device_id: null,
                unique_auth_id: "7Oaeg8JL2T20gIyBUhwEHwtt",
                password_digest: "",
                created_at: "2024-08-08T07:59:36.173Z",
                updated_at: "2024-08-22T13:11:11.610Z",
                user_name: null,
                platform: null,
                user_type: null,
                app_language_id: null,
                last_visit_at: null,
                is_blacklisted: false,
                suspend_until: null,
                status: "regular",
                role_id: 1,
                gender: null,
                date_of_birth: null,
                age: null,
                gpa: null,
                country: "Albania",
                city: "Bajram Curri",
                language: "portuguese",
                nationality: "portuguese",
                address: "202-Maple-St,-Gotham,-IL-61550",
                zipcode: "54567",
                confirm_email: null,
                stripe_id: "cus_QcgdgE0kBiyGoC",
                vendor_type: "company",
                tax_number: "-",
                company_name: "-",
                services_type: [
                    '',
                    ""
                ],
                facebook_url: "",
                instagram_url: "",
                linkedin_url: "",
                tiktok_url: "",
                client_id: "",
                iban: ""
            },
            image_url: "https://minio.b436904.dev.eastus.az.svc/sbucket/uatqex3qf4zyosf1hyiy79ugchw1?response-content-disposition=inline%3B%20filename%3D%2213-gallery%202.png%22%3B%20filename%2A%3DUTF-8%27%2713-gallery%25202.png&response-content-type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=hello%2F20240906%2Fbuilder-1%2Fs3%2Faws4_request&X-Amz-Date=20240906T122348Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=bb29dc419ff2cfeadc9e87be412534652286d76ff94b31ad7e775c407aa78ba3"
        }
    
},
meta: {
    message: "Request retrieved successfully"
}
}
  interface ITreatsForm {
    id?: number;
    calm: string | undefined;
    relaxed: string | undefined;
    easy_going: string | undefined;
    friendly: string | undefined;
    gentle: string | undefined;
    obedient: string | undefined;
    walks: number | undefined;
    walking_schedule: string;
    potty: number | undefined;
    potty_schedule: string;
    meal: number | undefined;
    meal_schedule: string;
  }
  export interface IMedicalForm {
    last_health_check_up: string;
    updateddate: string;
    time:string
  }
  interface IPersonalInfo {
    first_name: string,
    last_name: string,
    phone_number: string,
    date_of_birth: string,
    nationality: string,
    city: string,
    country: string,
    language: string,
    zipcode: string,
    address: string,
    id?: string,
  }
  const defaultTreats = {
    calm: undefined,
    relaxed: undefined,
    easy_going: undefined,
    friendly: undefined,
    gentle: undefined,
    obedient: undefined,
    walks: 0,
    walking_schedule: "",
    potty: 0,
    potty_schedule: "",
    meal: 0,
    meal_schedule: "",
  };
  
  const defaultPersonal = {
    first_name: "",
    last_name: "",
    phone_number: "",
    date_of_birth: "",
    nationality: "",
    city: "",
    country: "",
    language: "portuguese",
    zipcode: "",
    address: ""
  }
  const defaultMedical = {
    last_health_check_up: "",
    updateddate: '',
    time:''
}
  

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentMenuItem:number;
  isSideBarOpen:boolean;
  currentSettingsTab: number;
  profileImageUrl: string;
  isEditGeneral: boolean;
  iscancelAppointment:boolean;
  isOpenLanguageModal:boolean;
  cheap:boolean;
  cheapNumber:boolean
isSelectedGeneral:boolean;
  treats: ITreatsForm;
  personal: IPersonalInfo;
  isEditMedical: boolean;
  isEditTreats: boolean;
  isEditRequest:boolean;
  open:boolean;
  medical: IMedicalForm;
  RequestDetails:any;
  reshedulerrorMsg:string;
  isaccepted:boolean;
  rejectedMsg:string;
  newDate:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class GeneralRequestTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetRequestDetailsApi: string = "";
  resheduleRequestAPi: string = "";
  rejectacceptAPICall: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
        getName(MessageEnum.NavigationTargetMessage),
        getName(MessageEnum.NavigationPropsMessage),
        getName(MessageEnum.RestAPIResponceDataMessage),
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        
    ];

    this.state = {
        treats: defaultTreats,
        personal: defaultPersonal,
        isEditMedical: false,
        isEditTreats: false,
        currentMenuItem:0,
        currentSettingsTab: 0,
        isSideBarOpen:false,
        iscancelAppointment:false,
        profileImageUrl: "",
        isEditGeneral: false,
        isOpenLanguageModal: false,
        cheap:true,
        cheapNumber:false,
        isSelectedGeneral:true,
        isEditRequest:false,
        open: false,
        medical: defaultMedical,
        RequestDetails:'',
        reshedulerrorMsg:'',
        isaccepted:false,
        rejectedMsg:'',
        newDate:''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId===this.GetRequestDetailsApi) {
      this.handlebookingDetails(responseJson);
     }
     if (apiRequestCallId===this.resheduleRequestAPi) {
      this.handleresheduleRequest(responseJson);
     }
     if (apiRequestCallId===this.rejectacceptAPICall) {
      this.handlerejectacceptresponse(responseJson);
     }
    // Customizable Area End
  }

  // Customizable Area Start
  getDotStyle = (value: string | undefined, dotNumber: number) => {
    const notActiveStyles = {
      width: "11px",
      height: "11px",
      background: "#E7E7E7",
      borderRadius: "50%",
      transition: "background-color 0.3s, width 0.3s, height 0.3s",
    };

    const activeStyle = {
      width: "20px",
      height: "20px",
      background: "#6F05EA",
      borderRadius: "50%",
      transition: "background-color 0.3s, width 0.3s, height 0.3s",
    };

    if (value !== undefined) {
      if (Number(value) === dotNumber) {
        return activeStyle;
      }

      return { ...notActiveStyles, background: "#E2CDFB" };
    } else {
      return notActiveStyles;
    }
  };
  getInputProps = <T extends object>(
    field: keyof T,
    errors: FormikErrors<T>,
    touched: FormikTouched<T>,
    values: T,
  ) => {
    return {
      id: field,
      name: field,
      fullWidth: true,
      error: Boolean(errors[field]) && touched[field],
      helperText: (touched[field] && errors[field])?.toString(),
      labelStyle: values[field] ? styles.filledLabel : styles.secondaryLabel,
    };
  };
  getUserGeneralInfo = async () => {
    const userInfoToken = sessionStorage.getItem("login_token");
    this.GetRequestDetailsApi = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.requestdetailsEndpoint}/${13}`,
      token: userInfoToken,
    });
  };
  apiCall = async (data: IApiModel) => {
    const { contentType, method, endPoint, body, token, isJsonStringify } =
      data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  async componentDidMount() {
    this.getUserGeneralInfo();
  }
  setnewDate(date:any){
    this.setState({newDate:date,reshedulerrorMsg:''})
  }
  createResheduleCalll= async() =>{
    if(this.state.newDate!==''){
      this.setState({reshedulerrorMsg:''})
      const userInfoToken = sessionStorage.getItem("login_token");
      let formData = new FormData();
      formData.append("start_date", this.state.newDate);
      formData.append("request_type", 'Reschedule Request');
      formData.append("schedules_id", this.state.RequestDetails.attributes.id);
      this.resheduleRequestAPi = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.exampleAPiMethod,
        endPoint: `${configJSON.requestdetailsEndpoint}`,
        token: userInfoToken,
        body: formData,
        isJsonStringify: true,
      });
    }else{
      this.setState({reshedulerrorMsg:'Please select Updated date'})
    }
    
  }
  rejectRequestCall=async(type:string)=>{
    const userInfoToken = sessionStorage.getItem("login_token");
    let formDatas =  new FormData();
    formDatas.append("status", type);
    this.rejectacceptAPICall = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.patchcall,
      endPoint: `${configJSON.requestdetailsEndpoint}/${this.state.RequestDetails.attributes.id}`,
      token: userInfoToken,
      body: formDatas,
      isJsonStringify: true,
    });
  }
  handleresheduleRequest(responseJson:any){
    if(responseJson?.error){
      this.setState({reshedulerrorMsg:responseJson.error})
    }
      }
      handlerejectacceptresponse(responseJson:any){
        if(responseJson?.data){
          this.setState({isaccepted:true})
        }
        if(responseJson?.errors){
          this.setState({rejectedMsg:responseJson?.errors}) 
        }
      }
  handlebookingDetails(responseJson:any){
    if (responseJson.data) {
      this.setState({ RequestDetails: responseJson.data });
    }
   }
  openSideBar = () => {
    this.setState({ isSideBarOpen: !this.state.isSideBarOpen });
  };
  closeModal=()=>{
    this.setState({isOpenLanguageModal: !this.state.isOpenLanguageModal})
  }
  GetCancelAppointment=()=>{
    this.setState({iscancelAppointment:!this.state.iscancelAppointment,isOpenLanguageModal:false,isEditRequest:true})
  }
 toggleActions = () => {
    this.setState({isEditGeneral:!this.state.isEditGeneral})
}
  GetRejectCancelAppointment=()=>{
    this.setState({iscancelAppointment:false,isOpenLanguageModal:false})
  }
  
  setTabId=(id:number)=>{
    this.setState({ currentSettingsTab: id })
  }

  handleOpen = () => {
    this.setState({ open: !this.state.open,reshedulerrorMsg:'' });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  // Customizable Area End
}
