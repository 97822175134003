import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
    getName,
  } from "../../../framework/src/Messages/MessageEnum";
interface IApiModel {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string | null;
    isJsonStringify?: boolean;
  }
  interface EstablishmentGallaryUpdate {
    id: string;
    type: string;
    attributes: EstablishmentGallaryAttributes;
  }
  export interface EstablishmentGallaryAttributes {
    id: number;
    establishment_name: string;
    address: string;
    country: string;
    city: string;
    zipcode: string;
    email: string;
    phone_number: number;
    facebook_url: string;
    instagram_url: string;
    linkedin_url: string;
    tiktok_url: string;
    activated: false;
    created_at: string;
    updated_at: string;
    vandor_name: string;
  }
  export interface GallaryImageErrorResponse {
    detail?: string;
    account?: string;
    token?: string;
    error?:string;
  }
  interface IVendorImgVideoDataModel {
    id: number;
    url: string;
  }
  export interface MessageData{
    message:string
  }
  interface UpdateGallaryApiResponse {
    galleries: IVendorImgVideoDataModel[];
    meta:MessageData;
    errors: GallaryImageErrorResponse[];
    error: GallaryImageErrorResponse[]
  }
  interface GallaryImageResponse {
    galleries: [];
    errors?: GallaryImageErrorResponse[];
    error: GallaryImageErrorResponse[];
}
interface FileType{
  name:string
  lastModified: number, 
  webkitRelativePath: string, 
  size:number, 
  type:string 
}
export interface HandleDeleteRepo{
  meta:MessageData;
  errors?: GallaryImageErrorResponse[];
  error: GallaryImageErrorResponse[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedImageVideoId: number;
  openViewImageVideo: boolean;
  isOpenImgGallery:boolean;
  referenceImage:any;
  errorMessageReferenceImage:string;
  uploadedImagePreview:any[];
  uploadNewImagePre:any[];
  GallaryVedioImageArray:[];
  isEditImage:boolean;
  generalInfoFileUrl:string
  GallaryError:any;
  GallaryErrorErrorMsg:GallaryImageErrorResponse[]

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SettingGallaryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  UpdateGallaryImagesApi: string = "";
  GetGallaryImageAPi: string = "";
  DeleteImagesApi: string = "";
  
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
        getName(MessageEnum.NavigationTargetMessage),
        getName(MessageEnum.NavigationPropsMessage),
        getName(MessageEnum.RestAPIResponceDataMessage),
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        
    ];
    

    this.state = {
        selectedImageVideoId: 0,
      openViewImageVideo: false,
      isOpenImgGallery:false,
      referenceImage:'',
      errorMessageReferenceImage:'',
      uploadedImagePreview:[],
      GallaryVedioImageArray:[],
      GallaryError: '',
      generalInfoFileUrl:'',
      GallaryErrorErrorMsg:[],
      isEditImage:true,
      uploadNewImagePre:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.UpdateGallaryImagesApi:
            this.handleUpdateGallaryImagesApiResponse(responseJson);
            break;
          case this.GetGallaryImageAPi:
            this.handleGallaryImageResponse(responseJson);
            break;
            case this.DeleteImagesApi:
              this.handleDeletedImageresponse(responseJson);
            break;
        }
      }
    // Customizable Area End
  }

  // Customizable Area Start
  handleGallaryImageResponse = async(responseJson: UpdateGallaryApiResponse) => {
    if (responseJson.errors) {
            this.setState({ GallaryError: 'Invalid token' });
    } else {
        const responseData = responseJson;
        if(responseData.galleries.length>0){
          const newMediaArray = responseData.galleries.map((file, index) => ({
            id: this.state.uploadedImagePreview.length + index,
            src:file.url,
            imagefile:file.url,
            imageId:file.id
          }));
          this.setState((prevState) => ({
            uploadedImagePreview: [...prevState.uploadedImagePreview, ...newMediaArray],
            referenceImage:'/image'
        }));
        }
      }
}
handleDeleteMedia=async (id:number) => {
  this.setState((prevState) => ({
      uploadedImagePreview: prevState.uploadedImagePreview.filter((media:any) => media.imageId !== id),
  }));
  const userInfoToken = localStorage.getItem("login_token");
    const userId =  localStorage.getItem("establishment_ID");
  this.DeleteImagesApi = await this.apiCall({
    method: configJSON.httpDeleteMethod,
    endPoint: `${configJSON.DeleteGallary}?id=${userId}&gallery_id=${id}`,
  token: userInfoToken,
  isJsonStringify: false,
});
}
handleDeletedImageresponse=(responseJson: HandleDeleteRepo)=>{
  if (responseJson.errors?.some(error => error.token)) {
    this.goToLoginPage();
  }
  if(responseJson.meta){
    this.setState({isEditImage:true})
  }
}
goToLoginPage = () => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  async componentDidMount(): Promise<void> {
    this.getUserGeneralInfo();
    
  }
  handleUpdateGallaryImagesApiResponse = (
    responseJson: UpdateGallaryApiResponse
  ) => {
    if (responseJson.errors?.some(error => error.token)) {
      this.goToLoginPage();
    }
  };
  getUserGeneralInfo = async () => {
    const userInfoToken = localStorage.getItem("login_token");
    const userId =  localStorage.getItem("establishment_ID");
    this.GetGallaryImageAPi = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.GetGallaryShow}?id=${userId}`,
      token: userInfoToken,
    });
  };
  CalleditImage(){
    this.setState({isEditImage:!this.state.isEditImage})
  }
  SaveUploadedImage = async (
  ) => {
    const establishmentId = localStorage.getItem("establishment_ID");
    const establishmentToken = sessionStorage.getItem("login_token");
    let serviceGallaryUploadData = new FormData();
        this.state.uploadNewImagePre.forEach((media: { id: number ; src: string ;imagefile:File}) => {
          serviceGallaryUploadData.append(
                "establishment[galleries][]", media.imagefile, "[PROXY]"
            );
        })
        this.UpdateGallaryImagesApi = await this.apiCall({
          method: configJSON.httpPutMethod,
        endPoint: `${configJSON.accountsetting}/${establishmentId}`,
        token: establishmentToken,
        body: serviceGallaryUploadData,
        isJsonStringify: false,
      });
  };
 
  apiCall = async (data: IApiModel) => {
    const { contentType, method, endPoint, body, token, isJsonStringify } =
      data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      isJsonStringify ? JSON.stringify(body) : body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
