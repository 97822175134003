import React from "react";
// Customizable Area Start
import {
  Box, styled, Typography, TextField, Button, FormControlLabel, Checkbox,
  Select, MenuItem
} from "@material-ui/core";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { CheckedCheckBox, UnCheckedCheckBox } from "../assets";
import { checkCondition, generateTimeRange } from "../../../../components/src/HelperUtils";
interface IDays {
  day: string;
  value: string;
}

const DaysArray: IDays[] = [
  {
    day: "Monday",
    value: "Monday",
  },
  {
    day: "Tuesday",
    value: "Tuesday",
  },
  {
    day: "Wednesday",
    value: "Wednesday",
  },
  {
    day: "Thursday",
    value: "Thursday",
  },
  {
    day: "Friday",
    value: "Friday",
  },
  {
    day: "Saturday",
    value: "Saturday",
  },
  {
    day: "Sunday",
    value: "Sunday",
  },
];
interface IAnimal {
  animal: string;
  value: string;
}
const AnimalsArray: IAnimal[] = [
  {
    animal: "Dogs",
    value: "Dogs"
  },
  {
    animal: "Cats",
    value: "Cats"
  },
  {
    animal: "Fish",
    value: "Fish"
  },
  {
    animal: "Bird",
    value: "Bird"
  },
  {
    animal: "Rabbit",
    value: "Rabbit"
  },
  {
    animal: "Reptile",
    value: "Reptile"
  },
  {
    animal: "Rodents",
    value: "Rodents"
  }
]
// Customizable Area End

import GeneralController, {
  Props
} from "./GeneralController.web";

export default class General extends GeneralController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderScheduleSection = () => {
    const menuRange = generateTimeRange("09:00", "18:00", 30)
    return (
      <ScheduleWrapper>
        <WrapperTitle>Schedule Information</WrapperTitle>
        <Box style={style.subTitleWrapper}>
          <SubTitle style={style.blackColor}>Opening & Closing Days/Hours</SubTitle>
        </Box>
        {this.state.isEditOn ?
          <Box style={{ marginTop: "16px" }}>
            <SelectAllCheckbox
              value="start"
              control={
                <Checkbox
                  checkedIcon={<img src={CheckedCheckBox} />}
                  icon={<img src={UnCheckedCheckBox} />}
                />
              }
              label="Select all"
              labelPlacement="start"
            />
          </Box>
          : <></>}
        <DaysWrapper>
          {DaysArray.map((item, index) => {
            return (
              <DayBoxWrapper>
                <DayBox>
                  <DayCheckbox
                    className={checkCondition(Boolean(DaysArray[0].day === item.day), "checked-label", "default-label") as string}
                    label={item.day}
                    value={item.value}
                    checked={DaysArray[0].day === item.day}
                    labelPlacement="end"
                    control={this.state.isEditOn ?
                      <Checkbox
                        checkedIcon={<img src={CheckedCheckBox} />}
                        icon={<img src={UnCheckedCheckBox} />}
                      />
                      : <></>
                    }
                  />
                </DayBox>

                {DaysArray[0].day === item.day ?
                  <TimeBox>
                    <AccessTimeIcon
                      style={{
                        width: 24,
                        height: 24,
                        color: checkCondition(this.state.isEditOn, "#6F05EA", "#000000") as string
                      }}
                    />
                    <CustomSelect
                      className={checkCondition(this.state.isEditOn, "edit-select", "default-selects") as string}
                      id="start-range-day"
                      variant="outlined"
                      disabled={!this.state.isEditOn}
                      displayEmpty
                      defaultValue={""}
                      IconComponent={ArrowIcon}
                    >
                      <MenuFont value={""} disabled>00:00</MenuFont>
                      {menuRange.map((each, index) => {
                        return (
                          <MenuFont value={each.value} key={index}>{each.name}</MenuFont>
                        )
                      })}
                    </CustomSelect>
                    <CustomSelect
                      className={checkCondition(this.state.isEditOn, "edit-select", "default-selects") as string}
                      id="end-range-day"
                      variant="outlined"
                      disabled={!this.state.isEditOn}
                      defaultValue={""}
                      displayEmpty
                      IconComponent={ArrowIcon}
                    >
                      <MenuFont value={""} disabled>00:00</MenuFont>
                      {menuRange.map((each, index) => {
                        return (
                          <MenuFont value={each.value} key={index}>{each.name}</MenuFont>
                        )
                      })}
                    </CustomSelect>
                  </TimeBox>
                  : <></>}
              </DayBoxWrapper>
            )
          })}

        </DaysWrapper>
      </ScheduleWrapper>
    )
  }
  renderAcceptedAnimals = () => {
    return (
      <AcceptedWrapper>
        <WrapperTitle>Accepted Animals</WrapperTitle>
        <AnimalWrapper>
          {AnimalsArray.map((each) => {
            return (
              <LabelBox>
                <CustomCheckbox
                  className={AnimalsArray[0].animal === each.animal ? "checked-label" : "default-label"}
                  label={each.animal}
                  value={each.value}
                  checked={AnimalsArray[0].animal === each.animal}
                  labelPlacement="end"
                  control={this.state.isEditOn ?
                    <Checkbox
                      checkedIcon={<img src={CheckedCheckBox} />}
                      icon={<img src={UnCheckedCheckBox} />}
                    />
                    : <></>
                  }
                />
              </LabelBox>
            )
          })}
        </AnimalWrapper>
      </AcceptedWrapper>
    )
  }
  renderLunchBreak = () => {
    const menuRange = generateTimeRange("09:00", "18:00", 30)
    return (
      <LunchWrapper>
        <WrapperTitle>Lunch Break</WrapperTitle>
        <LunchCheckbox>
          <CheckWrapper>
            <CustomCheckbox
              label={"Everyday"}
              labelPlacement="end"
              control={this.state.isEditOn ?
                <Checkbox
                  checkedIcon={<img src={CheckedCheckBox} />}
                  icon={<img src={UnCheckedCheckBox} />}
                />
                : <></>
              }
            />
          </CheckWrapper>
          <TimeWrapper>
            <AccessTimeIcon style={{ width: 24, height: 24, color: this.state.isEditOn ? "#6F05EA" : "#000000" }} />
            <CustomSelect
              className={this.state.isEditOn ? "edit-select" : "default-selects"}
              id="start-range"
              variant="outlined"
              disabled={!this.state.isEditOn}
              displayEmpty
              defaultValue={""}
              IconComponent={ArrowIcon}
            >
              <MenuFont value={""} disabled>00:00</MenuFont>
              {menuRange.map((each, index) => {
                return (
                  <MenuFont value={each.value} key={index}>{each.name}</MenuFont>
                )
              })}
            </CustomSelect>
            <CustomSelect
              className={this.state.isEditOn ? "edit-select" : "default-selects"}
              id="end-range"
              variant="outlined"
              disabled={!this.state.isEditOn}
              displayEmpty
              defaultValue={""}
              IconComponent={ArrowIcon}
            >
              <MenuFont value={""} disabled>00:00</MenuFont>
              {menuRange.map((each, index) => {
                return (
                  <MenuFont value={each.value} key={index}>{each.name}</MenuFont>
                )
              })}
            </CustomSelect>
          </TimeWrapper>
        </LunchCheckbox>
      </LunchWrapper>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainWrapper>
        <BioWrapper>
          <FormLabelFont>Bio/About</FormLabelFont>
          <CustomTextField
            multiline
            minRows={6}
            data-testid="bio-input-fiels"
            variant="outlined"
            placeholder="Write a description from your establishment."
            inputProps={{
              maxLength: 500
            }}
          />
          <LengthBox>0/500</LengthBox>
        </BioWrapper>

        <PolicyWrapper>
          <FormLabelFont>Cancellation Policy</FormLabelFont>
          <CustomTextField
            multiline
            minRows={3}
            data-testid="cancellation-input-fiels"
            variant="outlined"
            placeholder="Write a Cancellation Policy."
          />
        </PolicyWrapper>

        <Section3Wrapper>
          {this.renderScheduleSection()}
          {this.renderAcceptedAnimals()}
        </Section3Wrapper>
        {this.renderLunchBreak()}

        <ButtonWrapper>
          {this.state.isEditOn ?
            <>
              <CustomButton
                style={style.blackBtn}
                onClick={this.handleCancelClick}
                data-testid="cancel-btn"
              >
                Cancel
              </CustomButton>
              <CustomButton
                style={style.blueBtn}
                onClick={this.handleCancelClick}
                data-testid="save-btn"
              >
                Save
              </CustomButton>
            </>
            :
            <CustomButton
              style={style.blueBtn}
              onClick={this.handleEditClick}
              data-testid="edit-btn"
            >
              Edit
            </CustomButton>
          }
        </ButtonWrapper>

      </MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  padding: "0px"
})
const BioWrapper = styled(Box)({
  padding: "0px"
})
const LengthBox = styled(Typography)({
  textAlign: "end",
  fontSize: "10px",
  fontWeight: 500,
  lineHeight: "22px",
  color: "#A6A6A6"
})
const PolicyWrapper = styled(Box)({
  padding: "0px",
  marginTop: "48px"
})

const Section3Wrapper = styled(Box)({
  marginTop: "48px",
  display: "flex",
  flexDirection: "row",
  columnGap: "32px"
})

const ScheduleWrapper = styled(Box)({
  padding: "0px",
  width: "100%"
})
const SelectAllCheckbox = styled(FormControlLabel)({
  margin: "0px",
  "& .MuiFormControlLabel-label": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    userSelect: "none",
  },
  "& .Mui-checked + .MuiFormControlLabel-label": {
    color: "#6F05EA",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px"
  }
})
const CustomCheckbox = styled(FormControlLabel)({
  margin: "0px",
  "&.checked-label .MuiFormControlLabel-label": {
    color: "#6F05EA",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
  },
  "&.default-label .MuiFormControlLabel-label": {
    color: "#A8A29E",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
  },
  "& .MuiFormControlLabel-label": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    userSelect: "none"
  },
  "& .Mui-checked + .MuiFormControlLabel-label": {
    color: "#6F05EA",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px"
  },
  "& .MuiCheckbox-root": {
    padding: "0px",
    marginRight: "16px"
  },
})
const DayCheckbox = styled(FormControlLabel)({
  margin: "0px",
  "&.checked-label .MuiFormControlLabel-label": {
    color: "#000000",
  },
  "&.default-label .MuiFormControlLabel-label": {
    color: "#A8A29E",
  },
  "& .MuiFormControlLabel-label": {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    userSelect: "none"
  },
  "& .Mui-checked + .MuiFormControlLabel-label": {
    color: "#6F05EA",
  },
  "& .MuiCheckbox-root": {
    padding: "0px",
    marginRight: "16px"
  },
})
const AcceptedWrapper = styled(Box)({
  padding: "0px 24px",
  width: "100%"
})

const LunchWrapper = styled(Box)({
  marginTop: "32px",
})
const LunchCheckbox = styled(Box)({
  marginTop: "32px",
  height: "60px",
  padding: "0 20px",
  display: "flex",
  alignItems: "center",
})

const ButtonWrapper = styled(Box)({
  padding: "0px",
  display: "flex",
  justifyContent: "end",
  columnGap: "12px",
  marginTop: "32px"
})

const CustomButton = styled(Button)({
  padding: "8px 0",
  height: "40px",
  width: "140px",
  borderRadius: "100px",
  textAlign: "center",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "24px",
  textTransform: "none"
})


const FormLabelFont = styled(Typography)({
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "22px",
  color: "#6F05EA"
})

const WrapperTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: "20px",
  color: "#6F05EA"
})
const SubTitle = styled(Typography)({
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "18px"
})

const CustomTextField = styled(TextField)({
  marginTop: "12px",
  width: "100%",
  "& .MuiOutlinedInput-multiline": {
    padding: "0"
  },
  "& textarea": {
    resize: "none",
    padding: " 8px 8px 10px 8px",
    fontFamily: 'Montserrat'
  }
})
const LabelBox = styled(Box)({
  height: "60px",
  width: "138px",
  minWidth: "138px",
  padding: "0 20px",
  display: "flex",
  alignItems: "center",
  borderRadius: "4px",
  backgroundColor: "#F8F8F8",
  flex: "0 0 calc(50% - 10px)"
})

const DayBoxWrapper = styled(Box)({
  display: "flex"
})
const DayBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  minWidth: "200px",
  height: "60px",
  padding: "0 20px",
  backgroundColor: "#F8F8F8",
  borderTopLeftRadius: "4px",
  borderBottomLeftRadius: "4px"
})

const TimeBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "60px",
  padding: "0 8px",
  backgroundColor: "#F8F8F8",
  borderTopRightRadius: "4px",
  borderBottomRightRadius: "4px"
})

const AnimalWrapper = styled(Box)({
  marginTop: "52px",
  display: "flex",
  flexWrap: "wrap",
  rowGap: "10px",
  columnGap: "16px"
})

const CheckWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  minWidth: "200px"
})

const TimeWrapper = styled(Box)({
  display: "flex",
  alignItems: "center"
})

const CustomSelect = styled(Select)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiSelect-select': {
    backgroundColor: 'transparent',
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400
  },
  "&.edit-select .MuiSelect-select": {
    color: '#6F05EA'
  },
  "&.edit-select .MuiSelect-icon": {
    color: '#6F05EA',
    marginTop: "-2px"
  },
});
const ArrowIcon = styled(KeyboardArrowDown)({
  "& .MuiSelect-icon": {
    color: "#000000",
    marginTop: "-2px"
  }
})
const MenuFont = styled(MenuItem)({
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 400
})
const DaysWrapper = styled(Box)({
  marginTop: "16px",
  display: "flex",
  flexDirection: "column",
  rowGap: "10px"
})
const style = {
  blueBtn: {
    color: "#6F05EA",
    border: "1px solid #6F05EA",
  } as React.CSSProperties,
  blackBtn: {
    color: "#78716C",
    border: "1px solid #78716C",
  } as React.CSSProperties,
  blueColor: {
    color: "#6F05EA",
  } as React.CSSProperties,
  blackColor: {
    color: "#000000",
  } as React.CSSProperties,
  greyColor: {
    color: "#A8A29E",
  } as React.CSSProperties,
  subTitleWrapper: {
    marginTop: "16px"
  } as React.CSSProperties,

}
// Customizable Area End
