// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

interface IForm {
    transportation: string,
    medication: string,
}
// Customizable Area End

export const webConfigJSON = require("../config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    isEditOn: boolean,
    form: IForm,
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class PricesVerticalController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            isEditOn: false,
            form: {
                transportation: "No",
                medication: "No",
            }
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, checked } = event.target;
        const value = checked ? "Yes" : "No";

        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [name]: value,
            },
        }));
    };
    handleCancelClick = () => {
        this.setState({
            isEditOn: false
        })
    }
    handleEditClick = () => {
        this.setState({
            isEditOn: true
        })
    }
    // Customizable Area End
}

